//mi
import REDMI13CBLACK1 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack1.jpg";
import REDMI13CBLACK2 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack2.jpg";
import REDMI13CBLACK3 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack3.jpg";
import REDMI13CBLACK4 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack4.jpg";
import REDMI13CBLACK5 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack5.jpg";
import REDMI13CBLACK6 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack6.jpg";
import REDMI13CGREEN1 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen1.jpg";
import REDMI13CGREEN2 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen2.jpg";
import REDMI13CGREEN3 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen3.jpg";
import REDMI13CGREEN4 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen4.jpg";
import REDMI13CGREEN5 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen5.jpg";
import REDMI13CGREEN6 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen6.jpg";
import REDMI13CSILVER1 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver1.jpg";
import REDMI13CSILVER2 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver2.jpg";
import REDMI13CSILVER3 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver3.jpg";
import REDMI13CSILVER4 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver4.jpg";
import REDMI13CSILVER5 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver5.jpg";
import REDMI13CSILVER6 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver6.jpg";
import REDMINOTE13WHITE1 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE1.jpg";
import REDMINOTE13WHITE2 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE2.jpg";
import REDMINOTE13WHITE3 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE3.jpg";
import REDMINOTE13WHITE4 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE4.jpg";
import REDMINOTE13WHITE5 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE5.jpg";
import REDMINOTE13PURPLE1 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE1.jpg";
import REDMINOTE13PURPLE2 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE2.jpg";
import REDMINOTE13PURPLE3 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE3.jpg";
import REDMINOTE13PURPLE4 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE4.jpg";
import REDMINOTE13PURPLE5 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE5.jpg";
import REDMINOTE13PURPLE6 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE6.jpg";
import REDMINOTE13GOLD1 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD1.jpg";
import REDMINOTE13GOLD2 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD1.jpg";
import REDMINOTE13GOLD3 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD2.jpg";
import REDMINOTE13GOLD4 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD3.jpg";
import REDMINOTE13GOLD5 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD4.jpg";
import REDMINOTE13GOLD6 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD5.jpg";
import REDMINOTE13BLACK1 from "assets/mi/REDMINOTE13/StealthBlack/MINOTE13BLACK1.jpg";
import REDMINOTE13BLACK2 from "assets/mi/REDMINOTE13/StealthBlack/MINOTE13BLACK2.jpg";
import REDMINOTE13BLACK3 from "assets/mi/REDMINOTE13/StealthBlack/MINOTE13BLACK3.jpg";
import REDMINOTE13BLACK4 from "assets/mi/REDMINOTE13/StealthBlack/MINOTE13BLACK4.jpg";
import REDMINOTE13BLACK5 from "assets/mi/REDMINOTE13/StealthBlack/MINOTE13BLACK5.jpg";
import REDMI135GBLACKDIAMOND1 from "assets/mi/REDMI135G/REDMI135GBLACKDIAMOND/REDMI135GBLACKDIAMOND1.jpg";
import REDMI135GBLACKDIAMOND2 from "assets/mi/REDMI135G/REDMI135GBLACKDIAMOND/REDMI135GBLACKDIAMOND2.jpg";
import REDMI135GBLACKDIAMOND3 from "assets/mi/REDMI135G/REDMI135GBLACKDIAMOND/REDMI135GBLACKDIAMOND3.jpg";
import REDMI135GBLACKDIAMOND4 from "assets/mi/REDMI135G/REDMI135GBLACKDIAMOND/REDMI135GBLACKDIAMOND4.jpg";
import REDMI135GBLACKDIAMOND5 from "assets/mi/REDMI135G/REDMI135GBLACKDIAMOND/REDMI135GBLACKDIAMOND5.jpg";

import REDMI135GHAWAIIANBLUE1 from "assets/mi/REDMI135G/REDMI135GHAWAIIANBLUE/REDMI135GHAWAIIANBLUE1.jpg";
import REDMI135GHAWAIIANBLUE2 from "assets/mi/REDMI135G/REDMI135GHAWAIIANBLUE/REDMI135GHAWAIIANBLUE2.jpg";
import REDMI135GHAWAIIANBLUE3 from "assets/mi/REDMI135G/REDMI135GHAWAIIANBLUE/REDMI135GHAWAIIANBLUE3.jpg";
import REDMI135GHAWAIIANBLUE4 from "assets/mi/REDMI135G/REDMI135GHAWAIIANBLUE/REDMI135GHAWAIIANBLUE4.jpg";
import REDMI135GHAWAIIANBLUE5 from "assets/mi/REDMI135G/REDMI135GHAWAIIANBLUE/REDMI135GHAWAIIANBLUE5.jpg";

import REDMI135GORCHIDPINK1 from "assets/mi/REDMI135G/REDMI135GORCHIDPINK/REDMI135GORCHIDPINK1.jpg";
import REDMI135GORCHIDPINK2 from "assets/mi/REDMI135G/REDMI135GORCHIDPINK/REDMI135GORCHIDPINK2.jpg";
import REDMI135GORCHIDPINK3 from "assets/mi/REDMI135G/REDMI135GORCHIDPINK/REDMI135GORCHIDPINK3.jpg";
import REDMI135GORCHIDPINK4 from "assets/mi/REDMI135G/REDMI135GORCHIDPINK/REDMI135GORCHIDPINK4.jpg";
import REDMI135GORCHIDPINK5 from "assets/mi/REDMI135G/REDMI135GORCHIDPINK/REDMI135GORCHIDPINK5.jpg";

import REDMINOTE13PROPLUS5GFUSIONBLACK1 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONBLACK/REDMINOTE13PROPLUS5GFUSIONBLACK1.jpg";
import REDMINOTE13PROPLUS5GFUSIONBLACK2 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONBLACK/REDMINOTE13PROPLUS5GFUSIONBLACK2.jpg";
import REDMINOTE13PROPLUS5GFUSIONBLACK3 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONBLACK/REDMINOTE13PROPLUS5GFUSIONBLACK3.jpg";
import REDMINOTE13PROPLUS5GFUSIONBLACK4 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONBLACK/REDMINOTE13PROPLUS5GFUSIONBLACK4.jpg";
import REDMINOTE13PROPLUS5GFUSIONBLACK5 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONBLACK/REDMINOTE13PROPLUS5GFUSIONBLACK5.jpg";

import REDMINOTE13PROPLUS5GFUSIONPURPLE1 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONPURPLE/REDMINOTE13PROPLUS5GFUSIONPURPLE1.jpg";
import REDMINOTE13PROPLUS5GFUSIONPURPLE2 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONPURPLE/REDMINOTE13PROPLUS5GFUSIONPURPLE2.jpg";
import REDMINOTE13PROPLUS5GFUSIONPURPLE3 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONPURPLE/REDMINOTE13PROPLUS5GFUSIONPURPLE3.jpg";
import REDMINOTE13PROPLUS5GFUSIONPURPLE4 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONPURPLE/REDMINOTE13PROPLUS5GFUSIONPURPLE4.jpg";
import REDMINOTE13PROPLUS5GFUSIONPURPLE5 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONPURPLE/REDMINOTE13PROPLUS5GFUSIONPURPLE5.jpg";

import REDMINOTE13PROPLUS5GFUSIONWHITE1 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONWHITE/REDMINOTE13PROPLUS5GFUSIONWHITE1.jpg";
import REDMINOTE13PROPLUS5GFUSIONWHITE2 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONWHITE/REDMINOTE13PROPLUS5GFUSIONWHITE2.jpg";
import REDMINOTE13PROPLUS5GFUSIONWHITE3 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONWHITE/REDMINOTE13PROPLUS5GFUSIONWHITE3.jpg";
import REDMINOTE13PROPLUS5GFUSIONWHITE4 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONWHITE/REDMINOTE13PROPLUS5GFUSIONWHITE4.jpg";
import REDMINOTE13PROPLUS5GFUSIONWHITE5 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONWHITE/REDMINOTE13PROPLUS5GFUSIONWHITE5.jpg";

import REDMINOTE14MYSTIQUEWHITE1 from "assets/mi/REDMINOTE14/REDMINOTE14MYSTIQUEWHITE/REDMINOTE14MYSTIQUEWHITE1.jpeg";
import REDMINOTE14MYSTIQUEWHITE2 from "assets/mi/REDMINOTE14/REDMINOTE14MYSTIQUEWHITE/REDMINOTE14MYSTIQUEWHITE2.jpeg";
import REDMINOTE14MYSTIQUEWHITE3 from "assets/mi/REDMINOTE14/REDMINOTE14MYSTIQUEWHITE/REDMINOTE14MYSTIQUEWHITE3.jpeg";
import REDMINOTE14MYSTIQUEWHITE4 from "assets/mi/REDMINOTE14/REDMINOTE14MYSTIQUEWHITE/REDMINOTE14MYSTIQUEWHITE4.jpeg";
import REDMINOTE14MYSTIQUEWHITE5 from "assets/mi/REDMINOTE14/REDMINOTE14MYSTIQUEWHITE/REDMINOTE14MYSTIQUEWHITE5.jpeg";
import REDMINOTE14MYSTIQUEWHITE6 from "assets/mi/REDMINOTE14/REDMINOTE14MYSTIQUEWHITE/REDMINOTE14MYSTIQUEWHITE6.jpeg";
import REDMINOTE14PHANTOMPURPLE1 from "assets/mi/REDMINOTE14/REDMINOTE14PHANTOMPURPLE/REDMINOTE14PHANTOMPURPLE1.jpeg";
import REDMINOTE14PHANTOMPURPLE2 from "assets/mi/REDMINOTE14/REDMINOTE14PHANTOMPURPLE/REDMINOTE14PHANTOMPURPLE2.jpeg";
import REDMINOTE14PHANTOMPURPLE3 from "assets/mi/REDMINOTE14/REDMINOTE14PHANTOMPURPLE/REDMINOTE14PHANTOMPURPLE3.jpeg";
import REDMINOTE14PHANTOMPURPLE4 from "assets/mi/REDMINOTE14/REDMINOTE14PHANTOMPURPLE/REDMINOTE14PHANTOMPURPLE4.jpeg";
import REDMINOTE14PHANTOMPURPLE5 from "assets/mi/REDMINOTE14/REDMINOTE14PHANTOMPURPLE/REDMINOTE14PHANTOMPURPLE5.jpeg";
import REDMINOTE14PHANTOMPURPLE6 from "assets/mi/REDMINOTE14/REDMINOTE14PHANTOMPURPLE/REDMINOTE14PHANTOMPURPLE6.jpeg";
import REDMINOTE14TITANBLACK1 from "assets/mi/REDMINOTE14/REDMINOTE14TITANBLACK/REDMINOTE14TITANBLACK1.jpeg";
import REDMINOTE14TITANBLACK2 from "assets/mi/REDMINOTE14/REDMINOTE14TITANBLACK/REDMINOTE14TITANBLACK2.jpeg";
import REDMINOTE14TITANBLACK3 from "assets/mi/REDMINOTE14/REDMINOTE14TITANBLACK/REDMINOTE14TITANBLACK3.jpeg";
import REDMINOTE14TITANBLACK4 from "assets/mi/REDMINOTE14/REDMINOTE14TITANBLACK/REDMINOTE14TITANBLACK4.jpeg";
import REDMINOTE14TITANBLACK5 from "assets/mi/REDMINOTE14/REDMINOTE14TITANBLACK/REDMINOTE14TITANBLACK5.jpeg";
import REDMINOTE14TITANBLACK6 from "assets/mi/REDMINOTE14/REDMINOTE14TITANBLACK/REDMINOTE14TITANBLACK6.jpeg";

import REDMINOTE14PROIVYGREEN1 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROIVYGREEN/REDMINOTE14PROIVYGREEN1.jpeg";
import REDMINOTE14PROIVYGREEN2 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROIVYGREEN/REDMINOTE14PROIVYGREEN2.jpeg";
import REDMINOTE14PROIVYGREEN3 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROIVYGREEN/REDMINOTE14PROIVYGREEN3.jpeg";
import REDMINOTE14PROIVYGREEN4 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROIVYGREEN/REDMINOTE14PROIVYGREEN4.jpeg";
import REDMINOTE14PROIVYGREEN5 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROIVYGREEN/REDMINOTE14PROIVYGREEN5.jpeg";
import REDMINOTE14PROIVYGREEN6 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROIVYGREEN/REDMINOTE14PROIVYGREEN6.jpeg";
import REDMINOTE14PROPHANTOMPURPLE1 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROPHANTOMPURPLE/REDMINOTE14PROPHANTOMPURPLE1.jpeg";
import REDMINOTE14PROPHANTOMPURPLE2 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROPHANTOMPURPLE/REDMINOTE14PROPHANTOMPURPLE2.jpeg";
import REDMINOTE14PROPHANTOMPURPLE3 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROPHANTOMPURPLE/REDMINOTE14PROPHANTOMPURPLE3.jpeg";
import REDMINOTE14PROPHANTOMPURPLE4 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROPHANTOMPURPLE/REDMINOTE14PROPHANTOMPURPLE4.jpeg";
import REDMINOTE14PROPHANTOMPURPLE5 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROPHANTOMPURPLE/REDMINOTE14PROPHANTOMPURPLE5.jpeg";
import REDMINOTE14PROPHANTOMPURPLE6 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROPHANTOMPURPLE/REDMINOTE14PROPHANTOMPURPLE6.jpeg";
import REDMINOTE14PROTITANBLACK1 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROTITANBLACK/REDMINOTE14PROTITANBLACK1.jpeg";
import REDMINOTE14PROTITANBLACK2 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROTITANBLACK/REDMINOTE14PROTITANBLACK2.jpeg";
import REDMINOTE14PROTITANBLACK3 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROTITANBLACK/REDMINOTE14PROTITANBLACK3.jpeg";
import REDMINOTE14PROTITANBLACK4 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROTITANBLACK/REDMINOTE14PROTITANBLACK4.jpeg";
import REDMINOTE14PROTITANBLACK5 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROTITANBLACK/REDMINOTE14PROTITANBLACK5.jpeg";
import REDMINOTE14PROTITANBLACK6 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROTITANBLACK/REDMINOTE14PROTITANBLACK6.jpeg";

import REDMINOTE14PROPLUSSPECTREBLUE1 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSSPECTREBLUE/REDMINOTE14PROSPECTREBLUE1.jpeg";
import REDMINOTE14PROPLUSSPECTREBLUE2 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSSPECTREBLUE/REDMINOTE14PROSPECTREBLUE2.jpeg";
import REDMINOTE14PROPLUSSPECTREBLUE3 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSSPECTREBLUE/REDMINOTE14PROSPECTREBLUE3.jpeg";
import REDMINOTE14PROPLUSSPECTREBLUE4 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSSPECTREBLUE/REDMINOTE14PROSPECTREBLUE4.jpeg";
import REDMINOTE14PROPLUSSPECTREBLUE5 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSSPECTREBLUE/REDMINOTE14PROSPECTREBLUE5.jpeg";
import REDMINOTE14PROPLUSSPECTREBLUE6 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSSPECTREBLUE/REDMINOTE14PROSPECTREBLUE6.jpeg";
import REDMINOTE14PROPLUSPHANTOMPURPLE1 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSPHANTOMPURPLE/REDMI14PROPLUSPHANTOMPURPLE1.jpeg";
import REDMINOTE14PROPLUSPHANTOMPURPLE2 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSPHANTOMPURPLE/REDMI14PROPLUSPHANTOMPURPLE2.jpeg";
import REDMINOTE14PROPLUSPHANTOMPURPLE3 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSPHANTOMPURPLE/REDMI14PROPLUSPHANTOMPURPLE3.jpeg";
import REDMINOTE14PROPLUSPHANTOMPURPLE4 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSPHANTOMPURPLE/REDMI14PROPLUSPHANTOMPURPLE4.jpeg";
import REDMINOTE14PROPLUSPHANTOMPURPLE5 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSPHANTOMPURPLE/REDMI14PROPLUSPHANTOMPURPLE5.jpeg";
import REDMINOTE14PROPLUSPHANTOMPURPLE6 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSPHANTOMPURPLE/REDMI14PROPLUSPHANTOMPURPLE6.jpeg";
import REDMINOTE14PROPLUSTITANBLACK1 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSTITANBLACK/REDMI14PROPLUSTITANBLACK1.jpeg";
import REDMINOTE14PROPLUSTITANBLACK2 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSTITANBLACK/REDMI14PROPLUSTITANBLACK2.jpeg";
import REDMINOTE14PROPLUSTITANBLACK3 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSTITANBLACK/REDMI14PROPLUSTITANBLACK3.jpeg";
import REDMINOTE14PROPLUSTITANBLACK4 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSTITANBLACK/REDMI14PROPLUSTITANBLACK4.jpeg";
import REDMINOTE14PROPLUSTITANBLACK5 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSTITANBLACK/REDMI14PROPLUSTITANBLACK5.jpeg";
import REDMINOTE14PROPLUSTITANBLACK6 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSTITANBLACK/REDMI14PROPLUSTITANBLACK6.jpeg";

import REDMI14CSTARGAZEBLACK1 from "assets/mi/REDMI14C/REDMI14CSTARGAZEBLACK/REDMI14CSTARGAZEBLACK1.jpeg";
import REDMI14CSTARGAZEBLACK2 from "assets/mi/REDMI14C/REDMI14CSTARGAZEBLACK/REDMI14CSTARGAZEBLACK2.jpeg";
import REDMI14CSTARGAZEBLACK3 from "assets/mi/REDMI14C/REDMI14CSTARGAZEBLACK/REDMI14CSTARGAZEBLACK3.jpeg";
import REDMI14CSTARGAZEBLACK4 from "assets/mi/REDMI14C/REDMI14CSTARGAZEBLACK/REDMI14CSTARGAZEBLACK4.jpeg";
import REDMI14CSTARGAZEBLACK5 from "assets/mi/REDMI14C/REDMI14CSTARGAZEBLACK/REDMI14CSTARGAZEBLACK5.jpeg";

import REDMI14CSTARLIGHTBLUE1 from "assets/mi/REDMI14C/REDMI14CSTARLIGHTBLUE/REDMI14CSTARLIGHTBLUE1.jpeg";
import REDMI14CSTARLIGHTBLUE2 from "assets/mi/REDMI14C/REDMI14CSTARLIGHTBLUE/REDMI14CSTARLIGHTBLUE2.jpeg";
import REDMI14CSTARLIGHTBLUE3 from "assets/mi/REDMI14C/REDMI14CSTARLIGHTBLUE/REDMI14CSTARLIGHTBLUE3.jpeg";
import REDMI14CSTARLIGHTBLUE4 from "assets/mi/REDMI14C/REDMI14CSTARLIGHTBLUE/REDMI14CSTARLIGHTBLUE4.jpeg";
import REDMI14CSTARLIGHTBLUE5 from "assets/mi/REDMI14C/REDMI14CSTARLIGHTBLUE/REDMI14CSTARLIGHTBLUE5.jpeg";

import REDMI14CSTARDUSTPURPLE1 from "assets/mi/REDMI14C/REDMI14CSTARDUSTPURPLE/REDMI14CSTARDUSTPURPLE1.jpeg";
import REDMI14CSTARDUSTPURPLE2 from "assets/mi/REDMI14C/REDMI14CSTARDUSTPURPLE/REDMI14CSTARDUSTPURPLE2.jpeg";
import REDMI14CSTARDUSTPURPLE3 from "assets/mi/REDMI14C/REDMI14CSTARDUSTPURPLE/REDMI14CSTARDUSTPURPLE3.jpeg";
import REDMI14CSTARDUSTPURPLE4 from "assets/mi/REDMI14C/REDMI14CSTARDUSTPURPLE/REDMI14CSTARDUSTPURPLE4.jpeg";
import REDMI14CSTARDUSTPURPLE5 from "assets/mi/REDMI14C/REDMI14CSTARDUSTPURPLE/REDMI14CSTARDUSTPURPLE5.jpeg";

//oppo import

import OPPOFINDX8PROPEARLWHITE1 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROPEARLWHITE/OPPOFINDX8PROPEARLWHITE1.jpeg";
import OPPOFINDX8PROPEARLWHITE2 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROPEARLWHITE/OPPOFINDX8PROPEARLWHITE2.jpeg";
import OPPOFINDX8PROPEARLWHITE3 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROPEARLWHITE/OPPOFINDX8PROPEARLWHITE3.jpeg";
import OPPOFINDX8PROPEARLWHITE4 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROPEARLWHITE/OPPOFINDX8PROPEARLWHITE4.jpeg";
import OPPOFINDX8PROPEARLWHITE5 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROPEARLWHITE/OPPOFINDX8PROPEARLWHITE5.jpeg";
import OPPOFINDX8PROPEARLWHITE6 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROPEARLWHITE/OPPOFINDX8PROPEARLWHITE6.jpeg";

import OPPOFINDX8PROSPACEBLACK1 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROSPACEBLACK/OPPOFINDX8PROSPACEBLACK1.jpeg";
import OPPOFINDX8PROSPACEBLACK2 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROSPACEBLACK/OPPOFINDX8PROSPACEBLACK2.jpeg";
import OPPOFINDX8PROSPACEBLACK3 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROSPACEBLACK/OPPOFINDX8PROSPACEBLACK3.jpeg";
import OPPOFINDX8PROSPACEBLACK4 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROSPACEBLACK/OPPOFINDX8PROSPACEBLACK4.jpeg";
import OPPOFINDX8PROSPACEBLACK5 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROSPACEBLACK/OPPOFINDX8PROSPACEBLACK5.jpeg";
import OPPOFINDX8PROSPACEBLACK6 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROSPACEBLACK/OPPOFINDX8PROSPACEBLACK6.jpeg";

import OPPOFINDX8SPACEBLACK1 from "assets/oppo/OPPOFINDX8/OPPOFINDX8SPACEBLACK/OPPOFINDX8SPACEBLACK1.jpeg";
import OPPOFINDX8SPACEBLACK2 from "assets/oppo/OPPOFINDX8/OPPOFINDX8SPACEBLACK/OPPOFINDX8SPACEBLACK2.jpeg";
import OPPOFINDX8SPACEBLACK3 from "assets/oppo/OPPOFINDX8/OPPOFINDX8SPACEBLACK/OPPOFINDX8SPACEBLACK3.jpeg";
import OPPOFINDX8SPACEBLACK4 from "assets/oppo/OPPOFINDX8/OPPOFINDX8SPACEBLACK/OPPOFINDX8SPACEBLACK4.jpeg";
import OPPOFINDX8SPACEBLACK5 from "assets/oppo/OPPOFINDX8/OPPOFINDX8SPACEBLACK/OPPOFINDX8SPACEBLACK5.jpeg";
import OPPOFINDX8SPACEBLACK6 from "assets/oppo/OPPOFINDX8/OPPOFINDX8SPACEBLACK/OPPOFINDX8SPACEBLACK6.jpeg";

import OPPOFINDX8STARGREY1 from "assets/oppo/OPPOFINDX8/OPPOFINDX8STARGREY/OPPOFINDX8STARGREY1.jpeg";
import OPPOFINDX8STARGREY2 from "assets/oppo/OPPOFINDX8/OPPOFINDX8STARGREY/OPPOFINDX8STARGREY2.jpeg";
import OPPOFINDX8STARGREY3 from "assets/oppo/OPPOFINDX8/OPPOFINDX8STARGREY/OPPOFINDX8STARGREY3.jpeg";
import OPPOFINDX8STARGREY4 from "assets/oppo/OPPOFINDX8/OPPOFINDX8STARGREY/OPPOFINDX8STARGREY4.jpeg";
import OPPOFINDX8STARGREY5 from "assets/oppo/OPPOFINDX8/OPPOFINDX8STARGREY/OPPOFINDX8STARGREY5.jpeg";
import OPPOFINDX8STARGREY6 from "assets/oppo/OPPOFINDX8/OPPOFINDX8STARGREY/OPPOFINDX8STARGREY6.jpeg";

import OPPOA17kGOLD1 from "assets/oppo/OPPOA17K/OPPOA17KGOLD/OPPOA17KGOLD1.jpg";
import OPPOA17kGOLD2 from "assets/oppo/OPPOA17K/OPPOA17KGOLD/OPPOA17KGOLD2.jpg";
import OPPOA17kGOLD3 from "assets/oppo/OPPOA17K/OPPOA17KGOLD/OPPOA17KGOLD3.jpg";
import OPPOA17kGOLD4 from "assets/oppo/OPPOA17K/OPPOA17KGOLD/OPPOA17KGOLD4.jpg";
import OPPOA17kGOLD5 from "assets/oppo/OPPOA17K/OPPOA17KGOLD/OPPOA17KGOLD5.jpg";
import OPPOA17kBLUE1 from "assets/oppo/OPPOA17K/OPPOA17KNAVYBLUE/OPPOA17KNAVYBLUE1.jpg";
import OPPOA17kBLUE2 from "assets/oppo/OPPOA17K/OPPOA17KNAVYBLUE/OPPOA17KNAVYBLUE2.jpg";
import OPPOA17kBLUE3 from "assets/oppo/OPPOA17K/OPPOA17KNAVYBLUE/OPPOA17KNAVYBLUE3.jpg";
import OPPOA17kBLUE4 from "assets/oppo/OPPOA17K/OPPOA17KNAVYBLUE/OPPOA17KNAVYBLUE4.jpg";
import OPPOA17kBLUE5 from "assets/oppo/OPPOA17K/OPPOA17KNAVYBLUE/OPPOA17KNAVYBLUE5.jpg";

import OPPOA77BLUE1 from "assets/oppo/OPPOA77/OPPOA77SKYBLUE/OPPOA77SKYBLUE1.jpg";
import OPPOA77BLUE2 from "assets/oppo/OPPOA77/OPPOA77SKYBLUE/OPPOA77SKYBLUE2.jpg";
import OPPOA77BLUE3 from "assets/oppo/OPPOA77/OPPOA77SKYBLUE/OPPOA77SKYBLUE3.jpg";
import OPPOA77BLUE4 from "assets/oppo/OPPOA77/OPPOA77SKYBLUE/OPPOA77SKYBLUE4.jpg";
import OPPOA77BLUE5 from "assets/oppo/OPPOA77/OPPOA77SKYBLUE/OPPOA77SKYBLUE5.jpg";
import OPPOA77ORANGE1 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE1.jpg";
import OPPOA77ORANGE2 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE2.jpg";
import OPPOA77ORANGE3 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE3.jpg";
import OPPOA77ORANGE4 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE4.jpg";
import OPPOA77ORANGE5 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE5.jpg";

import OPPOK12XBLUE1 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE1.jpg";
import OPPOK12XBLUE2 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE2.jpg";
import OPPOK12XBLUE3 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE3.jpg";
import OPPOK12XBLUE4 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE4.jpg";
import OPPOK12XBLUE5 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE5.jpg";
import OPPOK12XVOILET1 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET1.jpg";
import OPPOK12XVOILET2 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET2.jpg";
import OPPOK12XVOILET3 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET3.jpg";
import OPPOK12XVOILET4 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET4.jpg";
import OPPOK12XVOILET5 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET5.jpg";

import OPPORENO12BROWN1 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN1.jpg";
import OPPORENO12BROWN2 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN2.jpg";
import OPPORENO12BROWN3 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN3.jpg";
import OPPORENO12BROWN4 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN4.jpg";
import OPPORENO12BROWN5 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN5.jpg";
import OPPORENO12BROWN6 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN6.jpg";
import OPPORENO12PEACH1 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH1.jpg";
import OPPORENO12PEACH2 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH2.jpg";
import OPPORENO12PEACH3 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH3.jpg";
import OPPORENO12PEACH4 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH4.jpg";
import OPPORENO12PEACH5 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH5.jpg";
import OPPORENO12PEACH6 from "assets/oppo/OPPORENO12/OPPORENO12SUNSETPEACH/OPPORENO12SUNSETPEACH6.jpg";

import OPPORENO12PROBROWN1 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSPACEBROWN/OPPORENO12PROSPACEBROWN1.jpg";
import OPPORENO12PROBROWN2 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSPACEBROWN/OPPORENO12PROSPACEBROWN2.jpg";
import OPPORENO12PROBROWN3 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSPACEBROWN/OPPORENO12PROSPACEBROWN3.jpg";
import OPPORENO12PROBROWN4 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSPACEBROWN/OPPORENO12PROSPACEBROWN4.jpg";
import OPPORENO12PROBROWN5 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSPACEBROWN/OPPORENO12PROSPACEBROWN5.jpg";
import OPPORENO12PROGOLD1 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSUNSETGOLD/OPPORENO12PROSUNSETGOLD1.jpg";
import OPPORENO12PROGOLD2 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSUNSETGOLD/OPPORENO12PROSUNSETGOLD2.jpg";
import OPPORENO12PROGOLD3 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSUNSETGOLD/OPPORENO12PROSUNSETGOLD3.jpg";
import OPPORENO12PROGOLD4 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSUNSETGOLD/OPPORENO12PROSUNSETGOLD4.jpg";
import OPPORENO12PROGOLD5 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSUNSETGOLD/OPPORENO12PROSUNSETGOLD5.jpg";

import OPPOF27PROPLUSDUSKPINK1 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSDUSKPINK/OPPOF27PROPLUSDUSKPINK1.jpg";
import OPPOF27PROPLUSDUSKPINK2 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSDUSKPINK/OPPOF27PROPLUSDUSKPINK2.jpg";
import OPPOF27PROPLUSDUSKPINK3 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSDUSKPINK/OPPOF27PROPLUSDUSKPINK3.jpg";
import OPPOF27PROPLUSDUSKPINK4 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSDUSKPINK/OPPOF27PROPLUSDUSKPINK4.jpg";
import OPPOF27PROPLUSDUSKPINK5 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSDUSKPINK/OPPOF27PROPLUSDUSKPINK5.jpg";
import OPPOF27PROPLUSMIDNIGHTNAVY1 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSMIDNIGHTNAVY/OPPOF27PROPLUSMIDNIGHTNAVY1.jpg";
import OPPOF27PROPLUSMIDNIGHTNAVY2 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSMIDNIGHTNAVY/OPPOF27PROPLUSMIDNIGHTNAVY2.jpg";
import OPPOF27PROPLUSMIDNIGHTNAVY3 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSMIDNIGHTNAVY/OPPOF27PROPLUSMIDNIGHTNAVY3.jpg";
import OPPOF27PROPLUSMIDNIGHTNAVY4 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSMIDNIGHTNAVY/OPPOF27PROPLUSMIDNIGHTNAVY4.jpg";
import OPPOF27PROPLUSMIDNIGHTNAVY5 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSMIDNIGHTNAVY/OPPOF27PROPLUSMIDNIGHTNAVY5.jpg";

import OPPOA3XSPARKLEBLACK1 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK1.jpg";
import OPPOA3XSPARKLEBLACK2 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK2.jpg";
import OPPOA3XSPARKLEBLACK3 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK3.jpg";
import OPPOA3XSPARKLEBLACK4 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK4.jpg";
import OPPOA3XSPARKLEBLACK5 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK5.jpg";
import OPPOA3XSTARRYPURPLE1 from "assets/oppo/OPPOA3X/OPPOA3XSTARRYPURPLE/OPPOA3XSTARRYPURPLE1.jpg";
import OPPOA3XSTARRYPURPLE2 from "assets/oppo/OPPOA3X/OPPOA3XSTARRYPURPLE/OPPOA3XSTARRYPURPLE2.jpg";
import OPPOA3XSTARRYPURPLE3 from "assets/oppo/OPPOA3X/OPPOA3XSTARRYPURPLE/OPPOA3XSTARRYPURPLE3.jpg";
import OPPOA3XSTARRYPURPLE4 from "assets/oppo/OPPOA3X/OPPOA3XSTARRYPURPLE/OPPOA3XSTARRYPURPLE4.jpg";
import OPPOA3XSTARRYPURPLE5 from "assets/oppo/OPPOA3X/OPPOA3XSTARRYPURPLE/OPPOA3XSTARRYPURPLE5.jpg";

import OPPOF27AMBERORANGE1 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE1.jpg";
import OPPOF27AMBERORANGE2 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE2.jpg";
import OPPOF27AMBERORANGE3 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE3.jpg";
import OPPOF27AMBERORANGE4 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE4.jpg";
import OPPOF27AMBERORANGE5 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE5.jpg";
import OPPOF27EMERALDGREEN1 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN1.jpg";
import OPPOF27EMERALDGREEN2 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN2.jpg";
import OPPOF27EMERALDGREEN3 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN3.jpg";
import OPPOF27EMERALDGREEN4 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN4.jpg";
import OPPOF27EMERALDGREEN5 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN5.jpg";

import OPPOA3PROMOONLIGHTPURPLE1 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE1.jpg";
import OPPOA3PROMOONLIGHTPURPLE2 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE2.jpg";
import OPPOA3PROMOONLIGHTPURPLE3 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE3.jpg";
import OPPOA3PROMOONLIGHTPURPLE4 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE4.jpg";
import OPPOA3PROMOONLIGHTPURPLE5 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE5.jpg";
import OPPOA3PROSTARRYBLACK1 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK1.jpg";
import OPPOA3PROSTARRYBLACK2 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK2.jpg";
import OPPOA3PROSTARRYBLACK3 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK3.jpg";
import OPPOA3PROSTARRYBLACK4 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK4.jpg";
import OPPOA3PROSTARRYBLACK5 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK5.jpg";
import OPPOA3XSTARLIGHTWHITE1 from "assets/oppo/OPPOA3PRO/OPPOA3XSTARLIGHTWHITE/OPPOA3XSTARLIGHTWHITE1.jpg";
import OPPOA3XSTARLIGHTWHITE2 from "assets/oppo/OPPOA3PRO/OPPOA3XSTARLIGHTWHITE/OPPOA3XSTARLIGHTWHITE2.jpg";
import OPPOA3XSTARLIGHTWHITE3 from "assets/oppo/OPPOA3PRO/OPPOA3XSTARLIGHTWHITE/OPPOA3XSTARLIGHTWHITE3.jpg";
import OPPOA3XSTARLIGHTWHITE4 from "assets/oppo/OPPOA3PRO/OPPOA3XSTARLIGHTWHITE/OPPOA3XSTARLIGHTWHITE4.jpg";
import OPPOA3XSTARLIGHTWHITE5 from "assets/oppo/OPPOA3PRO/OPPOA3XSTARLIGHTWHITE/OPPOA3XSTARLIGHTWHITE5.jpg";

import RENO13PROGRAPHITEGREY1 from "assets/oppo/RENO13PRO/RENO13PROGRAPHITEGREY/RENO13PROGRAPHITEGREY1.jpeg";
import RENO13PROGRAPHITEGREY2 from "assets/oppo/RENO13PRO/RENO13PROGRAPHITEGREY/RENO13PROGRAPHITEGREY2.jpeg";
import RENO13PROGRAPHITEGREY3 from "assets/oppo/RENO13PRO/RENO13PROGRAPHITEGREY/RENO13PROGRAPHITEGREY3.jpeg";
import RENO13PROGRAPHITEGREY4 from "assets/oppo/RENO13PRO/RENO13PROGRAPHITEGREY/RENO13PROGRAPHITEGREY4.jpeg";
import RENO13PROGRAPHITEGREY5 from "assets/oppo/RENO13PRO/RENO13PROGRAPHITEGREY/RENO13PROGRAPHITEGREY5.jpeg";

import RENO13PROMISTLAVENDER1 from "assets/oppo/RENO13PRO/RENO13PROMISTLAVENDER/RENO13PROMISTLAVENDER1.jpeg";
import RENO13PROMISTLAVENDER2 from "assets/oppo/RENO13PRO/RENO13PROMISTLAVENDER/RENO13PROMISTLAVENDER2.jpeg";
import RENO13PROMISTLAVENDER3 from "assets/oppo/RENO13PRO/RENO13PROMISTLAVENDER/RENO13PROMISTLAVENDER3.jpeg";
import RENO13PROMISTLAVENDER4 from "assets/oppo/RENO13PRO/RENO13PROMISTLAVENDER/RENO13PROMISTLAVENDER4.jpeg";
import RENO13PROMISTLAVENDER5 from "assets/oppo/RENO13PRO/RENO13PROMISTLAVENDER/RENO13PROMISTLAVENDER5.jpeg";

import RENO135GIVORYWHITE1 from "assets/oppo/RENO13/RENO135GIVORYWHITE/RENO135GIVORYWHITE1.png";
import RENO135GIVORYWHITE2 from "assets/oppo/RENO13/RENO135GIVORYWHITE/RENO135GIVORYWHITE2.png";
import RENO135GIVORYWHITE3 from "assets/oppo/RENO13/RENO135GIVORYWHITE/RENO135GIVORYWHITE3.png";
import RENO135GIVORYWHITE4 from "assets/oppo/RENO13/RENO135GIVORYWHITE/RENO135GIVORYWHITE4.png";

import RENO135GLUMINOUSWHITE1 from "assets/oppo/RENO13/RENO135GLUMINOUSWHITE/RENO135GLUMINOUSWHITE1.png";
import RENO135GLUMINOUSWHITE2 from "assets/oppo/RENO13/RENO135GLUMINOUSWHITE/RENO135GLUMINOUSWHITE2.png";
import RENO135GLUMINOUSWHITE3 from "assets/oppo/RENO13/RENO135GLUMINOUSWHITE/RENO135GLUMINOUSWHITE3.png";
import RENO135GLUMINOUSWHITE4 from "assets/oppo/RENO13/RENO135GLUMINOUSWHITE/RENO135GLUMINOUSWHITE4.png";

//vivo
import VIVOY3005GEMERALDGREEN1 from "assets/vivo/VIVOY3005G/VIVOY3005GEMERALDGREEN/VIVOY3005GEMERALDGREEN1.jpg";
import VIVOY3005GEMERALDGREEN2 from "assets/vivo/VIVOY3005G/VIVOY3005GEMERALDGREEN/VIVOY3005GEMERALDGREEN2.jpg";
import VIVOY3005GEMERALDGREEN3 from "assets/vivo/VIVOY3005G/VIVOY3005GEMERALDGREEN/VIVOY3005GEMERALDGREEN3.jpg";
import VIVOY3005GEMERALDGREEN4 from "assets/vivo/VIVOY3005G/VIVOY3005GEMERALDGREEN/VIVOY3005GEMERALDGREEN4.jpg";
import VIVOY3005GEMERALDGREEN5 from "assets/vivo/VIVOY3005G/VIVOY3005GEMERALDGREEN/VIVOY3005GEMERALDGREEN5.jpg";
import VIVOY3005GPHANTOMPURPLE1 from "assets/vivo/VIVOY3005G/VIVOY3005GPHANTOMPURPLE/VIVOY3005GPHANTOMPURPLE1.jpg";
import VIVOY3005GPHANTOMPURPLE2 from "assets/vivo/VIVOY3005G/VIVOY3005GPHANTOMPURPLE/VIVOY3005GPHANTOMPURPLE2.jpg";
import VIVOY3005GPHANTOMPURPLE3 from "assets/vivo/VIVOY3005G/VIVOY3005GPHANTOMPURPLE/VIVOY3005GPHANTOMPURPLE3.jpg";
import VIVOY3005GPHANTOMPURPLE4 from "assets/vivo/VIVOY3005G/VIVOY3005GPHANTOMPURPLE/VIVOY3005GPHANTOMPURPLE4.jpg";
import VIVOY3005GPHANTOMPURPLE5 from "assets/vivo/VIVOY3005G/VIVOY3005GPHANTOMPURPLE/VIVOY3005GPHANTOMPURPLE5.jpg";
import VIVOY3005GTITANIUMSILVER1 from "assets/vivo/VIVOY3005G/VIVOY3005GTITANIUMSILVER/VIVOY3005GTITANIUMSILVER1.jpg";
import VIVOY3005GTITANIUMSILVER2 from "assets/vivo/VIVOY3005G/VIVOY3005GTITANIUMSILVER/VIVOY3005GTITANIUMSILVER2.jpg";
import VIVOY3005GTITANIUMSILVER3 from "assets/vivo/VIVOY3005G/VIVOY3005GTITANIUMSILVER/VIVOY3005GTITANIUMSILVER3.jpg";
import VIVOY3005GTITANIUMSILVER4 from "assets/vivo/VIVOY3005G/VIVOY3005GTITANIUMSILVER/VIVOY3005GTITANIUMSILVER4.jpg";
import VIVOY3005GTITANIUMSILVER5 from "assets/vivo/VIVOY3005G/VIVOY3005GTITANIUMSILVER/VIVOY3005GTITANIUMSILVER5.jpg";

import VIVOV40GANGESBLUE1 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE1.jpg";
import VIVOV40GANGESBLUE2 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE2.jpg";
import VIVOV40GANGESBLUE3 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE3.jpg";
import VIVOV40GANGESBLUE4 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE4.jpg";
import VIVOV40GANGESBLUE5 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE5.jpg";
import VIVOV40GANGESBLUE6 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE6.jpg";
import VIVOV40LOTUSPURPLE1 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE1.jpg";
import VIVOV40LOTUSPURPLE2 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE2.jpg";
import VIVOV40LOTUSPURPLE3 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE3.jpg";
import VIVOV40LOTUSPURPLE4 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE4.jpg";
import VIVOV40LOTUSPURPLE5 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE5.jpg";
import VIVOV40LOTUSPURPLE6 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE6.jpg";
import VIVOV40TITANIUMGREY1 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY1.jpg";
import VIVOV40TITANIUMGREY2 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY2.jpg";
import VIVOV40TITANIUMGREY3 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY3.jpg";
import VIVOV40TITANIUMGREY4 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY4.jpg";
import VIVOV40TITANIUMGREY5 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY5.jpg";
import VIVOV40TITANIUMGREY6 from "assets/vivo/VIVOV40/VIVOV40TITANIUMGREY/VIVOV40TITANIUMGREY6.jpg";

import VIVOV40EMINTGREEN1 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN1.jpg";
import VIVOV40EMINTGREEN2 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN2.jpg";
import VIVOV40EMINTGREEN3 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN3.jpg";
import VIVOV40EMINTGREEN4 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN4.jpg";
import VIVOV40EMINTGREEN5 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN5.jpg";
import VIVOV40EROYALBRONZE1 from "assets/vivo/VIVOV40E/VIVOV40EROYALBRONZE/VIVOV40EROYALBRONZE1.jpg";
import VIVOV40EROYALBRONZE2 from "assets/vivo/VIVOV40E/VIVOV40EROYALBRONZE/VIVOV40EROYALBRONZE2.jpg";
import VIVOV40EROYALBRONZE3 from "assets/vivo/VIVOV40E/VIVOV40EROYALBRONZE/VIVOV40EROYALBRONZE3.jpg";
import VIVOV40EROYALBRONZE4 from "assets/vivo/VIVOV40E/VIVOV40EROYALBRONZE/VIVOV40EROYALBRONZE4.jpg";
import VIVOV40EROYALBRONZE5 from "assets/vivo/VIVOV40E/VIVOV40EROYALBRONZE/VIVOV40EROYALBRONZE5.jpg";

import VIVOY18IPACEBLACK1 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK1.jpg";
import VIVOY18IPACEBLACK2 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK2.jpg";
import VIVOY18IPACEBLACK3 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK3.jpg";
import VIVOY18IPACEBLACK4 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK4.jpg";
import VIVOY18IPACEBLACK5 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK5.jpg";
import VIVOY18IPACEBLACK6 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK6.jpg";

import VIVOY28EBREEZEGREEN1 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN1.jpg";
import VIVOY28EBREEZEGREEN2 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN2.jpg";
import VIVOY28EBREEZEGREEN3 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN3.jpg";
import VIVOY28EBREEZEGREEN4 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN4.jpg";
import VIVOY28EBREEZEGREEN5 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN5.jpg";
import VIVOY28EVINTAGEREEN6 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN6.jpg";
import VIVOY28EVINTAGERED1 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED1.jpg";
import VIVOY28EVINTAGERED2 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED2.jpg";
import VIVOY28EVINTAGERED3 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED3.jpg";
import VIVOY28EVINTAGERED4 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED4.jpg";
import VIVOY28EVINTAGERED5 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED5.jpg";
import VIVOY28EVINTAGERED6 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED6.jpg";

import VIVOY200EBLACKDIAMOND1 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND1.jpg";
import VIVOY200EBLACKDIAMOND2 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND2.jpg";
import VIVOY200EBLACKDIAMOND3 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND3.jpg";
import VIVOY200EBLACKDIAMOND4 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND4.jpg";
import VIVOY200EBLACKDIAMOND5 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND5.jpg";
import VIVOY200EBLACKDIAMOND6 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND6.jpg";
import VIVOY200ESAFFRONDELIGHT1 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT1.jpg";
import VIVOY200ESAFFRONDELIGHT2 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT2.jpg";
import VIVOY200ESAFFRONDELIGHT3 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT3.jpg";
import VIVOY200ESAFFRONDELIGHT4 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT4.jpg";
import VIVOY200ESAFFRONDELIGHT5 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT5.jpg";
import VIVOY200ESAFFRONDELIGHT6 from "assets/vivo/VIVOY200E/VIVOY200ESAFFRONDELIGHT/VIVOY200ESAFFRONDELIGHT6.jpg";

import VIVOY300PLUSSILKGREEN1 from "assets/vivo/VIVOY300PLUS/VIVOY300PLUSSILKGREEN/VIVOY300PLUSSILKGREEN1.jpg";
import VIVOY300PLUSSILKGREEN2 from "assets/vivo/VIVOY300PLUS/VIVOY300PLUSSILKGREEN/VIVOY300PLUSSILKGREEN2.jpg";
import VIVOY300PLUSSILKGREEN3 from "assets/vivo/VIVOY300PLUS/VIVOY300PLUSSILKGREEN/VIVOY300PLUSSILKGREEN3.jpg";
import VIVOY300PLUSSILKGREEN4 from "assets/vivo/VIVOY300PLUS/VIVOY300PLUSSILKGREEN/VIVOY300PLUSSILKGREEN4.jpg";
import VIVOY300PLUSSILKGREEN5 from "assets/vivo/VIVOY300PLUS/VIVOY300PLUSSILKGREEN/VIVOY300PLUSSILKGREEN5.jpg";
import Y300PLUSSILKBLACK1 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK1.jpg";
import Y300PLUSSILKBLACK2 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK2.jpg";
import Y300PLUSSILKBLACK3 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK3.jpg";
import Y300PLUSSILKBLACK4 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK4.jpg";
import Y300PLUSSILKBLACK5 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK5.jpg";
import Y300PLUSSILKBLACK6 from "assets/vivo/VIVOY300PLUS/Y300PLUSSILKBLACK/Y300PLUSSILKBLACK6.jpg";

import VIVOX200NATURALGREEN1 from "assets/vivo/VIVOX200/VIVOX200NATURALGREEN/VIVOX200NATURALGREEN1.jpeg";
import VIVOX200NATURALGREEN2 from "assets/vivo/VIVOX200/VIVOX200NATURALGREEN/VIVOX200NATURALGREEN2.jpeg";
import VIVOX200NATURALGREEN3 from "assets/vivo/VIVOX200/VIVOX200NATURALGREEN/VIVOX200NATURALGREEN3.jpeg";
import VIVOX200NATURALGREEN4 from "assets/vivo/VIVOX200/VIVOX200NATURALGREEN/VIVOX200NATURALGREEN4.jpeg";
import VIVOX200NATURALGREEN5 from "assets/vivo/VIVOX200/VIVOX200NATURALGREEN/VIVOX200NATURALGREEN5.jpeg";
import VIVOX200NATURALGREEN6 from "assets/vivo/VIVOX200/VIVOX200NATURALGREEN/VIVOX200NATURALGREEN6.jpeg";
import VIVOX200NATURALGREEN7 from "assets/vivo/VIVOX200/VIVOX200NATURALGREEN/VIVOX200NATURALGREEN7.jpeg";
import VIVOX200COSMOSBLACK1 from "assets/vivo/VIVOX200/VIVOX200COSMOSBLACK/VIVOX200COSMOSBLACK1.jpeg";
import VIVOX200COSMOSBLACK2 from "assets/vivo/VIVOX200/VIVOX200COSMOSBLACK/VIVOX200COSMOSBLACK2.jpeg";
import VIVOX200COSMOSBLACK3 from "assets/vivo/VIVOX200/VIVOX200COSMOSBLACK/VIVOX200COSMOSBLACK3.jpeg";
import VIVOX200COSMOSBLACK4 from "assets/vivo/VIVOX200/VIVOX200COSMOSBLACK/VIVOX200COSMOSBLACK4.jpeg";
import VIVOX200COSMOSBLACK5 from "assets/vivo/VIVOX200/VIVOX200COSMOSBLACK/VIVOX200COSMOSBLACK5.jpeg";
import VIVOX200COSMOSBLACK6 from "assets/vivo/VIVOX200/VIVOX200COSMOSBLACK/VIVOX200COSMOSBLACK6.jpeg";

import VIVOX200PROTITANIUMGREY1 from "assets/vivo/VIVOX200PRO/VIVOX200TITANIUMGREY/VIVOX200PROTITANIUMGREY1.jpg";
import VIVOX200PROTITANIUMGREY2 from "assets/vivo/VIVOX200PRO/VIVOX200TITANIUMGREY/VIVOX200PROTITANIUMGREY2.jpg";
import VIVOX200PROTITANIUMGREY3 from "assets/vivo/VIVOX200PRO/VIVOX200TITANIUMGREY/VIVOX200PROTITANIUMGREY3.jpg";
import VIVOX200PROTITANIUMGREY4 from "assets/vivo/VIVOX200PRO/VIVOX200TITANIUMGREY/VIVOX200PROTITANIUMGREY4.jpg";
import VIVOX200PROTITANIUMGREY5 from "assets/vivo/VIVOX200PRO/VIVOX200TITANIUMGREY/VIVOX200PROTITANIUMGREY5.jpg";
import VIVOX200PROTITANIUMGREY6 from "assets/vivo/VIVOX200PRO/VIVOX200TITANIUMGREY/VIVOX200PROTITANIUMGREY6.jpg";

import VIVOX200PROCOSMOSBLACK1 from "assets/vivo/VIVOX200PRO/VIVOX200PROCOSMOSBLACK/VIVOX200PROCOSMOSBLACK1.jpg";
import VIVOX200PROCOSMOSBLACK2 from "assets/vivo/VIVOX200PRO/VIVOX200PROCOSMOSBLACK/VIVOX200PROCOSMOSBLACK2.jpg";
import VIVOX200PROCOSMOSBLACK3 from "assets/vivo/VIVOX200PRO/VIVOX200PROCOSMOSBLACK/VIVOX200PROCOSMOSBLACK3.jpg";
import VIVOX200PROCOSMOSBLACK4 from "assets/vivo/VIVOX200PRO/VIVOX200PROCOSMOSBLACK/VIVOX200PROCOSMOSBLACK4.jpg";
import VIVOX200PROCOSMOSBLACK5 from "assets/vivo/VIVOX200PRO/VIVOX200PROCOSMOSBLACK/VIVOX200PROCOSMOSBLACK5.jpg";
import VIVOX200PROCOSMOSBLACK6 from "assets/vivo/VIVOX200PRO/VIVOX200PROCOSMOSBLACK/VIVOX200PROCOSMOSBLACK6.jpg";

import VIVOY295GDIAMONDBLACK1 from "assets/vivo/VIVOY29/VIVOY295GDIAMONDBLACK/VIVOY295GDIAMONDBLACK1.jpeg";
import VIVOY295GDIAMONDBLACK2 from "assets/vivo/VIVOY29/VIVOY295GDIAMONDBLACK/VIVOY295GDIAMONDBLACK2.jpeg";
import VIVOY295GDIAMONDBLACK3 from "assets/vivo/VIVOY29/VIVOY295GDIAMONDBLACK/VIVOY295GDIAMONDBLACK3.jpeg";
import VIVOY295GDIAMONDBLACK4 from "assets/vivo/VIVOY29/VIVOY295GDIAMONDBLACK/VIVOY295GDIAMONDBLACK4.jpeg";
import VIVOY295GDIAMONDBLACK5 from "assets/vivo/VIVOY29/VIVOY295GDIAMONDBLACK/VIVOY295GDIAMONDBLACK5.jpeg";

import VIVOY295GGLACIERBLUE1 from "assets/vivo/VIVOY29/VIVOY295GGLACIERBLUE/VIVOY295GGLACIERBLUE1.jpeg";
import VIVOY295GGLACIERBLUE2 from "assets/vivo/VIVOY29/VIVOY295GGLACIERBLUE/VIVOY295GGLACIERBLUE2.jpeg";
import VIVOY295GGLACIERBLUE3 from "assets/vivo/VIVOY29/VIVOY295GGLACIERBLUE/VIVOY295GGLACIERBLUE3.jpeg";
import VIVOY295GGLACIERBLUE4 from "assets/vivo/VIVOY29/VIVOY295GGLACIERBLUE/VIVOY295GGLACIERBLUE4.jpeg";
import VIVOY295GGLACIERBLUE5 from "assets/vivo/VIVOY29/VIVOY295GGLACIERBLUE/VIVOY295GGLACIERBLUE5.jpeg";

import VIVOY295GTITANIUMGOLD1 from "assets/vivo/VIVOY29/VIVOY295GTITANIUMGOLD/VIVOY295GTITANIUMGOLD1.jpeg";
import VIVOY295GTITANIUMGOLD2 from "assets/vivo/VIVOY29/VIVOY295GTITANIUMGOLD/VIVOY295GTITANIUMGOLD2.jpeg";
import VIVOY295GTITANIUMGOLD3 from "assets/vivo/VIVOY29/VIVOY295GTITANIUMGOLD/VIVOY295GTITANIUMGOLD3.jpeg";
import VIVOY295GTITANIUMGOLD4 from "assets/vivo/VIVOY29/VIVOY295GTITANIUMGOLD/VIVOY295GTITANIUMGOLD4.jpeg";
import VIVOY295GTITANIUMGOLD5 from "assets/vivo/VIVOY29/VIVOY295GTITANIUMGOLD/VIVOY295GTITANIUMGOLD5.jpeg";

//samsung
import SAMSUNGA165GGOLD1 from "assets/samsung/SASMSUNGA165G/SAMSUNGA165GGOLD/SAMSUNGA165GGOLD1.jpeg";
import SAMSUNGA165GGOLD2 from "assets/samsung/SASMSUNGA165G/SAMSUNGA165GGOLD/SAMSUNGA165GGOLD2.jpeg";
import SAMSUNGA165GGOLD3 from "assets/samsung/SASMSUNGA165G/SAMSUNGA165GGOLD/SAMSUNGA165GGOLD3.jpeg";
import SAMSUNGA165GGOLD4 from "assets/samsung/SASMSUNGA165G/SAMSUNGA165GGOLD/SAMSUNGA165GGOLD4.jpeg";
import SAMSUNGA165GGOLD5 from "assets/samsung/SASMSUNGA165G/SAMSUNGA165GGOLD/SAMSUNGA165GGOLD5.jpeg";
import SAMSUNGA165GGOLD6 from "assets/samsung/SASMSUNGA165G/SAMSUNGA165GGOLD/SAMSUNGA165GGOLD6.jpeg";

import SASMSUNGA165GBLUEBLACK1 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GBLUEBLACK/SASMSUNGA165GBLUEBLACK1.jpeg";
import SASMSUNGA165GBLUEBLACK2 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GBLUEBLACK/SASMSUNGA165GBLUEBLACK2.jpeg";
import SASMSUNGA165GBLUEBLACK3 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GBLUEBLACK/SASMSUNGA165GBLUEBLACK3.jpeg";
import SASMSUNGA165GBLUEBLACK4 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GBLUEBLACK/SASMSUNGA165GBLUEBLACK4.jpeg";
import SASMSUNGA165GBLUEBLACK5 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GBLUEBLACK/SASMSUNGA165GBLUEBLACK5.jpeg";

import SASMSUNGA165GLIGHTGREEN1 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GLIGHTGREEN/SASMSUNGA165GLIGHTGREEN1.jpeg";
import SASMSUNGA165GLIGHTGREEN2 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GLIGHTGREEN/SASMSUNGA165GLIGHTGREEN2.jpeg";
import SASMSUNGA165GLIGHTGREEN3 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GLIGHTGREEN/SASMSUNGA165GLIGHTGREEN3.jpeg";
import SASMSUNGA165GLIGHTGREEN4 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GLIGHTGREEN/SASMSUNGA165GLIGHTGREEN4.jpeg";
import SASMSUNGA165GLIGHTGREEN5 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GLIGHTGREEN/SASMSUNGA165GLIGHTGREEN5.jpeg";

import A05SBLACK1 from "assets/samsung/A05S/A05SBLACK/A05SBLACK1.jpeg";
import A05SBLACK2 from "assets/samsung/A05S/A05SBLACK/A05SBLACK2.jpeg";
import A05SBLACK3 from "assets/samsung/A05S/A05SBLACK/A05SBLACK3.jpeg";
import A05SLIGHTGREEN1 from "assets/samsung/A05S/A05SLIGHTGREEN/A05SLIGHTGREEN1.jpeg";
import A05SLIGHTGREEN2 from "assets/samsung/A05S/A05SLIGHTGREEN/A05SLIGHTGREEN2.jpeg";
import A05SLIGHTGREEN3 from "assets/samsung/A05S/A05SLIGHTGREEN/A05SLIGHTGREEN3.jpeg";
import A05SLIGHTVIOLET1 from "assets/samsung/A05S/A05SLIGHTVIOLET/A05SLIGHTVIOLET1.jpeg";
import A05SLIGHTVIOLET2 from "assets/samsung/A05S/A05SLIGHTVIOLET/A05SLIGHTVIOLET2.jpeg";
import A05SLIGHTVIOLET3 from "assets/samsung/A05S/A05SLIGHTVIOLET/A05SLIGHTVIOLET3.jpeg";

import SAMSUNGA06BLACK1 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK1.jpg";
import SAMSUNGA06BLACK2 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK2.jpg";
import SAMSUNGA06BLACK3 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK3.jpg";
import SAMSUNGA06BLACK4 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK4.jpg";
import SAMSUNGA06BLACK5 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK5.jpg";
import SAMSUNGA06BLACK6 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK6.jpg";
import SAMSUNGA06GOLD1 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD1.jpg";
import SAMSUNGA06GOLD2 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD2.jpg";
import SAMSUNGA06GOLD3 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD3.jpg";
import SAMSUNGA06GOLD4 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD4.jpg";
import SAMSUNGA06GOLD5 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD5.jpg";
import SAMSUNGA06GOLD6 from "assets/samsung/SAMSUNGA06/SAMSUNGA06GOLD/SAMSUNGA06GOLD6.jpg";
import SAMSUNGA06LIGHTBLUE1 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE1.jpg";
import SAMSUNGA06LIGHTBLUE2 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE2.jpg";
import SAMSUNGA06LIGHTBLUE3 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE3.jpg";
import SAMSUNGA06LIGHTBLUE4 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE4.jpg";
import SAMSUNGA06LIGHTBLUE5 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE5.jpg";
import SAMSUNGA06LIGHTBLUE6 from "assets/samsung/SAMSUNGA06/SAMSUNGA06LIGHTBLUE/SAMSUNGA06LIGHTBLUE6.jpg";

import SAMSUNGA35AWESOMEICEBLUE1 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE1.jpg";
import SAMSUNGA35AWESOMEICEBLUE2 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE2.jpg";
import SAMSUNGA35AWESOMEICEBLUE3 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE3.jpg";
import SAMSUNGA35AWESOMEICEBLUE4 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE4.jpg";
import SAMSUNGA35AWESOMEICEBLUE5 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE5.jpg";
import SAMSUNGA35AWESOMEICEBLUE6 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE6.jpg";
import SAMSUNGA35AWESOMELILAC1 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC1.jpg";
import SAMSUNGA35AWESOMELILAC2 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC2.jpg";
import SAMSUNGA35AWESOMELILAC3 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC3.jpg";
import SAMSUNGA35AWESOMELILAC4 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC4.jpg";
import SAMSUNGA35AWESOMELILAC5 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC5.jpg";
import SAMSUNGA35AWESOMELILAC6 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC6.jpg";
import SAMSUNGA35AWESOMENAVY1 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY1.jpg";
import SAMSUNGA35AWESOMENAVY2 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY2.jpg";
import SAMSUNGA35AWESOMENAVY3 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY3.jpg";
import SAMSUNGA35AWESOMENAVY4 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY4.jpg";
import SAMSUNGA35AWESOMENAVY5 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY5.jpg";
import SAMSUNGA35AWESOMENAVY6 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMENAVY/SAMSUNGA35AWESOMENAVY6.jpg";

import SAMSUNGA55AWESOMENAVY1 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY1.jpg";
import SAMSUNGA55AWESOMENAVY2 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY2.jpg";
import SAMSUNGA55AWESOMENAVY3 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY3.jpg";
import SAMSUNGA55AWESOMENAVY4 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY4.jpg";
import SAMSUNGA55AWESOMENAVY5 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY5.jpg";
import SAMSUNGA55AWESOMENAVY6 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY6.jpg";
import SAMSUNGA55BLUE1 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE1.jpg";
import SAMSUNGA55BLUE2 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE2.jpg";
import SAMSUNGA55BLUE3 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE3.jpg";
import SAMSUNGA55BLUE4 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE4.jpg";
import SAMSUNGA55BLUE5 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE5.jpg";
import SAMSUNGA55BLUE6 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE6.jpg";

import SAMSUNGA145GBLACK1 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK1.jpg";
import SAMSUNGA145GBLACK2 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK2.jpg";
import SAMSUNGA145GBLACK3 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK3.jpg";
import SAMSUNGA145GBLACK4 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK4.jpg";
import SAMSUNGA145GBLACK5 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK5.jpg";
import SAMSUNGA145GBLACK6 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK6.jpg";
import SAMSUNGA145GDARKRED1 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED1.jpg";
import SAMSUNGA145GDARKRED2 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED2.jpg";
import SAMSUNGA145GDARKRED3 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED3.jpg";
import SAMSUNGA145GDARKRED4 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED4.jpg";
import SAMSUNGA145GDARKRED5 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED5.jpg";
import SAMSUNGA145GDARKRED6 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GDARKRED/SAMSUNGA145GDARKRED6.jpg";
import SAMSUNGA145GLIGHTGREEN1 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN1.jpg";
import SAMSUNGA145GLIGHTGREEN2 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN2.jpg";
import SAMSUNGA145GLIGHTGREEN3 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN3.jpg";
import SAMSUNGA145GLIGHTGREEN4 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN4.jpg";
import SAMSUNGA145GLIGHTGREEN5 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN5.jpg";
import SAMSUNGA145GLIGHTGREEN6 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GLIGHTGREEN/SAMSUNGA145GLIGHTGREEN6.jpg";

import SAMSUNGF15ASHBLACK1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK1.jpg";
import SAMSUNGF15ASHBLACK2 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK2.jpg";
import SAMSUNGF15ASHBLACK3 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK3.jpg";
import SAMSUNGF15ASHBLACK4 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK4.jpg";
import SAMSUNGF15ASHBLACK5 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK5.jpg";
import SAMSUNGF15ASHBLACK6 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK6.jpg";
import SAMSUNGF15GROOVYVIOLET1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET1.jpg";
import SAMSUNGF15GROOVYVIOLET2 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET2.jpg";
import SAMSUNGF15GROOVYVIOLET3 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET3.jpg";
import SAMSUNGF15GROOVYVIOLET4 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET4.jpg";
import SAMSUNGF15GROOVYVIOLET5 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET5.jpg";
import SAMSUNGF15GROOVYVIOLET6 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET6.jpg";
import SAMSUNGF15JAZZYGREEN1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15JAZZYGREEN/SAMSUNGF15JAZZYGREEN1.jpg";
import SAMSUNGF15JAZZYGREEN2 from "assets/samsung/SAMSUNGF15/SAMSUNGF15JAZZYGREEN/SAMSUNGF15JAZZYGREEN2.jpg";
import SAMSUNGF15JAZZYGREEN3 from "assets/samsung/SAMSUNGF15/SAMSUNGF15JAZZYGREEN/SAMSUNGF15JAZZYGREEN3.jpg";
import SAMSUNGF15JAZZYGREEN4 from "assets/samsung/SAMSUNGF15/SAMSUNGF15JAZZYGREEN/SAMSUNGF15JAZZYGREEN4.jpg";
import SAMSUNGF15JAZZYGREEN5 from "assets/samsung/SAMSUNGF15/SAMSUNGF15JAZZYGREEN/SAMSUNGF15JAZZYGREEN5.jpg";
import SAMSUNGF15JAZZYGREEN6 from "assets/samsung/SAMSUNGF15/SAMSUNGF15JAZZYGREEN/SAMSUNGF15JAZZYGREEN6.jpg";

import SAMSUNGM14ARCTICBLUE1 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE1.jpg";
import SAMSUNGM14ARCTICBLUE2 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE2.jpg";
import SAMSUNGM14ARCTICBLUE3 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE3.jpg";
import SAMSUNGM14ARCTICBLUE4 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE4.jpg";
import SAMSUNGM14ARCTICBLUE5 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE5.jpg";
import SAMSUNGM14ARCTICBLUE6 from "assets/samsung/SAMSUNGM14/SAMSUNGM14ARCTICBLUE/SAMSUNGM14ARCTICBLUE6.jpg";
import SAMSUNGM14SPPHIREBLUE1 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE1.jpg";
import SAMSUNGM14SPPHIREBLUE2 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE2.jpg";
import SAMSUNGM14SPPHIREBLUE3 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE3.jpg";
import SAMSUNGM14SPPHIREBLUE4 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE4.jpg";
import SAMSUNGM14SPPHIREBLUE5 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE5.jpg";
import SAMSUNGM14SPPHIREBLUE6 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE6.jpg";

import SAMSUNGS24COBALTVIOLET1 from "assets/samsung/SAMSUNGS245G/SAMSUNGS24COBALTVIOLET/SAMSUNGS24COBALTVIOLET1.jpg";
import SAMSUNGS24COBALTVIOLET2 from "assets/samsung/SAMSUNGS245G/SAMSUNGS24COBALTVIOLET/SAMSUNGS24COBALTVIOLET2.jpg";
import SAMSUNGS24COBALTVIOLET3 from "assets/samsung/SAMSUNGS245G/SAMSUNGS24COBALTVIOLET/SAMSUNGS24COBALTVIOLET3.jpg";
import SAMSUNGS24COBALTVIOLET4 from "assets/samsung/SAMSUNGS245G/SAMSUNGS24COBALTVIOLET/SAMSUNGS24COBALTVIOLET4.jpg";
import SAMSUNGS24COBALTVIOLET5 from "assets/samsung/SAMSUNGS245G/SAMSUNGS24COBALTVIOLET/SAMSUNGS24COBALTVIOLET5.jpg";

import SAMSUNGS245GAMBERYELLOW1 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GAMBERYELLOW/SAMSUNGS245GAMBERYELLOW1.jpg";
import SAMSUNGS245GAMBERYELLOW2 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GAMBERYELLOW/SAMSUNGS245GAMBERYELLOW2.jpg";
import SAMSUNGS245GAMBERYELLOW3 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GAMBERYELLOW/SAMSUNGS245GAMBERYELLOW3.jpg";
import SAMSUNGS245GAMBERYELLOW4 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GAMBERYELLOW/SAMSUNGS245GAMBERYELLOW4.jpg";
import SAMSUNGS245GAMBERYELLOW5 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GAMBERYELLOW/SAMSUNGS245GAMBERYELLOW5.jpg";

import SAMSUNGS245GMARBLEGRAY1 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY1.jpg";
import SAMSUNGS245GMARBLEGRAY2 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY2.jpg";
import SAMSUNGS245GMARBLEGRAY3 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY3.jpg";
import SAMSUNGS245GMARBLEGRAY4 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY4.jpg";
import SAMSUNGS245GMARBLEGRAY5 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY5.jpg";
import SAMSUNGS245GMARBLEGRAY6 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY6.jpg";

import SAMSUNGS245GONYXBLACK1 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GONYXBLACK/SAMSUNGS245GONYXBLACK1.jpg";
import SAMSUNGS245GONYXBLACK2 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GONYXBLACK/SAMSUNGS245GONYXBLACK2.jpg";
import SAMSUNGS245GONYXBLACK3 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GONYXBLACK/SAMSUNGS245GONYXBLACK3.jpg";
import SAMSUNGS245GONYXBLACK4 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GONYXBLACK/SAMSUNGS245GONYXBLACK4.jpg";
import SAMSUNGS245GONYXBLACK5 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GONYXBLACK/SAMSUNGS245GONYXBLACK5.jpg";

import SAMSUNGS24PLUS5GCOBALTVIOLET1 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET1.jpeg";
import SAMSUNGS24PLUS5GCOBALTVIOLET2 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET2.jpeg";
import SAMSUNGS24PLUS5GCOBALTVIOLET3 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET3.jpeg";
import SAMSUNGS24PLUS5GCOBALTVIOLET4 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET4.jpeg";
import SAMSUNGS24PLUS5GCOBALTVIOLET5 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET5.jpeg";
import SAMSUNGS24PLUS5GCOBALTVIOLET6 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET6.jpeg";

import SAMSUNGS24PLUS5GONYXBLACK1 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GONYXBLACK/SAMSUNGS24PLUS5GONYXBLACK1.jpg";
import SAMSUNGS24PLUS5GONYXBLACK2 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GONYXBLACK/SAMSUNGS24PLUS5GONYXBLACK2.jpg";
import SAMSUNGS24PLUS5GONYXBLACK3 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GONYXBLACK/SAMSUNGS24PLUS5GONYXBLACK3.jpg";
import SAMSUNGS24PLUS5GONYXBLACK4 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GONYXBLACK/SAMSUNGS24PLUS5GONYXBLACK4.jpg";
import SAMSUNGS24PLUS5GONYXBLACK5 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GONYXBLACK/SAMSUNGS24PLUS5GONYXBLACK5.jpg";

import SAMSUNGS24ULTRA5GTITANIUMBLACK1 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMBLACK/SAMSUNGS24ULTRA5GTITANIUMBLACK1.jpg";
import SAMSUNGS24ULTRA5GTITANIUMBLACK2 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMBLACK/SAMSUNGS24ULTRA5GTITANIUMBLACK2.jpg";
import SAMSUNGS24ULTRA5GTITANIUMBLACK3 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMBLACK/SAMSUNGS24ULTRA5GTITANIUMBLACK3.jpg";
import SAMSUNGS24ULTRA5GTITANIUMBLACK4 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMBLACK/SAMSUNGS24ULTRA5GTITANIUMBLACK4.jpg";
import SAMSUNGS24ULTRA5GTITANIUMBLACK5 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMBLACK/SAMSUNGS24ULTRA5GTITANIUMBLACK5.jpg";

import SAMSUNGS24ULTRA5GTITANIUMGRAY1 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY1.jpg";
import SAMSUNGS24ULTRA5GTITANIUMGRAY2 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY2.jpg";
import SAMSUNGS24ULTRA5GTITANIUMGRAY3 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY3.jpg";
import SAMSUNGS24ULTRA5GTITANIUMGRAY4 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY4.jpg";
import SAMSUNGS24ULTRA5GTITANIUMGRAY5 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY5.jpg";

import SAMSUNGS24ULTRA5GTITANIUMVIOLET1 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMVIOLET/SAMSUNGS24ULTRA5GTITANIUMVIOLET1.jpg";
import SAMSUNGS24ULTRA5GTITANIUMVIOLET2 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMVIOLET/SAMSUNGS24ULTRA5GTITANIUMVIOLET2.jpg";
import SAMSUNGS24ULTRA5GTITANIUMVIOLET3 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMVIOLET/SAMSUNGS24ULTRA5GTITANIUMVIOLET3.jpg";
import SAMSUNGS24ULTRA5GTITANIUMVIOLET4 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMVIOLET/SAMSUNGS24ULTRA5GTITANIUMVIOLET4.jpg";
import SAMSUNGS24ULTRA5GTITANIUMVIOLET5 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMVIOLET/SAMSUNGS24ULTRA5GTITANIUMVIOLET5.jpg";

import SAMSUNGS24ULTRA5GTITANIUMYELLOW1 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMYELLOW/SAMSUNGS24ULTRA5GTITANIUMYELLOW1.jpg";
import SAMSUNGS24ULTRA5GTITANIUMYELLOW2 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMYELLOW/SAMSUNGS24ULTRA5GTITANIUMYELLOW2.jpg";
import SAMSUNGS24ULTRA5GTITANIUMYELLOW3 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMYELLOW/SAMSUNGS24ULTRA5GTITANIUMYELLOW3.jpg";
import SAMSUNGS24ULTRA5GTITANIUMYELLOW4 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMYELLOW/SAMSUNGS24ULTRA5GTITANIUMYELLOW4.jpg";
import SAMSUNGS24ULTRA5GTITANIUMYELLOW5 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMYELLOW/SAMSUNGS24ULTRA5GTITANIUMYELLOW5.jpg";

import SAMSUNGS24FE5GBLUE1 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE1.jpg";
import SAMSUNGS24FE5GBLUE2 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE2.jpg";
import SAMSUNGS24FE5GBLUE3 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE3.jpg";
import SAMSUNGS24FE5GBLUE4 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE4.jpg";
import SAMSUNGS24FE5GBLUE5 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE5.jpg";
import SAMSUNGS24FE5GBLUE6 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE6.jpg";

import SAMSUNGS24FE5GGRAPHITE1 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE1.jpg";
import SAMSUNGS24FE5GGRAPHITE2 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE2.jpg";
import SAMSUNGS24FE5GGRAPHITE3 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE3.jpg";
import SAMSUNGS24FE5GGRAPHITE4 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE4.jpg";
import SAMSUNGS24FE5GGRAPHITE5 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE5.jpg";
import SAMSUNGS24FE5GGRAPHITE6 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE6.jpg";

import SAMSUNGS24FE5GMINT1 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT1.jpg";
import SAMSUNGS24FE5GMINT2 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT2.jpg";
import SAMSUNGS24FE5GMINT3 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT3.jpg";
import SAMSUNGS24FE5GMINT4 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT4.jpg";
import SAMSUNGS24FE5GMINT5 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT5.jpg";
import SAMSUNGS24FE5GMINT6 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GMINT/SAMSUNGS24FE5GMINT6.jpg";

import SAMSUNGA144GBLACK1 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GBLACK/SAMSUNGA144GBLACK1.jpg";
import SAMSUNGA144GBLACK2 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GBLACK/SAMSUNGA144GBLACK2.jpg";
import SAMSUNGA144GBLACK3 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GBLACK/SAMSUNGA144GBLACK3.jpg";
import SAMSUNGA144GBLACK4 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GBLACK/SAMSUNGA144GBLACK4.jpg";
import SAMSUNGA144GBLACK5 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GBLACK/SAMSUNGA144GBLACK5.jpg";

import SAMSUNGA144GLIGHTGREEN1 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GLIGHTGREEN/SAMSUNGA144GLIGHTGREEN1.jpg";
import SAMSUNGA144GLIGHTGREEN2 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GLIGHTGREEN/SAMSUNGA144GLIGHTGREEN2.jpg";
import SAMSUNGA144GLIGHTGREEN3 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GLIGHTGREEN/SAMSUNGA144GLIGHTGREEN3.jpg";
import SAMSUNGA144GLIGHTGREEN4 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GLIGHTGREEN/SAMSUNGA144GLIGHTGREEN4.jpg";
import SAMSUNGA144GLIGHTGREEN5 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GLIGHTGREEN/SAMSUNGA144GLIGHTGREEN5.jpg";

import SAMSUNGA144GSILVER1 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GSILVER/SAMSUNGA144GSILVER1.jpg";
import SAMSUNGA144GSILVER2 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GSILVER/SAMSUNGA144GSILVER2.jpg";
import SAMSUNGA144GSILVER3 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GSILVER/SAMSUNGA144GSILVER3.jpg";
import SAMSUNGA144GSILVER4 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GSILVER/SAMSUNGA144GSILVER4.jpg";
import SAMSUNGA144GSILVER5 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GSILVER/SAMSUNGA144GSILVER5.jpg";

//tecno
import TECNOCAMON20ARTEDITION1 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION1.jpg";
import TECNOCAMON20ARTEDITION2 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION2.jpg";
import TECNOCAMON20ARTEDITION3 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION3.jpg";
import TECNOCAMON20ARTEDITION4 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION4.jpg";
import TECNOCAMON20ARTEDITION5 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION5.jpg";
import TECNOCAMON20SERENITYBLUE1 from "assets/tecno/TECNOCAMON20/TECNOCAMON20SERENITYBLUE/TECNOCAMON20SERENITYBLUE1.jpg";
import TECNOCAMON20SERENITYBLUE2 from "assets/tecno/TECNOCAMON20/TECNOCAMON20SERENITYBLUE/TECNOCAMON20SERENITYBLUE2.jpg";
import TECNOCAMON20SERENITYBLUE3 from "assets/tecno/TECNOCAMON20/TECNOCAMON20SERENITYBLUE/TECNOCAMON20SERENITYBLUE3.jpg";
import TECNOCAMON20SERENITYBLUE4 from "assets/tecno/TECNOCAMON20/TECNOCAMON20SERENITYBLUE/TECNOCAMON20SERENITYBLUE4.jpg";
import TECNOCAMON20SERENITYBLUE5 from "assets/tecno/TECNOCAMON20/TECNOCAMON20SERENITYBLUE/TECNOCAMON20SERENITYBLUE5.jpg";

import TECNOCAMON20PRODARKWELKIN1 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PRODARKWELKIN/TECNOCAMON20PRODARKWELKIN1.jpg";
import TECNOCAMON20PRODARKWELKIN2 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PRODARKWELKIN/TECNOCAMON20PRODARKWELKIN2.jpg";
import TECNOCAMON20PRODARKWELKIN3 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PRODARKWELKIN/TECNOCAMON20PRODARKWELKIN3.jpg";
import TECNOCAMON20PRODARKWELKIN4 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PRODARKWELKIN/TECNOCAMON20PRODARKWELKIN4.jpg";
import TECNOCAMON20PROSERENITYBLUE1 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE1.jpg";
import TECNOCAMON20PROSERENITYBLUE2 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE2.jpg";
import TECNOCAMON20PROSERENITYBLUE3 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE3.jpg";
import TECNOCAMON20PROSERENITYBLUE4 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE4.jpg";
import TECNOCAMON20PROSERENITYBLUE5 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE5.jpg";

import TECNOCAMON30BASALTICDARK1 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK1.jpg";
import TECNOCAMON30BASALTICDARK2 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK2.jpg";
import TECNOCAMON30BASALTICDARK3 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK3.jpg";
import TECNOCAMON30BASALTICDARK4 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK4.jpg";
import TECNOCAMON30BASALTICDARK5 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK5.jpg";
import TECNOCAMON30UYUNISALTWHITE1 from "assets/tecno/TECNOCAMON30/TECNOCAMON30UYUNISALTWHITE/TECNOCAMON30UYUNISALTWHITE1.jpg";
import TECNOCAMON30UYUNISALTWHITE2 from "assets/tecno/TECNOCAMON30/TECNOCAMON30UYUNISALTWHITE/TECNOCAMON30UYUNISALTWHITE2.jpg";
import TECNOCAMON30UYUNISALTWHITE3 from "assets/tecno/TECNOCAMON30/TECNOCAMON30UYUNISALTWHITE/TECNOCAMON30UYUNISALTWHITE3.jpg";
import TECNOCAMON30UYUNISALTWHITE4 from "assets/tecno/TECNOCAMON30/TECNOCAMON30UYUNISALTWHITE/TECNOCAMON30UYUNISALTWHITE4.jpg";
import TECNOCAMON30UYUNISALTWHITE5 from "assets/tecno/TECNOCAMON30/TECNOCAMON30UYUNISALTWHITE/TECNOCAMON30UYUNISALTWHITE5.jpg";

import TECNOCAMON30PREMIERLAVABLACK1 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK1.jpg";
import TECNOCAMON30PREMIERLAVABLACK2 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK2.jpg";
import TECNOCAMON30PREMIERLAVABLACK3 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK3.jpg";
import TECNOCAMON30PREMIERLAVABLACK4 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK4.jpg";
import TECNOCAMON30PREMIERLAVABLACK5 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK5.jpg";
import TECNOCAMON30PREMIERSNOWYSILVER1 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERSNOWYSILVER/TECNOCAMON30PREMIERSNOWYSILVER1.jpg";
import TECNOCAMON30PREMIERSNOWYSILVER2 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERSNOWYSILVER/TECNOCAMON30PREMIERSNOWYSILVER2.jpg";
import TECNOCAMON30PREMIERSNOWYSILVER3 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERSNOWYSILVER/TECNOCAMON30PREMIERSNOWYSILVER3.jpg";
import TECNOCAMON30PREMIERSNOWYSILVER4 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERSNOWYSILVER/TECNOCAMON30PREMIERSNOWYSILVER4.jpg";
import TECNOCAMON30PREMIERSNOWYSILVER5 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERSNOWYSILVER/TECNOCAMON30PREMIERSNOWYSILVER5.jpg";

import TECNOPHANTOMX2MOONLIGHTSILVER1 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER1.jpg";
import TECNOPHANTOMX2MOONLIGHTSILVER2 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER2.jpg";
import TECNOPHANTOMX2MOONLIGHTSILVER3 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER3.jpg";
import TECNOPHANTOMX2MOONLIGHTSILVER4 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER4.jpg";
import TECNOPHANTOMX2MOONLIGHTSILVER5 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER5.jpg";
import TECNOPHANTOMX2STARDUSTGREY1 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2STARDUSTGREY/TECNOPHANTOMX2STARDUSTGREY1.jpg";
import TECNOPHANTOMX2STARDUSTGREY2 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2STARDUSTGREY/TECNOPHANTOMX2STARDUSTGREY2.jpg";
import TECNOPHANTOMX2STARDUSTGREY3 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2STARDUSTGREY/TECNOPHANTOMX2STARDUSTGREY3.jpg";

import TECNOPHANTOMX2PROMARSORANGE1 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE1.jpg";
import TECNOPHANTOMX2PROMARSORANGE2 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE2.jpg";
import TECNOPHANTOMX2PROMARSORANGE3 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE3.jpg";
import TECNOPHANTOMX2PROMARSORANGE4 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE4.jpg";
import TECNOPHANTOMX2PROMARSORANGE5 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE5.jpg";
import TECNOPHANTOMX2PROSTARDUSTGREY1 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROSTARDUSTGREY/TECNOPHANTOMX2PROSTARDUSTGREY1.jpg";
import TECNOPHANTOMX2PROSTARDUSTGREY2 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROSTARDUSTGREY/TECNOPHANTOMX2PROSTARDUSTGREY2.jpg";
import TECNOPHANTOMX2PROSTARDUSTGREY3 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROSTARDUSTGREY/TECNOPHANTOMX2PROSTARDUSTGREY3.jpg";
import TECNOPHANTOMX2PROSTARDUSTGREY4 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROSTARDUSTGREY/TECNOPHANTOMX2PROSTARDUSTGREY4.jpg";

import TECNOPOVA6NEOAURORACLOUD1 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAURORACLOUD/TECNOPOVA6NEOAURORACLOUD1.jpeg";
import TECNOPOVA6NEOAURORACLOUD2 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAURORACLOUD/TECNOPOVA6NEOAURORACLOUD2.jpeg";
import TECNOPOVA6NEOAURORACLOUD3 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAURORACLOUD/TECNOPOVA6NEOAURORACLOUD3.jpeg";
import TECNOPOVA6NEOAURORACLOUD4 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAURORACLOUD/TECNOPOVA6NEOAURORACLOUD4.jpeg";
import TECNOPOVA6NEOAZURESKY1 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAZURESKY/TECNOPOVA6NEOAZURESKY1.jpeg";
import TECNOPOVA6NEOAZURESKY2 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAZURESKY/TECNOPOVA6NEOAZURESKY2.jpeg";
import TECNOPOVA6NEOAZURESKY3 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAZURESKY/TECNOPOVA6NEOAZURESKY3.jpeg";
import TECNOPOVA6NEOAZURESKY4 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAZURESKY/TECNOPOVA6NEOAZURESKY4.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW1 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW1.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW2 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW2.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW3 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW3.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW4 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW4.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW5 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW5.jpeg";
import TECNOPOVA6NEOMIDNIGHTSHADOW6 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOMIDNIGHTSHADOW/TECNOPOVA6NEOMIDNIGHTSHADOW6.jpeg";

import TECNOPOVA6PROCOMETGREEN1 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN1.jpeg";
import TECNOPOVA6PROCOMETGREEN2 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN2.jpeg";
import TECNOPOVA6PROCOMETGREEN3 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN3.jpeg";
import TECNOPOVA6PROCOMETGREEN4 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN4.jpeg";
import TECNOPOVA6PROCOMETGREEN5 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN5.jpeg";
import TECNOPOVA6PROMETEORITEGREY1 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROMETEORITEGREY/TECNOPOVA6PROMETEORITEGREY1.jpg";
import TECNOPOVA6PROMETEORITEGREY2 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROMETEORITEGREY/TECNOPOVA6PROMETEORITEGREY2.jpg";
import TECNOPOVA6PROMETEORITEGREY3 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROMETEORITEGREY/TECNOPOVA6PROMETEORITEGREY3.jpg";
import TECNOPOVA6PROMETEORITEGREY4 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROMETEORITEGREY/TECNOPOVA6PROMETEORITEGREY4.jpg";
import TECNOPOVA6PROMETEORITEGREY5 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROMETEORITEGREY/TECNOPOVA6PROMETEORITEGREY5.jpg";

import TECNOSPARK30CAURORCLOUD1 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD1.jpeg";
import TECNOSPARK30CAURORCLOUD2 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD2.jpeg";
import TECNOSPARK30CAURORCLOUD3 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD3.jpeg";
import TECNOSPARK30CAURORCLOUD4 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD4.jpeg";
import TECNOSPARK30CAURORCLOUD5 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD5.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW1 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW1.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW2 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW2.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW3 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW3.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW4 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW4.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW5 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW5.jpeg";
import TECNOSPARK30CMIDNIGHTSHADOW6 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CMIDNIGHTSHADOW/TECNOSPARK30CMIDNIGHTSHADOW6.jpeg";

// IQOO

import IQOONEO9PROCONQUERORBLACK1 from "assets/iqoo/IQOONEO9PRO/IQOONEO9PROCONQUERORBLACK/IQOONEO9PROCONQUERORBLACK1.jpeg";
import IQOONEO9PROCONQUERORBLACK2 from "assets/iqoo/IQOONEO9PRO/IQOONEO9PROCONQUERORBLACK/IQOONEO9PROCONQUERORBLACK2.jpeg";
import IQOONEO9PROCONQUERORBLACK3 from "assets/iqoo/IQOONEO9PRO/IQOONEO9PROCONQUERORBLACK/IQOONEO9PROCONQUERORBLACK3.jpeg";
import IQOONEO9PROCONQUERORBLACK4 from "assets/iqoo/IQOONEO9PRO/IQOONEO9PROCONQUERORBLACK/IQOONEO9PROCONQUERORBLACK4.jpeg";
import IQOONEO9PROFIERYRED1 from "assets/iqoo/IQOONEO9PRO/IQOONEO9PROFIERYRED/IQOONEO9PROFIERYRED1.jpeg";
import IQOONEO9PROFIERYRED2 from "assets/iqoo/IQOONEO9PRO/IQOONEO9PROFIERYRED/IQOONEO9PROFIERYRED2.jpeg";
import IQOONEO9PROFIERYRED3 from "assets/iqoo/IQOONEO9PRO/IQOONEO9PROFIERYRED/IQOONEO9PROFIERYRED3.jpeg";
import IQOONEO9PROFIERYRED4 from "assets/iqoo/IQOONEO9PRO/IQOONEO9PROFIERYRED/IQOONEO9PROFIERYRED4.jpeg";
import IQOONEO9PROFIERYRED5 from "assets/iqoo/IQOONEO9PRO/IQOONEO9PROFIERYRED/IQOONEO9PROFIERYRED5.jpeg";

import IQOOZ9BRUSHEDGREEN1 from "assets/iqoo/IQOOZ9/IQOOZ9BRUSHEDGREEN/IQOOZ9BRUSHEDGREEN1.jpeg";
import IQOOZ9BRUSHEDGREEN2 from "assets/iqoo/IQOOZ9/IQOOZ9BRUSHEDGREEN/IQOOZ9BRUSHEDGREEN2.jpeg";
import IQOOZ9BRUSHEDGREEN3 from "assets/iqoo/IQOOZ9/IQOOZ9BRUSHEDGREEN/IQOOZ9BRUSHEDGREEN3.jpeg";
import IQOOZ9BRUSHEDGREEN4 from "assets/iqoo/IQOOZ9/IQOOZ9BRUSHEDGREEN/IQOOZ9BRUSHEDGREEN4.jpeg";
import IQOOZ9GRAPHENEBLUE1 from "assets/iqoo/IQOOZ9/IQOOZ9GRAPHENEBLUE/IQOOZ9GRAPHENEBLUE1.jpeg";
import IQOOZ9GRAPHENEBLUE2 from "assets/iqoo/IQOOZ9/IQOOZ9GRAPHENEBLUE/IQOOZ9GRAPHENEBLUE1.jpeg";
import IQOOZ9GRAPHENEBLUE3 from "assets/iqoo/IQOOZ9/IQOOZ9GRAPHENEBLUE/IQOOZ9GRAPHENEBLUE3.jpeg";
import IQOOZ9GRAPHENEBLUE4 from "assets/iqoo/IQOOZ9/IQOOZ9GRAPHENEBLUE/IQOOZ9GRAPHENEBLUE4.jpeg";

import IQOOZ9LITEAQUAFLOW1 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEAQUAFLOW/IQOOZ9LITEAQUAFLOW1.jpeg";
import IQOOZ9LITEAQUAFLOW2 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEAQUAFLOW/IQOOZ9LITEAQUAFLOW2.jpeg";
import IQOOZ9LITEAQUAFLOW3 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEAQUAFLOW/IQOOZ9LITEAQUAFLOW3.jpeg";
import IQOOZ9LITEAQUAFLOW4 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEAQUAFLOW/IQOOZ9LITEAQUAFLOW4.jpeg";
import IQOOZ9LITEAQUAFLOW5 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEAQUAFLOW/IQOOZ9LITEAQUAFLOW5.jpeg";
import IQOOZ9LITEMOCHABROWN1 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEMOCHABROWN/IQOOZ9LITEMOCHABROWN1.jpg";
import IQOOZ9LITEMOCHABROWN2 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEMOCHABROWN/IQOOZ9LITEMOCHABROWN2.jpg";
import IQOOZ9LITEMOCHABROWN3 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEMOCHABROWN/IQOOZ9LITEMOCHABROWN3.jpg";
import IQOOZ9LITEMOCHABROWN4 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEMOCHABROWN/IQOOZ9LITEMOCHABROWN4.jpg";
import IQOOZ9LITEMOCHABROWN5 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEMOCHABROWN/IQOOZ9LITEMOCHABROWN5.jpg";

import IQOOZ9SONYXGREEN1 from "assets/iqoo/IQOOZ9S/IQOOZ9SONYXGREEN/IQOOZ9SONYXGREEN1.jpeg";
import IQOOZ9SONYXGREEN2 from "assets/iqoo/IQOOZ9S/IQOOZ9SONYXGREEN/IQOOZ9SONYXGREEN2.jpeg";
import IQOOZ9SONYXGREEN3 from "assets/iqoo/IQOOZ9S/IQOOZ9SONYXGREEN/IQOOZ9SONYXGREEN3.jpeg";
import IQOOZ9SONYXGREEN4 from "assets/iqoo/IQOOZ9S/IQOOZ9SONYXGREEN/IQOOZ9SONYXGREEN4.jpeg";
import IQOOZ9STITANIUMMATTE1 from "assets/iqoo/IQOOZ9S/IQOOZ9STITANIUMMATTE/IQOOZ9STITANIUMMATTE1.jpeg";
import IQOOZ9STITANIUMMATTE2 from "assets/iqoo/IQOOZ9S/IQOOZ9STITANIUMMATTE/IQOOZ9STITANIUMMATTE2.jpeg";
import IQOOZ9STITANIUMMATTE3 from "assets/iqoo/IQOOZ9S/IQOOZ9STITANIUMMATTE/IQOOZ9STITANIUMMATTE3.jpeg";
import IQOOZ9STITANIUMMATTE4 from "assets/iqoo/IQOOZ9S/IQOOZ9STITANIUMMATTE/IQOOZ9STITANIUMMATTE4.jpeg";

import IQOOZ9XSTORMGREY1 from "assets/iqoo/IQOOZ9X/IQOOZ9XSTORMGREY/IQOOZ9XSTORMGREY1.jpeg";
import IQOOZ9XSTORMGREY2 from "assets/iqoo/IQOOZ9X/IQOOZ9XSTORMGREY/IQOOZ9XSTORMGREY2.jpeg";
import IQOOZ9XSTORMGREY3 from "assets/iqoo/IQOOZ9X/IQOOZ9XSTORMGREY/IQOOZ9XSTORMGREY3.jpeg";
import IQOOZ9XSTORMGREY4 from "assets/iqoo/IQOOZ9X/IQOOZ9XSTORMGREY/IQOOZ9XSTORMGREY4.jpeg";
import IQOOZ9XSTORMGREY5 from "assets/iqoo/IQOOZ9X/IQOOZ9XSTORMGREY/IQOOZ9XSTORMGREY5.jpeg";
import IQOOZ9XTORNADOGREEN1 from "assets/iqoo/IQOOZ9X/IQOOZ9XTORNADOGREEN/IQOOZ9XTORNADOGREEN1.jpeg";
import IQOOZ9XTORNADOGREEN2 from "assets/iqoo/IQOOZ9X/IQOOZ9XTORNADOGREEN/IQOOZ9XTORNADOGREEN2.jpeg";
import IQOOZ9XTORNADOGREEN3 from "assets/iqoo/IQOOZ9X/IQOOZ9XTORNADOGREEN/IQOOZ9XTORNADOGREEN3.jpeg";
import IQOOZ9XTORNADOGREEN4 from "assets/iqoo/IQOOZ9X/IQOOZ9XTORNADOGREEN/IQOOZ9XTORNADOGREEN4.jpeg";
import IQOOZ9XTORNADOGREEN5 from "assets/iqoo/IQOOZ9X/IQOOZ9XTORNADOGREEN/IQOOZ9XTORNADOGREEN5.jpeg";

import IQOOZ9SPROLUXEMARBLE1 from "assets/iqoo/IQOOZ9SPRO/IQOOZ9SPROLUXEMARBLE/IQOOZ9SPROLUXEMARBLE1.jpeg";
import IQOOZ9SPROLUXEMARBLE2 from "assets/iqoo/IQOOZ9SPRO/IQOOZ9SPROLUXEMARBLE/IQOOZ9SPROLUXEMARBLE2.jpeg";
import IQOOZ9SPROLUXEMARBLE3 from "assets/iqoo/IQOOZ9SPRO/IQOOZ9SPROLUXEMARBLE/IQOOZ9SPROLUXEMARBLE3.jpeg";
import IQOOZ9SPROLUXEMARBLE4 from "assets/iqoo/IQOOZ9SPRO/IQOOZ9SPROLUXEMARBLE/IQOOZ9SPROLUXEMARBLE4.jpeg";
import IQOOZ9SPROFLAMBOYANTORANGE1 from "assets/iqoo/IQOOZ9SPRO/IQOOZ9SPROFLAMBOYANTORANGE/IQOOZ9SPROFLAMBOYANTORANGE1.jpeg";
import IQOOZ9SPROFLAMBOYANTORANGE2 from "assets/iqoo/IQOOZ9SPRO/IQOOZ9SPROFLAMBOYANTORANGE/IQOOZ9SPROFLAMBOYANTORANGE2.jpeg";
import IQOOZ9SPROFLAMBOYANTORANGE3 from "assets/iqoo/IQOOZ9SPRO/IQOOZ9SPROFLAMBOYANTORANGE/IQOOZ9SPROFLAMBOYANTORANGE3.jpeg";
import IQOOZ9SPROFLAMBOYANTORANGE4 from "assets/iqoo/IQOOZ9SPRO/IQOOZ9SPROFLAMBOYANTORANGE/IQOOZ9SPROFLAMBOYANTORANGE4.jpeg";
import IQOOZ9SPROFLAMBOYANTORANGE5 from "assets/iqoo/IQOOZ9SPRO/IQOOZ9SPROFLAMBOYANTORANGE/IQOOZ9SPROFLAMBOYANTORANGE5.jpeg";

//Realme

import REALMEC63JADEGREEN1 from "assets/realme/REALMEC63/REALMEC63JADEGREEN/REALMEC63JADEGREEN1.jpeg";
import REALMEC63JADEGREEN2 from "assets/realme/REALMEC63/REALMEC63JADEGREEN/REALMEC63JADEGREEN2.jpeg";
import REALMEC63JADEGREEN3 from "assets/realme/REALMEC63/REALMEC63JADEGREEN/REALMEC63JADEGREEN3.jpeg";
import REALMEC63JADEGREEN4 from "assets/realme/REALMEC63/REALMEC63JADEGREEN/REALMEC63JADEGREEN4.jpeg";
import REALMEC63JADEGREEN5 from "assets/realme/REALMEC63/REALMEC63JADEGREEN/REALMEC63JADEGREEN5.jpeg";
import REALMEC63LEATHERBLUE1 from "assets/realme/REALMEC63/REALMEC63LEATHERBLUE/REALMEC63LEATHERBLUE1.jpeg";
import REALMEC63LEATHERBLUE2 from "assets/realme/REALMEC63/REALMEC63LEATHERBLUE/REALMEC63LEATHERBLUE2.jpeg";
import REALMEC63LEATHERBLUE3 from "assets/realme/REALMEC63/REALMEC63LEATHERBLUE/REALMEC63LEATHERBLUE3.jpeg";
import REALMEC63LEATHERBLUE4 from "assets/realme/REALMEC63/REALMEC63LEATHERBLUE/REALMEC63LEATHERBLUE4.jpeg";
import REALMEC63LEATHERBLUE5 from "assets/realme/REALMEC63/REALMEC63LEATHERBLUE/REALMEC63LEATHERBLUE5.jpeg";
import REALMEC63LEATHERBLUE6 from "assets/realme/REALMEC63/REALMEC63LEATHERBLUE/REALMEC63LEATHERBLUE6.jpeg";

import REALME11GLORYBLACK1 from "assets/realme/REALME11/REALME11GLORYBLACK/REALME11GLORYBLACK1.jpeg";
import REALME11GLORYBLACK2 from "assets/realme/REALME11/REALME11GLORYBLACK/REALME11GLORYBLACK2.jpeg";
import REALME11GLORYBLACK3 from "assets/realme/REALME11/REALME11GLORYBLACK/REALME11GLORYBLACK3.jpeg";
import REALME11GLORYBLACK4 from "assets/realme/REALME11/REALME11GLORYBLACK/REALME11GLORYBLACK4.jpeg";
import REALME11GLORYBLACK5 from "assets/realme/REALME11/REALME11GLORYBLACK/REALME11GLORYBLACK5.jpeg";
import REALME11GLORYGOLD1 from "assets/realme/REALME11/REALME11GLORYGOLD/REALME11GLORYGOLD1.jpeg";
import REALME11GLORYGOLD2 from "assets/realme/REALME11/REALME11GLORYGOLD/REALME11GLORYGOLD2.jpeg";
import REALME11GLORYGOLD3 from "assets/realme/REALME11/REALME11GLORYGOLD/REALME11GLORYGOLD3.jpeg";
import REALME11GLORYGOLD4 from "assets/realme/REALME11/REALME11GLORYGOLD/REALME11GLORYGOLD4.jpeg";
import REALME11GLORYGOLD5 from "assets/realme/REALME11/REALME11GLORYGOLD/REALME11GLORYGOLD5.jpeg";

import REALME12TWILIGHTPURPLE1 from "assets/realme/REALME12/REALME12TWILIGHTPURPLE/REALME12TWILIGHTPURPLE1.jpeg";
import REALME12TWILIGHTPURPLE2 from "assets/realme/REALME12/REALME12TWILIGHTPURPLE/REALME12TWILIGHTPURPLE2.jpeg";
import REALME12TWILIGHTPURPLE3 from "assets/realme/REALME12/REALME12TWILIGHTPURPLE/REALME12TWILIGHTPURPLE3.jpeg";
import REALME12TWILIGHTPURPLE4 from "assets/realme/REALME12/REALME12TWILIGHTPURPLE/REALME12TWILIGHTPURPLE4.jpeg";
import REALME12TWILIGHTPURPLE5 from "assets/realme/REALME12/REALME12TWILIGHTPURPLE/REALME12TWILIGHTPURPLE5.jpeg";
import REALME12WOODLANDGREEN1 from "assets/realme/REALME12/REALME12WOODLANDGREEN/REALME12WOODLANDGREEN1.jpeg";
import REALME12WOODLANDGREEN2 from "assets/realme/REALME12/REALME12WOODLANDGREEN/REALME12WOODLANDGREEN2.jpeg";
import REALME12WOODLANDGREEN3 from "assets/realme/REALME12/REALME12WOODLANDGREEN/REALME12WOODLANDGREEN3.jpeg";
import REALME12WOODLANDGREEN4 from "assets/realme/REALME12/REALME12WOODLANDGREEN/REALME12WOODLANDGREEN4.jpeg";
import REALME12WOODLANDGREEN5 from "assets/realme/REALME12/REALME12WOODLANDGREEN/REALME12WOODLANDGREEN5.jpeg";

import REALMEC65FEATHERGREEN1 from "assets/realme/REALMEC65/REALMEC65FEATHERGREEN/REALMEC65FEATHERGREEN1.jpeg";
import REALMEC65FEATHERGREEN2 from "assets/realme/REALMEC65/REALMEC65FEATHERGREEN/REALMEC65FEATHERGREEN2.jpeg";
import REALMEC65FEATHERGREEN3 from "assets/realme/REALMEC65/REALMEC65FEATHERGREEN/REALMEC65FEATHERGREEN3.jpeg";
import REALMEC65FEATHERGREEN4 from "assets/realme/REALMEC65/REALMEC65FEATHERGREEN/REALMEC65FEATHERGREEN4.jpeg";
import REALMEC65FEATHERGREEN5 from "assets/realme/REALMEC65/REALMEC65FEATHERGREEN/REALMEC65FEATHERGREEN5.jpeg";
import REALMEC65GLOWINGBLACK1 from "assets/realme/REALMEC65/REALMEC65GLOWINGBLACK/REALMEC65GLOWINGBLACK1.jpeg";
import REALMEC65GLOWINGBLACK2 from "assets/realme/REALMEC65/REALMEC65GLOWINGBLACK/REALMEC65GLOWINGBLACK2.jpeg";
import REALMEC65GLOWINGBLACK3 from "assets/realme/REALMEC65/REALMEC65GLOWINGBLACK/REALMEC65GLOWINGBLACK3.jpeg";
import REALMEC65GLOWINGBLACK4 from "assets/realme/REALMEC65/REALMEC65GLOWINGBLACK/REALMEC65GLOWINGBLACK4.jpeg";
import REALMEC65GLOWINGBLACK5 from "assets/realme/REALMEC65/REALMEC65GLOWINGBLACK/REALMEC65GLOWINGBLACK5.jpeg";

import REALMEC67SUNNYOASIS1 from "assets/realme/REALMEC67/REALMEC67SUNNYOASIS/REALMEC67SUNNYOASIS1.jpeg";
import REALMEC67SUNNYOASIS2 from "assets/realme/REALMEC67/REALMEC67SUNNYOASIS/REALMEC67SUNNYOASIS2.jpeg";
import REALMEC67SUNNYOASIS3 from "assets/realme/REALMEC67/REALMEC67SUNNYOASIS/REALMEC67SUNNYOASIS3.jpeg";
import REALMEC67SUNNYOASIS4 from "assets/realme/REALMEC67/REALMEC67SUNNYOASIS/REALMEC67SUNNYOASIS4.jpeg";
import REALMEC67SUNNYOASIS5 from "assets/realme/REALMEC67/REALMEC67SUNNYOASIS/REALMEC67SUNNYOASIS5.jpeg";
import REALMEC67DARKPURPLE1 from "assets/realme/REALMEC67/REALMEC67DARKPURPLE/REALMEC67DARKPURPLE1.jpeg";
import REALMEC67DARKPURPLE2 from "assets/realme/REALMEC67/REALMEC67DARKPURPLE/REALMEC67DARKPURPLE2.jpeg";
import REALMEC67DARKPURPLE3 from "assets/realme/REALMEC67/REALMEC67DARKPURPLE/REALMEC67DARKPURPLE3.jpeg";
import REALMEC67DARKPURPLE4 from "assets/realme/REALMEC67/REALMEC67DARKPURPLE/REALMEC67DARKPURPLE4.jpeg";
import REALMEC67DARKPURPLE5 from "assets/realme/REALMEC67/REALMEC67DARKPURPLE/REALMEC67DARKPURPLE5.jpeg";

import REALME12PLUSNAVIGATORBEIGE1 from "assets/realme/REALME12PLUS/REALME12PLUSNAVIGATORBEIGE/REALME12PLUSNAVIGATORBEIGE1.jpeg";
import REALME12PLUSNAVIGATORBEIGE2 from "assets/realme/REALME12PLUS/REALME12PLUSNAVIGATORBEIGE/REALME12PLUSNAVIGATORBEIGE2.jpeg";
import REALME12PLUSNAVIGATORBEIGE3 from "assets/realme/REALME12PLUS/REALME12PLUSNAVIGATORBEIGE/REALME12PLUSNAVIGATORBEIGE3.jpeg";
import REALME12PLUSNAVIGATORBEIGE4 from "assets/realme/REALME12PLUS/REALME12PLUSNAVIGATORBEIGE/REALME12PLUSNAVIGATORBEIGE4.jpeg";
import REALME12PLUSNAVIGATORBEIGE5 from "assets/realme/REALME12PLUS/REALME12PLUSNAVIGATORBEIGE/REALME12PLUSNAVIGATORBEIGE5.jpeg";
import REALME12PLUSPIONEERGREEN1 from "assets/realme/REALME12PLUS/REALME12PLUSPIONEERGREEN/REALME12PLUSPIONEERGREEN1.jpeg";
import REALME12PLUSPIONEERGREEN2 from "assets/realme/REALME12PLUS/REALME12PLUSPIONEERGREEN/REALME12PLUSPIONEERGREEN2.jpeg";
import REALME12PLUSPIONEERGREEN3 from "assets/realme/REALME12PLUS/REALME12PLUSPIONEERGREEN/REALME12PLUSPIONEERGREEN3.jpeg";
import REALME12PLUSPIONEERGREEN4 from "assets/realme/REALME12PLUS/REALME12PLUSPIONEERGREEN/REALME12PLUSPIONEERGREEN4.jpeg";
import REALME12PLUSPIONEERGREEN5 from "assets/realme/REALME12PLUS/REALME12PLUSPIONEERGREEN/REALME12PLUSPIONEERGREEN5.jpeg";

import REALME13PROPLUSMONETPURPLE1 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSMONETPURPLE/REALME13PROPLUSMONETPURPLE1.jpeg";
import REALME13PROPLUSMONETPURPLE2 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSMONETPURPLE/REALME13PROPLUSMONETPURPLE2.jpeg";
import REALME13PROPLUSMONETPURPLE3 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSMONETPURPLE/REALME13PROPLUSMONETPURPLE3.jpeg";
import REALME13PROPLUSMONETPURPLE4 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSMONETPURPLE/REALME13PROPLUSMONETPURPLE4.jpeg";
import REALME13PROPLUSMONETPURPLE5 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSMONETPURPLE/REALME13PROPLUSMONETPURPLE5.jpeg";
import REALME13PROPLUSMONETGOLD1 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSMONETGOLD/REALME13PROPLUSMONETGOLD1.jpeg";
import REALME13PROPLUSMONETGOLD2 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSMONETGOLD/REALME13PROPLUSMONETGOLD2.jpeg";
import REALME13PROPLUSMONETGOLD3 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSMONETGOLD/REALME13PROPLUSMONETGOLD3.jpeg";
import REALME13PROPLUSMONETGOLD4 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSMONETGOLD/REALME13PROPLUSMONETGOLD4.jpeg";
import REALME13PROPLUSMONETGOLD5 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSMONETGOLD/REALME13PROPLUSMONETGOLD5.jpeg";
import REALME13PROPLUSEMERALDGREEN1 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSEMERALDGREEN/REALME13PROPLUSEMERALDGREEN1.jpeg";
import REALME13PROPLUSEMERALDGREEN2 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSEMERALDGREEN/REALME13PROPLUSEMERALDGREEN2.jpeg";
import REALME13PROPLUSEMERALDGREEN3 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSEMERALDGREEN/REALME13PROPLUSEMERALDGREEN3.jpeg";
import REALME13PROPLUSEMERALDGREEN4 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSEMERALDGREEN/REALME13PROPLUSEMERALDGREEN4.jpeg";
import REALME13PROPLUSEMERALDGREEN5 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSEMERALDGREEN/REALME13PROPLUSEMERALDGREEN5.jpeg";

import REALME13PROEMERALDGREEN1 from "assets/realme/REALME13PRO/REALME13PROEMERALDGREEN/REALME13PROEMERALDGREEN1.jpeg";
import REALME13PROEMERALDGREEN2 from "assets/realme/REALME13PRO/REALME13PROEMERALDGREEN/REALME13PROEMERALDGREEN2.jpeg";
import REALME13PROEMERALDGREEN3 from "assets/realme/REALME13PRO/REALME13PROEMERALDGREEN/REALME13PROEMERALDGREEN3.jpeg";
import REALME13PROEMERALDGREEN4 from "assets/realme/REALME13PRO/REALME13PROEMERALDGREEN/REALME13PROEMERALDGREEN4.jpeg";
import REALME13PROEMERALDGREEN5 from "assets/realme/REALME13PRO/REALME13PROEMERALDGREEN/REALME13PROEMERALDGREEN5.jpeg";
import REALME13PROMONETGOLD1 from "assets/realme/REALME13PRO/REALME13PROMONETGOLD/REALME13PROMONETGOLD1.jpeg";
import REALME13PROMONETGOLD2 from "assets/realme/REALME13PRO/REALME13PROMONETGOLD/REALME13PROMONETGOLD1.jpeg";
import REALME13PROMONETGOLD3 from "assets/realme/REALME13PRO/REALME13PROMONETGOLD/REALME13PROMONETGOLD3.jpeg";
import REALME13PROMONETGOLD4 from "assets/realme/REALME13PRO/REALME13PROMONETGOLD/REALME13PROMONETGOLD4.jpeg";
import REALME13PROMONETGOLD5 from "assets/realme/REALME13PRO/REALME13PROMONETGOLD/REALME13PROMONETGOLD5.jpeg";
import REALME13PROMONETPURPLE1 from "assets/realme/REALME13PRO/REALME13PROMONETPURPLE/REALME13PROMONETPURPLE1.jpeg";
import REALME13PROMONETPURPLE2 from "assets/realme/REALME13PRO/REALME13PROMONETPURPLE/REALME13PROMONETPURPLE2.jpeg";
import REALME13PROMONETPURPLE3 from "assets/realme/REALME13PRO/REALME13PROMONETPURPLE/REALME13PROMONETPURPLE3.jpeg";
import REALME13PROMONETPURPLE4 from "assets/realme/REALME13PRO/REALME13PROMONETPURPLE/REALME13PROMONETPURPLE4.jpeg";
import REALME13PROMONETPURPLE5 from "assets/realme/REALME13PRO/REALME13PROMONETPURPLE/REALME13PROMONETPURPLE5.jpeg";

import REALME13PLUSVICTORYGOLD1 from "assets/realme/REALME13PLUS/REALME13PLUSVICTORYGOLD/REALME13PLUSVICTORYGOLD1.jpeg";
import REALME13PLUSVICTORYGOLD2 from "assets/realme/REALME13PLUS/REALME13PLUSVICTORYGOLD/REALME13PLUSVICTORYGOLD2.jpeg";
import REALME13PLUSVICTORYGOLD3 from "assets/realme/REALME13PLUS/REALME13PLUSVICTORYGOLD/REALME13PLUSVICTORYGOLD3.jpeg";
import REALME13PLUSVICTORYGOLD4 from "assets/realme/REALME13PLUS/REALME13PLUSVICTORYGOLD/REALME13PLUSVICTORYGOLD4.jpeg";
import REALME13PLUSVICTORYGOLD5 from "assets/realme/REALME13PLUS/REALME13PLUSVICTORYGOLD/REALME13PLUSVICTORYGOLD5.jpeg";
import REALME13PLUSSPEEDGREEN1 from "assets/realme/REALME13PLUS/REALME13PLUSSPEEDGREEN/REALME13PLUSSPEEDGREEN1.jpeg";
import REALME13PLUSSPEEDGREEN2 from "assets/realme/REALME13PLUS/REALME13PLUSSPEEDGREEN/REALME13PLUSSPEEDGREEN2.jpeg";
import REALME13PLUSSPEEDGREEN3 from "assets/realme/REALME13PLUS/REALME13PLUSSPEEDGREEN/REALME13PLUSSPEEDGREEN3.jpeg";
import REALME13PLUSSPEEDGREEN4 from "assets/realme/REALME13PLUS/REALME13PLUSSPEEDGREEN/REALME13PLUSSPEEDGREEN4.jpeg";
import REALME13PLUSSPEEDGREEN5 from "assets/realme/REALME13PLUS/REALME13PLUSSPEEDGREEN/REALME13PLUSSPEEDGREEN5.jpeg";
import REALME13PLUSDARKPURPLE1 from "assets/realme/REALME13PLUS/REALME13PLUSDARKPURPLE/REALME13PLUSDARKPURPLE1.jpeg";
import REALME13PLUSDARKPURPLE2 from "assets/realme/REALME13PLUS/REALME13PLUSDARKPURPLE/REALME13PLUSDARKPURPLE2.jpeg";
import REALME13PLUSDARKPURPLE3 from "assets/realme/REALME13PLUS/REALME13PLUSDARKPURPLE/REALME13PLUSDARKPURPLE3.jpeg";
import REALME13PLUSDARKPURPLE4 from "assets/realme/REALME13PLUS/REALME13PLUSDARKPURPLE/REALME13PLUSDARKPURPLE4.jpeg";
import REALME13PLUSDARKPURPLE5 from "assets/realme/REALME13PLUS/REALME13PLUSDARKPURPLE/REALME13PLUSDARKPURPLE5.jpeg";

import REALME14XJEWELRED1 from "assets/realme/REALME14XJEWELRED/REALME14XJEWELRED1.jpeg";
import REALME14XJEWELRED2 from "assets/realme/REALME14XJEWELRED/REALME14XJEWELRED2.jpeg";
import REALME14XJEWELRED3 from "assets/realme/REALME14XJEWELRED/REALME14XJEWELRED3.jpeg";
import REALME14XJEWELRED4 from "assets/realme/REALME14XJEWELRED/REALME14XJEWELRED4.jpeg";
import REALME14XJEWELRED5 from "assets/realme/REALME14XJEWELRED/REALME14XJEWELRED5.jpeg";

import REALME14XGOLDENGLOW1 from "assets/realme/REALME14XGOLDENGLOW/REALME14XGOLDENGLOW1.jpeg";
import REALME14XGOLDENGLOW2 from "assets/realme/REALME14XGOLDENGLOW/REALME14XGOLDENGLOW2.jpeg";
import REALME14XGOLDENGLOW3 from "assets/realme/REALME14XGOLDENGLOW/REALME14XGOLDENGLOW3.jpeg";
import REALME14XGOLDENGLOW4 from "assets/realme/REALME14XGOLDENGLOW/REALME14XGOLDENGLOW4.jpeg";
import REALME14XGOLDENGLOW5 from "assets/realme/REALME14XGOLDENGLOW/REALME14XGOLDENGLOW5.jpeg";

import REALME14XCRYSTALBLACK1 from "assets/realme/REALME14XCRYSTALBLACK/REALME14XCRYSTALBLACK1.jpeg";
import REALME14XCRYSTALBLACK2 from "assets/realme/REALME14XCRYSTALBLACK/REALME14XCRYSTALBLACK2.jpeg";
import REALME14XCRYSTALBLACK3 from "assets/realme/REALME14XCRYSTALBLACK/REALME14XCRYSTALBLACK3.jpeg";
import REALME14XCRYSTALBLACK4 from "assets/realme/REALME14XCRYSTALBLACK/REALME14XCRYSTALBLACK4.jpeg";
import REALME14XCRYSTALBLACK5 from "assets/realme/REALME14XCRYSTALBLACK/REALME14XCRYSTALBLACK5.jpeg";

import REALME14PROJAIPURPINK1 from "assets/realme/REALME14PRO/REALME14PROJAIPURPINK/REALME14PROJAIPURPINK1.jpeg";
import REALME14PROJAIPURPINK2 from "assets/realme/REALME14PRO/REALME14PROJAIPURPINK/REALME14PROJAIPURPINK2.jpeg";
import REALME14PROJAIPURPINK3 from "assets/realme/REALME14PRO/REALME14PROJAIPURPINK/REALME14PROJAIPURPINK3.jpeg";
import REALME14PROJAIPURPINK4 from "assets/realme/REALME14PRO/REALME14PROJAIPURPINK/REALME14PROJAIPURPINK4.jpeg";
import REALME14PROJAIPURPINK5 from "assets/realme/REALME14PRO/REALME14PROJAIPURPINK/REALME14PROJAIPURPINK5.jpeg";

import REALME14PROPEARLWHITE1 from "assets/realme/REALME14PRO/REALME14PROPEARLWHITE/REALME14PROPEARLWHITE1.jpeg";
import REALME14PROPEARLWHITE2 from "assets/realme/REALME14PRO/REALME14PROPEARLWHITE/REALME14PROPEARLWHITE2.jpeg";
import REALME14PROPEARLWHITE3 from "assets/realme/REALME14PRO/REALME14PROPEARLWHITE/REALME14PROPEARLWHITE3.jpeg";
import REALME14PROPEARLWHITE4 from "assets/realme/REALME14PRO/REALME14PROPEARLWHITE/REALME14PROPEARLWHITE4.jpeg";
import REALME14PROPEARLWHITE5 from "assets/realme/REALME14PRO/REALME14PROPEARLWHITE/REALME14PROPEARLWHITE5.jpeg";

import REALME14PROSUEDEGREY1 from "assets/realme/REALME14PRO/REALME14PROSUEDEGREY/REALME14PROSUEDEGREY1.jpeg";
import REALME14PROSUEDEGREY2 from "assets/realme/REALME14PRO/REALME14PROSUEDEGREY/REALME14PROSUEDEGREY2.jpeg";
import REALME14PROSUEDEGREY3 from "assets/realme/REALME14PRO/REALME14PROSUEDEGREY/REALME14PROSUEDEGREY3.jpeg";
import REALME14PROSUEDEGREY4 from "assets/realme/REALME14PRO/REALME14PROSUEDEGREY/REALME14PROSUEDEGREY4.jpeg";
import REALME14PROSUEDEGREY5 from "assets/realme/REALME14PRO/REALME14PROSUEDEGREY/REALME14PROSUEDEGREY5.jpeg";

import REALME14PROPLUSBIKANERPRUPLE1 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSBIKANERPRUPLE/REALME14PROPLUSBIKANERPRUPLE1.jpeg";
import REALME14PROPLUSBIKANERPRUPLE2 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSBIKANERPRUPLE/REALME14PROPLUSBIKANERPRUPLE2.jpeg";
import REALME14PROPLUSBIKANERPRUPLE3 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSBIKANERPRUPLE/REALME14PROPLUSBIKANERPRUPLE3.jpeg";
import REALME14PROPLUSBIKANERPRUPLE4 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSBIKANERPRUPLE/REALME14PROPLUSBIKANERPRUPLE4.jpeg";
import REALME14PROPLUSBIKANERPRUPLE5 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSBIKANERPRUPLE/REALME14PROPLUSBIKANERPRUPLE5.jpeg";

import REALME14PROPLUSPEARLWHITE1 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSPEARLWHITE/REALME14PROPLUSPEARLWHITE1.jpeg";
import REALME14PROPLUSPEARLWHITE2 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSPEARLWHITE/REALME14PROPLUSPEARLWHITE2.jpeg";
import REALME14PROPLUSPEARLWHITE3 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSPEARLWHITE/REALME14PROPLUSPEARLWHITE3.jpeg";
import REALME14PROPLUSPEARLWHITE4 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSPEARLWHITE/REALME14PROPLUSPEARLWHITE4.jpeg";
import REALME14PROPLUSPEARLWHITE5 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSPEARLWHITE/REALME14PROPLUSPEARLWHITE5.jpeg";

import REALME14PROPLUSSUEDEGREY1 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSSUEDEGREY/REALME14PROPLUSSUEDEGREY1.jpeg";
import REALME14PROPLUSSUEDEGREY2 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSSUEDEGREY/REALME14PROPLUSSUEDEGREY2.jpeg";
import REALME14PROPLUSSUEDEGREY3 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSSUEDEGREY/REALME14PROPLUSSUEDEGREY3.jpeg";
import REALME14PROPLUSSUEDEGREY4 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSSUEDEGREY/REALME14PROPLUSSUEDEGREY4.jpeg";
import REALME14PROPLUSSUEDEGREY5 from "assets/realme/REALME14PROPLUS/REALME14PROPLUSSUEDEGREY/REALME14PROPLUSSUEDEGREY5.jpeg";

//Motorola

import MOTOROLAG45VIVAMAGENTA1 from "assets/motorola/MOTOROLAG45/MOTOROLAG45VIVAMAGENTA/MOTOROLAG45VIVAMAGENTA1.jpeg";
import MOTOROLAG45VIVAMAGENTA2 from "assets/motorola/MOTOROLAG45/MOTOROLAG45VIVAMAGENTA/MOTOROLAG45VIVAMAGENTA2.jpeg";
import MOTOROLAG45VIVAMAGENTA3 from "assets/motorola/MOTOROLAG45/MOTOROLAG45VIVAMAGENTA/MOTOROLAG45VIVAMAGENTA3.jpeg";
import MOTOROLAG45VIVAMAGENTA4 from "assets/motorola/MOTOROLAG45/MOTOROLAG45VIVAMAGENTA/MOTOROLAG45VIVAMAGENTA4.jpeg";
import MOTOROLAG45BRILLIANTGREEN1 from "assets/motorola/MOTOROLAG45/MOTOROLAG45BRILLIANTGREEN/MOTOROLAG45BRILLIANTGREEN1.jpeg";
import MOTOROLAG45BRILLIANTGREEN2 from "assets/motorola/MOTOROLAG45/MOTOROLAG45BRILLIANTGREEN/MOTOROLAG45BRILLIANTGREEN2.jpeg";
import MOTOROLAG45BRILLIANTGREEN3 from "assets/motorola/MOTOROLAG45/MOTOROLAG45BRILLIANTGREEN/MOTOROLAG45BRILLIANTGREEN3.jpeg";
import MOTOROLAG45BRILLIANTGREEN4 from "assets/motorola/MOTOROLAG45/MOTOROLAG45BRILLIANTGREEN/MOTOROLAG45BRILLIANTGREEN4.jpeg";
import MOTOROLAG45BRILLIANTBLUE1 from "assets/motorola/MOTOROLAG45/MOTOROLAG45BRILLIANTBLUE/MOTOROLAG45BRILLIANTBLUE1.jpeg";
import MOTOROLAG45BRILLIANTBLUE2 from "assets/motorola/MOTOROLAG45/MOTOROLAG45BRILLIANTBLUE/MOTOROLAG45BRILLIANTBLUE2.jpeg";
import MOTOROLAG45BRILLIANTBLUE3 from "assets/motorola/MOTOROLAG45/MOTOROLAG45BRILLIANTBLUE/MOTOROLAG45BRILLIANTBLUE3.jpeg";
import MOTOROLAG45BRILLIANTBLUE4 from "assets/motorola/MOTOROLAG45/MOTOROLAG45BRILLIANTBLUE/MOTOROLAG45BRILLIANTBLUE4.jpeg";

import MOTOROLAG85COBALTBLUE1 from "assets/motorola/MOTOROLAG85/MOTOROLAG85COBALTBLUE/MOTOROLAG85COBALTBLUE1.jpeg";
import MOTOROLAG85COBALTBLUE2 from "assets/motorola/MOTOROLAG85/MOTOROLAG85COBALTBLUE/MOTOROLAG85COBALTBLUE2.jpeg";
import MOTOROLAG85COBALTBLUE3 from "assets/motorola/MOTOROLAG85/MOTOROLAG85COBALTBLUE/MOTOROLAG85COBALTBLUE3.jpeg";
import MOTOROLAG85COBALTBLUE4 from "assets/motorola/MOTOROLAG85/MOTOROLAG85COBALTBLUE/MOTOROLAG85COBALTBLUE4.jpeg";
import MOTOROLAG85COBALTBLUE5 from "assets/motorola/MOTOROLAG85/MOTOROLAG85COBALTBLUE/MOTOROLAG85COBALTBLUE5.jpeg";
import MOTOROLAG85OLIVEGREEN1 from "assets/motorola/MOTOROLAG85/MOTOROLAG85OLIVEGREEN/MOTOROLAG85OLIVEGREEN1.jpeg";
import MOTOROLAG85OLIVEGREEN2 from "assets/motorola/MOTOROLAG85/MOTOROLAG85OLIVEGREEN/MOTOROLAG85OLIVEGREEN2.jpeg";
import MOTOROLAG85OLIVEGREEN3 from "assets/motorola/MOTOROLAG85/MOTOROLAG85OLIVEGREEN/MOTOROLAG85OLIVEGREEN3.jpeg";
import MOTOROLAG85OLIVEGREEN4 from "assets/motorola/MOTOROLAG85/MOTOROLAG85OLIVEGREEN/MOTOROLAG85OLIVEGREEN4.jpeg";
import MOTOROLAG85OLIVEGREEN5 from "assets/motorola/MOTOROLAG85/MOTOROLAG85OLIVEGREEN/MOTOROLAG85OLIVEGREEN5.jpeg";
import MOTOROLAG85URBANGREY1 from "assets/motorola/MOTOROLAG85/MOTOROLAG85URBANGREY/MOTOROLAG85URBANGREY1.jpeg";
import MOTOROLAG85URBANGREY2 from "assets/motorola/MOTOROLAG85/MOTOROLAG85URBANGREY/MOTOROLAG85URBANGREY2.jpeg";
import MOTOROLAG85URBANGREY3 from "assets/motorola/MOTOROLAG85/MOTOROLAG85URBANGREY/MOTOROLAG85URBANGREY3.jpeg";
import MOTOROLAG85URBANGREY4 from "assets/motorola/MOTOROLAG85/MOTOROLAG85URBANGREY/MOTOROLAG85URBANGREY4.jpeg";
import MOTOROLAG85URBANGREY5 from "assets/motorola/MOTOROLAG85/MOTOROLAG85URBANGREY/MOTOROLAG85URBANGREY5.jpeg";
import MOTOROLAG85VIVAMAGENTA1 from "assets/motorola/MOTOROLAG85/MOTOROLAG85VIVAMAGENTA/MOTOROLAG85VIVAMAGENTA1.jpeg";
import MOTOROLAG85VIVAMAGENTA2 from "assets/motorola/MOTOROLAG85/MOTOROLAG85VIVAMAGENTA/MOTOROLAG85VIVAMAGENTA2.jpeg";
import MOTOROLAG85VIVAMAGENTA3 from "assets/motorola/MOTOROLAG85/MOTOROLAG85VIVAMAGENTA/MOTOROLAG85VIVAMAGENTA3.jpeg";
import MOTOROLAG85VIVAMAGENTA4 from "assets/motorola/MOTOROLAG85/MOTOROLAG85VIVAMAGENTA/MOTOROLAG85VIVAMAGENTA4.jpeg";
import MOTOROLAG85VIVAMAGENTA5 from "assets/motorola/MOTOROLAG85/MOTOROLAG85VIVAMAGENTA/MOTOROLAG85VIVAMAGENTA5.jpeg";

import MOTOROLAG64ICELILAC1 from "assets/motorola/MOTOROLAG64/MOTOROLAG64ICELILAC/MOTOROLAG64ICELILAC1.jpeg";
import MOTOROLAG64ICELILAC2 from "assets/motorola/MOTOROLAG64/MOTOROLAG64ICELILAC/MOTOROLAG64ICELILAC2.jpeg";
import MOTOROLAG64ICELILAC3 from "assets/motorola/MOTOROLAG64/MOTOROLAG64ICELILAC/MOTOROLAG64ICELILAC3.jpeg";
import MOTOROLAG64ICELILAC4 from "assets/motorola/MOTOROLAG64/MOTOROLAG64ICELILAC/MOTOROLAG64ICELILAC4.jpeg";
import MOTOROLAG64ICELILAC5 from "assets/motorola/MOTOROLAG64/MOTOROLAG64ICELILAC/MOTOROLAG64ICELILAC5.jpeg";
import MOTOROLAG64MINTGREEN1 from "assets/motorola/MOTOROLAG64/MOTOROLAG64MINTGREEN/MOTOROLAG64MINTGREEN1.jpeg";
import MOTOROLAG64MINTGREEN2 from "assets/motorola/MOTOROLAG64/MOTOROLAG64MINTGREEN/MOTOROLAG64MINTGREEN2.jpeg";
import MOTOROLAG64MINTGREEN3 from "assets/motorola/MOTOROLAG64/MOTOROLAG64MINTGREEN/MOTOROLAG64MINTGREEN3.jpeg";
import MOTOROLAG64MINTGREEN4 from "assets/motorola/MOTOROLAG64/MOTOROLAG64MINTGREEN/MOTOROLAG64MINTGREEN4.jpeg";
import MOTOROLAG64MINTGREEN5 from "assets/motorola/MOTOROLAG64/MOTOROLAG64MINTGREEN/MOTOROLAG64MINTGREEN5.jpeg";
import MOTOROLAG64PEARLBLUE1 from "assets/motorola/MOTOROLAG64/MOTOROLAG64PEARLBLUE/MOTOROLAG64PEARLBLUE1.jpeg";
import MOTOROLAG64PEARLBLUE2 from "assets/motorola/MOTOROLAG64/MOTOROLAG64PEARLBLUE/MOTOROLAG64PEARLBLUE2.jpeg";
import MOTOROLAG64PEARLBLUE3 from "assets/motorola/MOTOROLAG64/MOTOROLAG64PEARLBLUE/MOTOROLAG64PEARLBLUE3.jpeg";
import MOTOROLAG64PEARLBLUE4 from "assets/motorola/MOTOROLAG64/MOTOROLAG64PEARLBLUE/MOTOROLAG64PEARLBLUE4.jpeg";
import MOTOROLAG64PEARLBLUE5 from "assets/motorola/MOTOROLAG64/MOTOROLAG64PEARLBLUE/MOTOROLAG64PEARLBLUE5.jpeg";

import MOTOROLAEDGE50NEOPANTONELATTE1 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONELATTE/MOTOROLAEDGE50NEOPANTONELATTE1.jpeg";
import MOTOROLAEDGE50NEOPANTONELATTE2 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONELATTE/MOTOROLAEDGE50NEOPANTONELATTE2.jpeg";
import MOTOROLAEDGE50NEOPANTONELATTE3 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONELATTE/MOTOROLAEDGE50NEOPANTONELATTE3.jpeg";
import MOTOROLAEDGE50NEOPANTONELATTE4 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONELATTE/MOTOROLAEDGE50NEOPANTONELATTE4.jpeg";
import MOTOROLAEDGE50NEOPANTONELATTE5 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONELATTE/MOTOROLAEDGE50NEOPANTONELATTE5.jpeg";
import MOTOROLAEDGE50NEONEUTICALBLUE1 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEONEUTICALBLUE/MOTOROLAEDGE50NEONEUTICALBLUE1.jpeg";
import MOTOROLAEDGE50NEONEUTICALBLUE2 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEONEUTICALBLUE/MOTOROLAEDGE50NEONEUTICALBLUE1.jpeg";
import MOTOROLAEDGE50NEONEUTICALBLUE3 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEONEUTICALBLUE/MOTOROLAEDGE50NEONEUTICALBLUE3.jpeg";
import MOTOROLAEDGE50NEONEUTICALBLUE4 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEONEUTICALBLUE/MOTOROLAEDGE50NEONEUTICALBLUE4.jpeg";
import MOTOROLAEDGE50NEONEUTICALBLUE5 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEONEUTICALBLUE/MOTOROLAEDGE50NEONEUTICALBLUE5.jpeg";
import MOTOROLAEDGE50NEOPANTONEGRISAILLE1 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONEGRISAILLE/MOTOROLAEDGE50NEOPANTONEGRISAILLE1.jpeg";
import MOTOROLAEDGE50NEOPANTONEGRISAILLE2 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONEGRISAILLE/MOTOROLAEDGE50NEOPANTONEGRISAILLE2.jpeg";
import MOTOROLAEDGE50NEOPANTONEGRISAILLE3 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONEGRISAILLE/MOTOROLAEDGE50NEOPANTONEGRISAILLE3.jpeg";
import MOTOROLAEDGE50NEOPANTONEGRISAILLE4 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONEGRISAILLE/MOTOROLAEDGE50NEOPANTONEGRISAILLE4.jpeg";
import MOTOROLAEDGE50NEOPANTONEGRISAILLE5 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONEGRISAILLE/MOTOROLAEDGE50NEOPANTONEGRISAILLE5.jpeg";
import MOTOROLAEDGE50NEOPANTONEPOINCIANA1 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONEPOINCIANA/MOTOROLAEDGE50NEOPANTONEPOINCIANA1.jpeg";
import MOTOROLAEDGE50NEOPANTONEPOINCIANA2 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONEPOINCIANA/MOTOROLAEDGE50NEOPANTONEPOINCIANA2.jpeg";
import MOTOROLAEDGE50NEOPANTONEPOINCIANA3 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONEPOINCIANA/MOTOROLAEDGE50NEOPANTONEPOINCIANA3.jpeg";
import MOTOROLAEDGE50NEOPANTONEPOINCIANA4 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONEPOINCIANA/MOTOROLAEDGE50NEOPANTONEPOINCIANA4.jpeg";
import MOTOROLAEDGE50NEOPANTONEPOINCIANA5 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONEPOINCIANA/MOTOROLAEDGE50NEOPANTONEPOINCIANA5.jpeg";

import MOTOROLAEDGE50FUSIONFORESTGREEN1 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONFORESTGREEN/MOTOROLAEDGE50FUSIONFORESTGREEN1.jpeg";
import MOTOROLAEDGE50FUSIONFORESTGREEN2 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONFORESTGREEN/MOTOROLAEDGE50FUSIONFORESTGREEN2.jpeg";
import MOTOROLAEDGE50FUSIONFORESTGREEN3 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONFORESTGREEN/MOTOROLAEDGE50FUSIONFORESTGREEN3.jpeg";
import MOTOROLAEDGE50FUSIONFORESTGREEN4 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONFORESTGREEN/MOTOROLAEDGE50FUSIONFORESTGREEN4.jpeg";
import MOTOROLAEDGE50FUSIONFORESTGREEN5 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONFORESTGREEN/MOTOROLAEDGE50FUSIONFORESTGREEN5.jpeg";
import MOTOROLAEDGE50FUSIONFORESTBLUE1 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONFORESTBLUE/MOTOROLAEDGE50FUSIONFORESTBLUE1.jpeg";
import MOTOROLAEDGE50FUSIONFORESTBLUE2 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONFORESTBLUE/MOTOROLAEDGE50FUSIONFORESTBLUE2.jpeg";
import MOTOROLAEDGE50FUSIONFORESTBLUE3 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONFORESTBLUE/MOTOROLAEDGE50FUSIONFORESTBLUE3.jpeg";
import MOTOROLAEDGE50FUSIONFORESTBLUE4 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONFORESTBLUE/MOTOROLAEDGE50FUSIONFORESTBLUE4.jpeg";
import MOTOROLAEDGE50FUSIONFORESTBLUE5 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONFORESTBLUE/MOTOROLAEDGE50FUSIONFORESTBLUE5.jpeg";
import MOTOROLAEDGE50FUSIONHOTPINK1 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONHOTPINK/MOTOROLAEDGE50FUSIONHOTPINK1.jpeg";
import MOTOROLAEDGE50FUSIONHOTPINK2 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONHOTPINK/MOTOROLAEDGE50FUSIONHOTPINK2.jpeg";
import MOTOROLAEDGE50FUSIONHOTPINK3 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONHOTPINK/MOTOROLAEDGE50FUSIONHOTPINK3.jpeg";
import MOTOROLAEDGE50FUSIONHOTPINK4 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONHOTPINK/MOTOROLAEDGE50FUSIONHOTPINK4.jpeg";
import MOTOROLAEDGE50FUSIONHOTPINK5 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONHOTPINK/MOTOROLAEDGE50FUSIONHOTPINK5.jpeg";
import MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE1 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE/MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE1.jpeg";
import MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE2 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE/MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE2.jpeg";
import MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE3 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE/MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE3.jpeg";
import MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE4 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE/MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE4.jpeg";
import MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE5 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE/MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE5.jpeg";

import MOTOROLAEDGE50PEACHFUZZ1 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50PEACHFUZZ/MOTOROLAEDGE50PEACHFUZZ1.jpeg";
import MOTOROLAEDGE50PEACHFUZZ2 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50PEACHFUZZ/MOTOROLAEDGE50PEACHFUZZ2.jpeg";
import MOTOROLAEDGE50PEACHFUZZ3 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50PEACHFUZZ/MOTOROLAEDGE50PEACHFUZZ3.jpeg";
import MOTOROLAEDGE50PEACHFUZZ4 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50PEACHFUZZ/MOTOROLAEDGE50PEACHFUZZ4.jpeg";
import MOTOROLAEDGE50PEACHFUZZ5 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50PEACHFUZZ/MOTOROLAEDGE50PEACHFUZZ5.jpeg";
import MOTOROLAEDGE50JUNGLEGREEN1 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50JUNGLEGREEN/MOTOROLAEDGE50JUNGLEGREEN1.jpeg";
import MOTOROLAEDGE50JUNGLEGREEN2 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50JUNGLEGREEN/MOTOROLAEDGE50JUNGLEGREEN2.jpeg";
import MOTOROLAEDGE50JUNGLEGREEN3 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50JUNGLEGREEN/MOTOROLAEDGE50JUNGLEGREEN3.jpeg";
import MOTOROLAEDGE50JUNGLEGREEN4 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50JUNGLEGREEN/MOTOROLAEDGE50JUNGLEGREEN4.jpeg";
import MOTOROLAEDGE50JUNGLEGREEN5 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50JUNGLEGREEN/MOTOROLAEDGE50JUNGLEGREEN5.jpeg";
import MOTOROLAEDGE50KOALAGREY1 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50KOALAGREY/MOTOROLAEDGE50KOALAGREY1.jpeg";
import MOTOROLAEDGE50KOALAGREY2 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50KOALAGREY/MOTOROLAEDGE50KOALAGREY2.jpeg";
import MOTOROLAEDGE50KOALAGREY3 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50KOALAGREY/MOTOROLAEDGE50KOALAGREY3.jpeg";
import MOTOROLAEDGE50KOALAGREY4 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50KOALAGREY/MOTOROLAEDGE50KOALAGREY4.jpeg";
import MOTOROLAEDGE50KOALAGREY5 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50KOALAGREY/MOTOROLAEDGE50KOALAGREY5.jpeg";

import MOTOROLAEDGE50PROCANEELBAY1 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROCANEELBAY/MOTOROLAEDGE50PROCANEELBAY1.jpeg";
import MOTOROLAEDGE50PROCANEELBAY2 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROCANEELBAY/MOTOROLAEDGE50PROCANEELBAY2.jpeg";
import MOTOROLAEDGE50PROCANEELBAY3 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROCANEELBAY/MOTOROLAEDGE50PROCANEELBAY3.jpeg";
import MOTOROLAEDGE50PROCANEELBAY4 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROCANEELBAY/MOTOROLAEDGE50PROCANEELBAY4.jpeg";
import MOTOROLAEDGE50PROCANEELBAY5 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROCANEELBAY/MOTOROLAEDGE50PROCANEELBAY5.jpeg";
import MOTOROLAEDGE50PROBLACKBEAUTY1 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROBLACKBEAUTY/MOTOROLAEDGE50PROBLACKBEAUTY1.jpeg";
import MOTOROLAEDGE50PROBLACKBEAUTY2 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROBLACKBEAUTY/MOTOROLAEDGE50PROBLACKBEAUTY2.jpeg";
import MOTOROLAEDGE50PROBLACKBEAUTY3 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROBLACKBEAUTY/MOTOROLAEDGE50PROBLACKBEAUTY3.jpeg";
import MOTOROLAEDGE50PROBLACKBEAUTY4 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROBLACKBEAUTY/MOTOROLAEDGE50PROBLACKBEAUTY4.jpeg";
import MOTOROLAEDGE50PROBLACKBEAUTY5 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROBLACKBEAUTY/MOTOROLAEDGE50PROBLACKBEAUTY5.jpeg";
import MOTOROLAEDGE50PROLUXELAVENDER1 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROLUXELAVENDER/MOTOROLAEDGE50PROLUXELAVENDER1.jpeg";
import MOTOROLAEDGE50PROLUXELAVENDER2 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROLUXELAVENDER/MOTOROLAEDGE50PROLUXELAVENDER2.jpeg";
import MOTOROLAEDGE50PROLUXELAVENDER3 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROLUXELAVENDER/MOTOROLAEDGE50PROLUXELAVENDER3.jpeg";
import MOTOROLAEDGE50PROLUXELAVENDER4 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROLUXELAVENDER/MOTOROLAEDGE50PROLUXELAVENDER4.jpeg";
import MOTOROLAEDGE50PROLUXELAVENDER5 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROLUXELAVENDER/MOTOROLAEDGE50PROLUXELAVENDER5.jpeg";
import MOTOROLAEDGE50PROMOONLIGHTPEARL1 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROMOONLIGHTPEARL/MOTOROLAEDGE50PROMOONLIGHTPEARL1.jpeg";
import MOTOROLAEDGE50PROMOONLIGHTPEARL2 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROMOONLIGHTPEARL/MOTOROLAEDGE50PROMOONLIGHTPEARL2.jpeg";
import MOTOROLAEDGE50PROMOONLIGHTPEARL3 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROMOONLIGHTPEARL/MOTOROLAEDGE50PROMOONLIGHTPEARL3.jpeg";
import MOTOROLAEDGE50PROMOONLIGHTPEARL4 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROMOONLIGHTPEARL/MOTOROLAEDGE50PROMOONLIGHTPEARL4.jpeg";
import MOTOROLAEDGE50PROMOONLIGHTPEARL5 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROMOONLIGHTPEARL/MOTOROLAEDGE50PROMOONLIGHTPEARL5.jpeg";
import MOTOROLAEDGE50PROVANILACREAM1 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROVANILACREAM/MOTOROLAEDGE50PROVANILACREAM1.jpeg";
import MOTOROLAEDGE50PROVANILACREAM2 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROVANILACREAM/MOTOROLAEDGE50PROVANILACREAM2.jpeg";
import MOTOROLAEDGE50PROVANILACREAM3 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROVANILACREAM/MOTOROLAEDGE50PROVANILACREAM3.jpeg";
import MOTOROLAEDGE50PROVANILACREAM4 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROVANILACREAM/MOTOROLAEDGE50PROVANILACREAM4.jpeg";
import MOTOROLAEDGE50PROVANILACREAM5 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROVANILACREAM/MOTOROLAEDGE50PROVANILACREAM5.jpeg";

//Poco
import POCOC75SILVERSTARDUST1 from "assets/poco/POCOC75/POCOC75SILVERSTARDUST/POCOC75SILVERSTARDUST1.jpeg";
import POCOC75SILVERSTARDUST2 from "assets/poco/POCOC75/POCOC75SILVERSTARDUST/POCOC75SILVERSTARDUST2.jpeg";
import POCOC75SILVERSTARDUST3 from "assets/poco/POCOC75/POCOC75SILVERSTARDUST/POCOC75SILVERSTARDUST3.jpeg";
import POCOC75SILVERSTARDUST4 from "assets/poco/POCOC75/POCOC75SILVERSTARDUST/POCOC75SILVERSTARDUST4.jpeg";
import POCOC75ENCHANTEDGREEN1 from "assets/poco/POCOC75/POCOC75ENCHANTEDGREEN/POCOC75ENCHANTEDGREEN1.jpeg";
import POCOC75ENCHANTEDGREEN2 from "assets/poco/POCOC75/POCOC75ENCHANTEDGREEN/POCOC75ENCHANTEDGREEN2.jpeg";
import POCOC75ENCHANTEDGREEN3 from "assets/poco/POCOC75/POCOC75ENCHANTEDGREEN/POCOC75ENCHANTEDGREEN3.jpeg";
import POCOC75ENCHANTEDGREEN4 from "assets/poco/POCOC75/POCOC75ENCHANTEDGREEN/POCOC75ENCHANTEDGREEN4.jpeg";
import POCOC75AQUABLISS1 from "assets/poco/POCOC75/POCOC75AQUABLISS/POCOC75AQUABLISS1.jpeg";
import POCOC75AQUABLISS2 from "assets/poco/POCOC75/POCOC75AQUABLISS/POCOC75AQUABLISS2.jpeg";
import POCOC75AQUABLISS3 from "assets/poco/POCOC75/POCOC75AQUABLISS/POCOC75AQUABLISS3.jpeg";
import POCOC75AQUABLISS4 from "assets/poco/POCOC75/POCOC75AQUABLISS/POCOC75AQUABLISS4.jpeg";

import POCOM7PROLAVENDERFROST1 from "assets/poco/POCOM7PRO/POCOM7PROLAVENDERFROST/POCOM7PROLAVENDERFROST1.jpeg";
import POCOM7PROLAVENDERFROST2 from "assets/poco/POCOM7PRO/POCOM7PROLAVENDERFROST/POCOM7PROLAVENDERFROST2.jpeg";
import POCOM7PROLAVENDERFROST3 from "assets/poco/POCOM7PRO/POCOM7PROLAVENDERFROST/POCOM7PROLAVENDERFROST3.jpeg";
import POCOM7PROLAVENDERFROST4 from "assets/poco/POCOM7PRO/POCOM7PROLAVENDERFROST/POCOM7PROLAVENDERFROST4.jpeg";
import POCOM7PROLAVENDERFROST5 from "assets/poco/POCOM7PRO/POCOM7PROLAVENDERFROST/POCOM7PROLAVENDERFROST5.jpeg";
import POCOM7PROLUNARDUST1 from "assets/poco/POCOM7PRO/POCOM7PROLUNARDUST/POCOM7PROLUNARDUST1.jpeg";
import POCOM7PROLUNARDUST2 from "assets/poco/POCOM7PRO/POCOM7PROLUNARDUST/POCOM7PROLUNARDUST2.jpeg";
import POCOM7PROLUNARDUST3 from "assets/poco/POCOM7PRO/POCOM7PROLUNARDUST/POCOM7PROLUNARDUST3.jpeg";
import POCOM7PROLUNARDUST4 from "assets/poco/POCOM7PRO/POCOM7PROLUNARDUST/POCOM7PROLUNARDUST4.jpeg";
import POCOM7PROLUNARDUST5 from "assets/poco/POCOM7PRO/POCOM7PROLUNARDUST/POCOM7PROLUNARDUST5.jpeg";
import POCOM7PROOLIVETWILIGHT1 from "assets/poco/POCOM7PRO/POCOM7PROOLIVETWILIGHT/POCOM7PROOLIVETWILIGHT1.jpeg";
import POCOM7PROOLIVETWILIGHT2 from "assets/poco/POCOM7PRO/POCOM7PROOLIVETWILIGHT/POCOM7PROOLIVETWILIGHT2.jpeg";
import POCOM7PROOLIVETWILIGHT3 from "assets/poco/POCOM7PRO/POCOM7PROOLIVETWILIGHT/POCOM7PROOLIVETWILIGHT3.jpeg";
import POCOM7PROOLIVETWILIGHT4 from "assets/poco/POCOM7PRO/POCOM7PROOLIVETWILIGHT/POCOM7PROOLIVETWILIGHT4.jpeg";
import POCOM7PROOLIVETWILIGHT5 from "assets/poco/POCOM7PRO/POCOM7PROOLIVETWILIGHT/POCOM7PROOLIVETWILIGHT5.jpeg";

export const PHONEIMAGE = [
  //VIVO PHONES
  {
    id: ["VIVO1", "VIVO2"],

    colors: [
      {
        id: 1,
        name: "Titanium grey",
        images: [
          VIVOV40TITANIUMGREY1,
          VIVOV40TITANIUMGREY2,
          VIVOV40TITANIUMGREY3,
          VIVOV40TITANIUMGREY4,
          VIVOV40TITANIUMGREY5,
          VIVOV40TITANIUMGREY6,
        ],
      },
      {
        id: 2,
        name: "Ganges Blue",
        images: [
          VIVOV40GANGESBLUE1,
          VIVOV40GANGESBLUE2,
          VIVOV40GANGESBLUE3,
          VIVOV40GANGESBLUE4,
          VIVOV40GANGESBLUE5,
          VIVOV40GANGESBLUE6,
        ],
      },
      {
        id: 3,
        name: "Lotus Purple",
        images: [
          VIVOV40LOTUSPURPLE1,
          VIVOV40LOTUSPURPLE2,
          VIVOV40LOTUSPURPLE3,
          VIVOV40LOTUSPURPLE4,
          VIVOV40LOTUSPURPLE5,
        ],
      },
    ],
  },

  {
    id: ["VIVO3", "VIVO4"],

    colors: [
      {
        id: 1,
        name: "Mint Green",
        images: [
          VIVOV40EMINTGREEN1,
          VIVOV40EMINTGREEN2,
          VIVOV40EMINTGREEN3,
          VIVOV40EMINTGREEN4,
          VIVOV40EMINTGREEN5,
        ],
      },
      {
        id: 2,
        name: "Royal Bronze",
        images: [
          VIVOV40EROYALBRONZE1,
          VIVOV40EROYALBRONZE2,
          VIVOV40EROYALBRONZE3,
          VIVOV40EROYALBRONZE4,
          VIVOV40EROYALBRONZE5,
        ],
      },
    ],
  },

  {
    id: ["VIVO5"],

    colors: [
      {
        id: 1,
        name: "Black Diamond",
        images: [
          VIVOY200EBLACKDIAMOND1,
          VIVOY200EBLACKDIAMOND2,
          VIVOY200EBLACKDIAMOND3,
          VIVOY200EBLACKDIAMOND4,
          VIVOY200EBLACKDIAMOND5,
          VIVOY200EBLACKDIAMOND6,
        ],
      },
      {
        id: 2,
        name: "Saffron Delight",
        images: [
          VIVOY200ESAFFRONDELIGHT1,
          VIVOY200ESAFFRONDELIGHT2,
          VIVOY200ESAFFRONDELIGHT3,
          VIVOY200ESAFFRONDELIGHT4,
          VIVOY200ESAFFRONDELIGHT5,
          VIVOY200ESAFFRONDELIGHT6,
        ],
      },
    ],
  },

  {
    id: ["VIVO7"],

    colors: [
      {
        id: 1,
        name: "Silk Green",
        images: [
          VIVOY300PLUSSILKGREEN1,
          VIVOY300PLUSSILKGREEN2,
          VIVOY300PLUSSILKGREEN3,
          VIVOY300PLUSSILKGREEN4,
          VIVOY300PLUSSILKGREEN5,
        ],
      },
      {
        id: 2,
        name: "Silk Black",
        images: [
          Y300PLUSSILKBLACK1,
          Y300PLUSSILKBLACK2,
          Y300PLUSSILKBLACK3,
          Y300PLUSSILKBLACK4,
          Y300PLUSSILKBLACK5,
          Y300PLUSSILKBLACK6,
        ],
      },
    ],
  },

  {
    id: ["VIVO12", "VIVO13"],

    colors: [
      {
        id: 1,
        name: "Breeze Green",
        images: [
          VIVOY28EBREEZEGREEN1,
          VIVOY28EBREEZEGREEN2,
          VIVOY28EBREEZEGREEN3,
          VIVOY28EBREEZEGREEN4,
          VIVOY28EBREEZEGREEN5,
        ],
      },
      {
        id: 2,
        name: "Vintage Red",
        images: [
          VIVOY28EVINTAGERED1,
          VIVOY28EVINTAGERED2,
          VIVOY28EVINTAGERED3,
          VIVOY28EVINTAGERED4,
          VIVOY28EVINTAGERED5,
        ],
      },
    ],
  },

  {
    id: ["VIVO14"],

    colors: [
      {
        id: 1,
        name: "Peace Black",
        images: [
          VIVOY18IPACEBLACK1,
          VIVOY18IPACEBLACK2,
          VIVOY18IPACEBLACK3,
          VIVOY18IPACEBLACK4,
          VIVOY18IPACEBLACK5,
        ],
      },
    ],
  },

  {
    id: ["VIVO15", "VIVO16"],

    colors: [
      {
        id: 1,
        name: "MERALD GREEN",
        images: [
          VIVOY3005GEMERALDGREEN1,
          VIVOY3005GEMERALDGREEN2,
          VIVOY3005GEMERALDGREEN3,
          VIVOY3005GEMERALDGREEN4,
          VIVOY3005GEMERALDGREEN5,
        ],
      },
      {
        id: 2,
        name: "PHANTOM PURPLE",
        images: [
          VIVOY3005GPHANTOMPURPLE1,
          VIVOY3005GPHANTOMPURPLE2,
          VIVOY3005GPHANTOMPURPLE3,
          VIVOY3005GPHANTOMPURPLE4,
          VIVOY3005GPHANTOMPURPLE5,
        ],
      },
      {
        id: 3,
        name: "TITANIUM SILVER",
        images: [
          VIVOY3005GTITANIUMSILVER1,
          VIVOY3005GTITANIUMSILVER2,
          VIVOY3005GTITANIUMSILVER3,
          VIVOY3005GTITANIUMSILVER4,
          VIVOY3005GTITANIUMSILVER5,
        ],
      },
    ],
  },

  {
    id: ["VIVO17", "VIVO18"],

    colors: [
      {
        id: 1,
        name: "Natural Green",
        images: [
          VIVOX200NATURALGREEN1,
          VIVOX200NATURALGREEN2,
          VIVOX200NATURALGREEN3,
          VIVOX200NATURALGREEN4,
          VIVOX200NATURALGREEN5,
          VIVOX200NATURALGREEN6,
        ],
      },
      {
        id: 2,
        name: "Cosmos Black",
        images: [
          VIVOX200COSMOSBLACK1,
          VIVOX200COSMOSBLACK2,
          VIVOX200COSMOSBLACK3,
          VIVOX200COSMOSBLACK4,
          VIVOX200COSMOSBLACK5,
          VIVOX200COSMOSBLACK6,
        ],
      },
    ],
  },

  {
    id: ["VIVO19"],

    colors: [
      {
        id: 1,
        name: "Titanium Grey",
        images: [
          VIVOX200PROTITANIUMGREY1,
          VIVOX200PROTITANIUMGREY2,
          VIVOX200PROTITANIUMGREY3,
          VIVOX200PROTITANIUMGREY4,
          VIVOX200PROTITANIUMGREY5,
          VIVOX200PROTITANIUMGREY6,
        ],
      },
      {
        id: 2,
        name: "Cosmos Black",
        images: [
          VIVOX200PROCOSMOSBLACK1,
          VIVOX200PROCOSMOSBLACK2,
          VIVOX200PROCOSMOSBLACK3,
          VIVOX200PROCOSMOSBLACK4,
          VIVOX200PROCOSMOSBLACK5,
          VIVOX200PROCOSMOSBLACK6,
        ],
      },
    ],
  },
  {
    id: ["VIVO20", "VIVO21", "VIVO22", "VIVO23"],

    colors: [
      {
        id: 1,
        name: "Glacier Blue",
        images: [
          VIVOY295GGLACIERBLUE1,
          VIVOY295GGLACIERBLUE2,
          VIVOY295GGLACIERBLUE3,
          VIVOY295GGLACIERBLUE4,
          VIVOY295GGLACIERBLUE5,
        ],
      },
      {
        id: 2,
        name: "Diamond Black",
        images: [
          VIVOY295GDIAMONDBLACK1,
          VIVOY295GDIAMONDBLACK2,
          VIVOY295GDIAMONDBLACK3,
          VIVOY295GDIAMONDBLACK4,
          VIVOY295GDIAMONDBLACK5,
        ],
      },
      {
        id: 3,
        name: "Titanium Gold",
        images: [
          VIVOY295GTITANIUMGOLD1,
          VIVOY295GTITANIUMGOLD2,
          VIVOY295GTITANIUMGOLD3,
          VIVOY295GTITANIUMGOLD4,
          VIVOY295GTITANIUMGOLD5,
        ],
      },
    ],
  },

  //tecno
  {
    id: ["TECNO1"],

    colors: [
      {
        id: 1,
        name: "ART EDITION",
        images: [
          TECNOCAMON20ARTEDITION1,
          TECNOCAMON20ARTEDITION2,
          TECNOCAMON20ARTEDITION3,
          TECNOCAMON20ARTEDITION4,
          TECNOCAMON20ARTEDITION5,
        ],
      },
      {
        id: 2,
        name: "Serenity Blue",
        images: [
          TECNOCAMON20SERENITYBLUE1,
          TECNOCAMON20SERENITYBLUE2,
          TECNOCAMON20SERENITYBLUE3,
          TECNOCAMON20SERENITYBLUE4,
          TECNOCAMON20SERENITYBLUE5,
        ],
      },
    ],
  },

  {
    id: ["TECNO2"],

    colors: [
      {
        id: 1,
        name: "Dark Welkin",
        images: [
          TECNOCAMON20PRODARKWELKIN1,
          TECNOCAMON20PRODARKWELKIN2,
          TECNOCAMON20PRODARKWELKIN3,
          TECNOCAMON20PRODARKWELKIN4,
        ],
      },
      {
        id: 2,
        name: "Serenity Blue",
        images: [
          TECNOCAMON20PROSERENITYBLUE1,
          TECNOCAMON20PROSERENITYBLUE2,
          TECNOCAMON20PROSERENITYBLUE3,
          TECNOCAMON20PROSERENITYBLUE4,
          TECNOCAMON20PROSERENITYBLUE5,
        ],
      },
    ],
  },

  {
    id: ["TECNO3"],

    colors: [
      {
        id: 1,
        name: "Basaltic Dark",
        images: [
          TECNOCAMON30BASALTICDARK1,
          TECNOCAMON30BASALTICDARK2,
          TECNOCAMON30BASALTICDARK3,
          TECNOCAMON30BASALTICDARK4,
          TECNOCAMON30BASALTICDARK5,
        ],
      },
      {
        id: 2,
        name: "Uyunisalt White",
        images: [
          TECNOCAMON30UYUNISALTWHITE1,
          TECNOCAMON30UYUNISALTWHITE2,
          TECNOCAMON30UYUNISALTWHITE3,
          TECNOCAMON30UYUNISALTWHITE4,
          TECNOCAMON30UYUNISALTWHITE5,
        ],
      },
    ],
  },

  {
    id: ["TECNO4"],

    colors: [
      {
        id: 1,
        name: "Lava Black",
        images: [
          TECNOCAMON30PREMIERLAVABLACK1,
          TECNOCAMON30PREMIERLAVABLACK2,
          TECNOCAMON30PREMIERLAVABLACK3,
          TECNOCAMON30PREMIERLAVABLACK4,
          TECNOCAMON30PREMIERLAVABLACK5,
        ],
      },
      {
        id: 2,
        name: "Snow Silver",
        images: [
          TECNOCAMON30PREMIERSNOWYSILVER1,
          TECNOCAMON30PREMIERSNOWYSILVER2,
          TECNOCAMON30PREMIERSNOWYSILVER3,
          TECNOCAMON30PREMIERSNOWYSILVER4,
          TECNOCAMON30PREMIERSNOWYSILVER5,
        ],
      },
    ],
  },

  {
    id: ["TECNO5"],

    colors: [
      {
        id: 1,
        name: "Moonlight Silver",
        images: [
          TECNOPHANTOMX2MOONLIGHTSILVER1,
          TECNOPHANTOMX2MOONLIGHTSILVER2,
          TECNOPHANTOMX2MOONLIGHTSILVER3,
          TECNOPHANTOMX2MOONLIGHTSILVER4,
          TECNOPHANTOMX2MOONLIGHTSILVER5,
        ],
      },
      {
        id: 2,
        name: "Stardust Grey",
        images: [
          TECNOPHANTOMX2STARDUSTGREY1,
          TECNOPHANTOMX2STARDUSTGREY2,
          TECNOPHANTOMX2STARDUSTGREY3,
        ],
      },
    ],
  },

  {
    id: ["TECNO6"],

    colors: [
      {
        id: 1,
        name: "Mars Orange",
        images: [
          TECNOPHANTOMX2PROMARSORANGE1,
          TECNOPHANTOMX2PROMARSORANGE2,
          TECNOPHANTOMX2PROMARSORANGE3,
          TECNOPHANTOMX2PROMARSORANGE4,
          TECNOPHANTOMX2PROMARSORANGE5,
        ],
      },
      {
        id: 2,
        name: "Stardust Grey",
        images: [
          TECNOPHANTOMX2PROSTARDUSTGREY1,
          TECNOPHANTOMX2PROSTARDUSTGREY2,
          TECNOPHANTOMX2PROSTARDUSTGREY3,
          TECNOPHANTOMX2PROSTARDUSTGREY4,
        ],
      },
    ],
  },

  {
    id: ["TECNO7"],

    colors: [
      {
        id: 1,
        name: "Aurora Cloud",
        images: [
          TECNOPOVA6NEOAURORACLOUD1,
          TECNOPOVA6NEOAURORACLOUD2,
          TECNOPOVA6NEOAURORACLOUD3,
          TECNOPOVA6NEOAURORACLOUD4,
        ],
      },
      {
        id: 2,
        name: "Azur Sky",
        images: [
          TECNOPOVA6NEOAZURESKY1,
          TECNOPOVA6NEOAZURESKY2,
          TECNOPOVA6NEOAZURESKY3,
          TECNOPOVA6NEOAZURESKY4,
        ],
      },
      {
        id: 3,
        name: "Midnight Shadow",
        images: [
          TECNOPOVA6NEOMIDNIGHTSHADOW1,
          TECNOPOVA6NEOMIDNIGHTSHADOW2,
          TECNOPOVA6NEOMIDNIGHTSHADOW3,
          TECNOPOVA6NEOMIDNIGHTSHADOW4,
          TECNOPOVA6NEOMIDNIGHTSHADOW5,
          TECNOPOVA6NEOMIDNIGHTSHADOW6,
        ],
      },
    ],
  },

  {
    id: ["TECNO8"],

    colors: [
      {
        id: 1,
        name: "Comet Green",
        images: [
          TECNOPOVA6PROCOMETGREEN1,
          TECNOPOVA6PROCOMETGREEN2,
          TECNOPOVA6PROCOMETGREEN3,
          TECNOPOVA6PROCOMETGREEN4,
          TECNOPOVA6PROCOMETGREEN5,
        ],
      },
      {
        id: 2,
        name: "Meteorite Grey",
        images: [
          TECNOPOVA6PROMETEORITEGREY1,
          TECNOPOVA6PROMETEORITEGREY2,
          TECNOPOVA6PROMETEORITEGREY3,
          TECNOPOVA6PROMETEORITEGREY4,
          TECNOPOVA6PROMETEORITEGREY5,
        ],
      },
    ],
  },

  {
    id: ["TECNO9"],

    colors: [
      {
        id: 1,
        name: "Auror Cloud",
        images: [
          TECNOSPARK30CAURORCLOUD1,
          TECNOSPARK30CAURORCLOUD2,
          TECNOSPARK30CAURORCLOUD3,
          TECNOSPARK30CAURORCLOUD4,
          TECNOSPARK30CAURORCLOUD5,
        ],
      },
      {
        id: 2,
        name: "Midnight Shadow",
        images: [
          TECNOSPARK30CMIDNIGHTSHADOW1,
          TECNOSPARK30CMIDNIGHTSHADOW2,
          TECNOSPARK30CMIDNIGHTSHADOW3,
          TECNOSPARK30CMIDNIGHTSHADOW4,
          TECNOSPARK30CMIDNIGHTSHADOW5,
          TECNOSPARK30CMIDNIGHTSHADOW6,
        ],
      },
    ],
  },
  //MI Phones
  {
    id: ["MI1", "MI2", "MI3"],

    colors: [
      {
        id: 1,
        name: "Starlight Black",
        images: [
          REDMI13CBLACK1,
          REDMI13CBLACK2,
          REDMI13CBLACK3,
          REDMI13CBLACK4,
          REDMI13CBLACK5,
          REDMI13CBLACK6,
        ],
      },
      {
        id: 2,
        name: "Starlight Green",
        images: [
          REDMI13CGREEN1,
          REDMI13CGREEN2,
          REDMI13CGREEN3,
          REDMI13CGREEN4,
          REDMI13CGREEN5,
          REDMI13CGREEN6,
        ],
      },
      {
        id: 3,
        name: "Starlight Silver",
        images: [
          REDMI13CSILVER1,
          REDMI13CSILVER2,
          REDMI13CSILVER3,
          REDMI13CSILVER4,
          REDMI13CSILVER5,
          REDMI13CSILVER6,
        ],
      },
    ],
  },

  {
    id: ["MI4", "MI5", "MI6"],

    colors: [
      {
        id: 1,
        name: "Arctic White",
        images: [
          REDMINOTE13WHITE1,
          REDMINOTE13WHITE2,
          REDMINOTE13WHITE3,
          REDMINOTE13WHITE4,
          REDMINOTE13WHITE5,
        ],
      },
      {
        id: 2,
        name: "Chromatic Purple",
        images: [
          REDMINOTE13PURPLE1,
          REDMINOTE13PURPLE2,
          REDMINOTE13PURPLE3,
          REDMINOTE13PURPLE4,
          REDMINOTE13PURPLE5,
          REDMINOTE13PURPLE6,
        ],
      },
      {
        id: 3,
        name: "Prism Gold",
        images: [
          REDMINOTE13GOLD1,
          REDMINOTE13GOLD2,
          REDMINOTE13GOLD3,
          REDMINOTE13GOLD4,
          REDMINOTE13GOLD5,
          REDMINOTE13GOLD6,
        ],
      },
      {
        id: 4,
        name: "Stealth Black",
        images: [
          REDMINOTE13BLACK1,
          REDMINOTE13BLACK2,
          REDMINOTE13BLACK3,
          REDMINOTE13BLACK4,
          REDMINOTE13BLACK5,
        ],
      },
    ],
  },

  {
    id: ["MI7", "MI8"],

    colors: [
      {
        id: 1,
        name: "black diamond",
        images: [
          REDMI135GBLACKDIAMOND1,
          REDMI135GBLACKDIAMOND2,
          REDMI135GBLACKDIAMOND3,
          REDMI135GBLACKDIAMOND4,
          REDMI135GBLACKDIAMOND5,
        ],
      },

      {
        id: 2,
        name: "HAWAIIAN BLUE",
        images: [
          REDMI135GHAWAIIANBLUE1,
          REDMI135GHAWAIIANBLUE2,
          REDMI135GHAWAIIANBLUE3,
          REDMI135GHAWAIIANBLUE4,
          REDMI135GHAWAIIANBLUE5,
        ],
      },

      {
        id: 3,
        name: "ORCHID PINK",
        images: [
          REDMI135GORCHIDPINK1,
          REDMI135GORCHIDPINK2,
          REDMI135GORCHIDPINK3,
          REDMI135GORCHIDPINK4,
          REDMI135GORCHIDPINK5,
        ],
      },
    ],
  },

  {
    id: ["MI9", "MI10", "MI11"],

    colors: [
      {
        id: 1,
        name: "Fusion Black",
        images: [
          REDMINOTE13PROPLUS5GFUSIONBLACK1,
          REDMINOTE13PROPLUS5GFUSIONBLACK2,
          REDMINOTE13PROPLUS5GFUSIONBLACK3,
          REDMINOTE13PROPLUS5GFUSIONBLACK4,
          REDMINOTE13PROPLUS5GFUSIONBLACK5,
        ],
      },

      {
        id: 2,
        name: "Fusion Purple",
        images: [
          REDMINOTE13PROPLUS5GFUSIONPURPLE1,
          REDMINOTE13PROPLUS5GFUSIONPURPLE2,
          REDMINOTE13PROPLUS5GFUSIONPURPLE3,
          REDMINOTE13PROPLUS5GFUSIONPURPLE4,
          REDMINOTE13PROPLUS5GFUSIONPURPLE5,
        ],
      },

      {
        id: 3,
        name: "Fusion White",
        images: [
          REDMINOTE13PROPLUS5GFUSIONWHITE1,
          REDMINOTE13PROPLUS5GFUSIONWHITE2,
          REDMINOTE13PROPLUS5GFUSIONWHITE3,
          REDMINOTE13PROPLUS5GFUSIONWHITE4,
          REDMINOTE13PROPLUS5GFUSIONWHITE5,
        ],
      },
    ],
  },

  {
    id: ["MI12", "MI13", "MI14"],

    colors: [
      {
        id: 1,
        name: "Mystique White",
        images: [
          REDMINOTE14MYSTIQUEWHITE1,
          REDMINOTE14MYSTIQUEWHITE2,
          REDMINOTE14MYSTIQUEWHITE3,
          REDMINOTE14MYSTIQUEWHITE4,
          REDMINOTE14MYSTIQUEWHITE5,
          REDMINOTE14MYSTIQUEWHITE6,
        ],
      },

      {
        id: 2,
        name: "Phantom Purple",
        images: [
          REDMINOTE14PHANTOMPURPLE1,
          REDMINOTE14PHANTOMPURPLE2,
          REDMINOTE14PHANTOMPURPLE3,
          REDMINOTE14PHANTOMPURPLE4,
          REDMINOTE14PHANTOMPURPLE5,
          REDMINOTE14PHANTOMPURPLE6,
        ],
      },

      {
        id: 3,
        name: "Titan Black",
        images: [
          REDMINOTE14TITANBLACK1,
          REDMINOTE14TITANBLACK2,
          REDMINOTE14TITANBLACK3,
          REDMINOTE14TITANBLACK4,
          REDMINOTE14TITANBLACK5,
          REDMINOTE14TITANBLACK6,
        ],
      },
    ],
  },

  {
    id: ["MI15", "MI16"],

    colors: [
      {
        id: 1,
        name: "Ivy Green",
        images: [
          REDMINOTE14PROIVYGREEN1,
          REDMINOTE14PROIVYGREEN2,
          REDMINOTE14PROIVYGREEN3,
          REDMINOTE14PROIVYGREEN4,
          REDMINOTE14PROIVYGREEN5,
          REDMINOTE14PROIVYGREEN6,
        ],
      },

      {
        id: 2,
        name: "Phantom Purple",
        images: [
          REDMINOTE14PROPHANTOMPURPLE1,
          REDMINOTE14PROPHANTOMPURPLE2,
          REDMINOTE14PROPHANTOMPURPLE3,
          REDMINOTE14PROPHANTOMPURPLE4,
          REDMINOTE14PROPHANTOMPURPLE5,
          REDMINOTE14PROPHANTOMPURPLE6,
        ],
      },

      {
        id: 3,
        name: "Titan Black",
        images: [
          REDMINOTE14PROTITANBLACK1,
          REDMINOTE14PROTITANBLACK2,
          REDMINOTE14PROTITANBLACK3,
          REDMINOTE14PROTITANBLACK4,
          REDMINOTE14PROTITANBLACK5,
          REDMINOTE14PROTITANBLACK6,
        ],
      },
    ],
  },

  {
    id: ["MI17", "MI18", "MI19"],

    colors: [
      {
        id: 1,
        name: "Spectre Blue",
        images: [
          REDMINOTE14PROPLUSSPECTREBLUE1,
          REDMINOTE14PROPLUSSPECTREBLUE2,
          REDMINOTE14PROPLUSSPECTREBLUE3,
          REDMINOTE14PROPLUSSPECTREBLUE4,
          REDMINOTE14PROPLUSSPECTREBLUE5,
          REDMINOTE14PROPLUSSPECTREBLUE6,
        ],
      },

      {
        id: 2,
        name: "Phantom Purple",
        images: [
          REDMINOTE14PROPLUSPHANTOMPURPLE1,
          REDMINOTE14PROPLUSPHANTOMPURPLE2,
          REDMINOTE14PROPLUSPHANTOMPURPLE3,
          REDMINOTE14PROPLUSPHANTOMPURPLE4,
          REDMINOTE14PROPLUSPHANTOMPURPLE5,
          REDMINOTE14PROPLUSPHANTOMPURPLE6,
        ],
      },

      {
        id: 3,
        name: "Titan Black",
        images: [
          REDMINOTE14PROPLUSTITANBLACK1,
          REDMINOTE14PROPLUSTITANBLACK2,
          REDMINOTE14PROPLUSTITANBLACK3,
          REDMINOTE14PROPLUSTITANBLACK4,
          REDMINOTE14PROPLUSTITANBLACK5,
          REDMINOTE14PROPLUSTITANBLACK6,
        ],
      },
    ],
  },

  {
    id: ["MI20", "MI21", "MI22"],

    colors: [
      {
        id: 1,
        name: "Starlight blue",
        images: [
          REDMI14CSTARLIGHTBLUE1,
          REDMI14CSTARLIGHTBLUE2,
          REDMI14CSTARLIGHTBLUE3,
          REDMI14CSTARLIGHTBLUE4,
          REDMI14CSTARLIGHTBLUE5,
        ],
      },

      {
        id: 2,
        name: "StarGaze Black",
        images: [
          REDMI14CSTARGAZEBLACK1,
          REDMI14CSTARGAZEBLACK2,
          REDMI14CSTARGAZEBLACK3,
          REDMI14CSTARGAZEBLACK4,
          REDMI14CSTARGAZEBLACK5,
        ],
      },

      {
        id: 3,
        name: "StarDust Purple ",
        images: [
          REDMI14CSTARDUSTPURPLE1,
          REDMI14CSTARDUSTPURPLE2,
          REDMI14CSTARDUSTPURPLE3,
          REDMI14CSTARDUSTPURPLE4,
          REDMI14CSTARDUSTPURPLE5,
        ],
      },
    ],
  },

  //OPPO PHONES
  {
    id: ["OPPO2"],

    colors: [
      {
        id: 1,
        name: "GOLD",
        images: [
          OPPOA17kGOLD1,
          OPPOA17kGOLD2,
          OPPOA17kGOLD3,
          OPPOA17kGOLD4,
          OPPOA17kGOLD5,
        ],
      },
      {
        id: 2,
        name: "BLUE",
        images: [
          OPPOA17kBLUE1,
          OPPOA17kBLUE2,
          OPPOA17kBLUE3,
          OPPOA17kBLUE4,
          OPPOA17kBLUE5,
        ],
      },
    ],
  },

  {
    id: ["OPPO3"],

    colors: [
      {
        id: 1,
        name: "SKY BLUE",
        images: [
          OPPOA77BLUE1,
          OPPOA77BLUE2,
          OPPOA77BLUE3,
          OPPOA77BLUE4,
          OPPOA77BLUE5,
        ],
      },
      {
        id: 2,
        name: "SUNSET ORANGE",
        images: [
          OPPOA77ORANGE1,
          OPPOA77ORANGE2,
          OPPOA77ORANGE3,
          OPPOA77ORANGE4,
          OPPOA77ORANGE5,
        ],
      },
    ],
  },

  {
    id: ["OPPO5", "OPPO6"],

    colors: [
      {
        id: 1,
        name: "BREEZE BLUE",
        images: [
          OPPOK12XBLUE1,
          OPPOK12XBLUE2,
          OPPOK12XBLUE3,
          OPPOK12XBLUE4,
          OPPOK12XBLUE5,
        ],
      },
      {
        id: 2,
        name: "MIDNIGHT VOILET",
        images: [
          OPPOK12XVOILET1,
          OPPOK12XVOILET2,
          OPPOK12XVOILET3,
          OPPOK12XVOILET4,
          OPPOK12XVOILET5,
        ],
      },
    ],
  },

  {
    id: ["OPPO7", "OPPO8"],

    colors: [
      {
        id: 1,
        name: "SPACE BROWN",
        images: [
          OPPORENO12PROBROWN1,
          OPPORENO12PROBROWN2,
          OPPORENO12PROBROWN3,
          OPPORENO12PROBROWN4,
          OPPORENO12PROBROWN5,
        ],
      },
      {
        id: 2,
        name: "SUNSET GOLD",
        images: [
          OPPORENO12PROGOLD1,
          OPPORENO12PROGOLD2,
          OPPORENO12PROGOLD3,
          OPPORENO12PROGOLD4,
          OPPORENO12PROGOLD5,
        ],
      },
    ],
  },

  {
    id: ["OPPO9"],

    colors: [
      {
        id: 1,
        name: "MATTE BROWN",
        images: [
          OPPORENO12BROWN1,
          OPPORENO12BROWN2,
          OPPORENO12BROWN3,
          OPPORENO12BROWN4,
          OPPORENO12BROWN5,
          OPPORENO12BROWN6,
        ],
      },
      {
        id: 2,
        name: "SUNSET PEACH",
        images: [
          OPPORENO12PEACH1,
          OPPORENO12PEACH2,
          OPPORENO12PEACH3,
          OPPORENO12PEACH4,
          OPPORENO12PEACH5,
          OPPORENO12PEACH6,
        ],
      },
    ],
  },

  {
    id: ["OPPO10", "OPPO11"],

    colors: [
      {
        id: 1,
        name: "Midnight Navy",
        images: [
          OPPOF27PROPLUSMIDNIGHTNAVY1,
          OPPOF27PROPLUSMIDNIGHTNAVY2,
          OPPOF27PROPLUSMIDNIGHTNAVY3,
          OPPOF27PROPLUSMIDNIGHTNAVY4,
          OPPOF27PROPLUSMIDNIGHTNAVY5,
        ],
      },
      {
        id: 2,
        name: "Dusk Pink",
        images: [
          OPPOF27PROPLUSDUSKPINK1,
          OPPOF27PROPLUSDUSKPINK2,
          OPPOF27PROPLUSDUSKPINK3,
          OPPOF27PROPLUSDUSKPINK4,
          OPPOF27PROPLUSDUSKPINK5,
        ],
      },
    ],
  },

  {
    id: ["OPPO12", "OPPO13"],

    colors: [
      {
        id: 1,
        name: "Amber Orange",
        images: [
          OPPOF27AMBERORANGE1,
          OPPOF27AMBERORANGE2,
          OPPOF27AMBERORANGE3,
          OPPOF27AMBERORANGE4,
          OPPOF27AMBERORANGE5,
        ],
      },
      {
        id: 2,
        name: " Emerlad Green",
        images: [
          OPPOF27EMERALDGREEN1,
          OPPOF27EMERALDGREEN2,
          OPPOF27EMERALDGREEN3,
          OPPOF27EMERALDGREEN4,
          OPPOF27EMERALDGREEN5,
        ],
      },
    ],
  },

  {
    id: ["OPPO14", "OPPO15"],

    colors: [
      {
        id: 1,
        name: "Moonlight Purple",
        images: [
          OPPOA3PROMOONLIGHTPURPLE1,
          OPPOA3PROMOONLIGHTPURPLE2,
          OPPOA3PROMOONLIGHTPURPLE3,
          OPPOA3PROMOONLIGHTPURPLE4,
          OPPOA3PROMOONLIGHTPURPLE5,
        ],
      },
      {
        id: 2,
        name: "Starry Black",
        images: [
          OPPOA3PROSTARRYBLACK1,
          OPPOA3PROSTARRYBLACK2,
          OPPOA3PROSTARRYBLACK3,
          OPPOA3PROSTARRYBLACK4,
          OPPOA3PROSTARRYBLACK5,
        ],
      },
    ],
  },

  {
    id: ["OPPO16"],

    colors: [
      {
        id: 1,
        name: "Sparkle Black",
        images: [
          OPPOA3XSPARKLEBLACK1,
          OPPOA3XSPARKLEBLACK2,
          OPPOA3XSPARKLEBLACK3,
          OPPOA3XSPARKLEBLACK4,
          OPPOA3XSPARKLEBLACK5,
        ],
      },
      {
        id: 2,
        name: "Starry Purple",
        images: [
          OPPOA3XSTARRYPURPLE1,
          OPPOA3XSTARRYPURPLE2,
          OPPOA3XSTARRYPURPLE3,
          OPPOA3XSTARRYPURPLE4,
          OPPOA3XSTARRYPURPLE5,
        ],
      },
    ],
  },

  {
    id: ["OPPO17"],

    colors: [
      {
        id: 1,
        name: "Sparkle Black",
        images: [
          OPPOA3XSPARKLEBLACK1,
          OPPOA3XSPARKLEBLACK2,
          OPPOA3XSPARKLEBLACK3,
          OPPOA3XSPARKLEBLACK4,
          OPPOA3XSPARKLEBLACK5,
        ],
      },
      {
        id: 2,
        name: "Starry Purple",
        images: [
          OPPOA3XSTARRYPURPLE1,
          OPPOA3XSTARRYPURPLE2,
          OPPOA3XSTARRYPURPLE3,
          OPPOA3XSTARRYPURPLE4,
          OPPOA3XSTARRYPURPLE5,
        ],
      },
      {
        id: 3,
        name: "Starlight White",
        images: [
          OPPOA3XSTARLIGHTWHITE1,
          OPPOA3XSTARLIGHTWHITE2,
          OPPOA3XSTARLIGHTWHITE3,
          OPPOA3XSTARLIGHTWHITE4,
          OPPOA3XSTARLIGHTWHITE5,
        ],
      },
    ],
  },

  {
    id: ["OPPO18", "OPPO19"],

    colors: [
      {
        id: 1,
        name: "Space Black",
        images: [
          OPPOFINDX8SPACEBLACK1,
          OPPOFINDX8SPACEBLACK2,
          OPPOFINDX8SPACEBLACK3,
          OPPOFINDX8SPACEBLACK4,
          OPPOFINDX8SPACEBLACK5,
          OPPOFINDX8SPACEBLACK6,
        ],
      },
      {
        id: 2,
        name: "Star Grey",
        images: [
          OPPOFINDX8STARGREY1,
          OPPOFINDX8STARGREY2,
          OPPOFINDX8STARGREY3,
          OPPOFINDX8STARGREY4,
          OPPOFINDX8STARGREY5,
          OPPOFINDX8STARGREY6,
        ],
      },
    ],
  },

  {
    id: ["OPPO20"],

    colors: [
      {
        id: 1,
        name: "Peral White",
        images: [
          OPPOFINDX8PROPEARLWHITE1,
          OPPOFINDX8PROPEARLWHITE2,
          OPPOFINDX8PROPEARLWHITE3,
          OPPOFINDX8PROPEARLWHITE4,
          OPPOFINDX8PROPEARLWHITE5,
          OPPOFINDX8PROPEARLWHITE6,
        ],
      },
      {
        id: 2,
        name: "Space Black",
        images: [
          OPPOFINDX8PROSPACEBLACK1,
          OPPOFINDX8PROSPACEBLACK2,
          OPPOFINDX8PROSPACEBLACK3,
          OPPOFINDX8PROSPACEBLACK4,
          OPPOFINDX8PROSPACEBLACK5,
          OPPOFINDX8PROSPACEBLACK6,
        ],
      },
    ],
  },
  {
    id: ["OPPO21", "OPPO22"],

    colors: [
      {
        id: 1,
        name: "Ivory White",
        images: [
          RENO135GIVORYWHITE1,
          RENO135GIVORYWHITE2,
          RENO135GIVORYWHITE3,
          RENO135GIVORYWHITE4,
        ],
      },
      {
        id: 2,
        name: "Luminous White",
        images: [
          RENO135GLUMINOUSWHITE1,
          RENO135GLUMINOUSWHITE2,
          RENO135GLUMINOUSWHITE3,
          RENO135GLUMINOUSWHITE4,
        ],
      },
    ],
  },
  {
    id: ["OPPO23", "OPPO24"],

    colors: [
      {
        id: 1,
        name: "Mist Lavender",
        images: [
          RENO13PROMISTLAVENDER1,
          RENO13PROMISTLAVENDER2,
          RENO13PROMISTLAVENDER3,
          RENO13PROMISTLAVENDER4,
          RENO13PROMISTLAVENDER5,
        ],
      },
      {
        id: 2,
        name: "Graphite Grey",
        images: [
          RENO13PROGRAPHITEGREY1,
          RENO13PROGRAPHITEGREY2,
          RENO13PROGRAPHITEGREY3,
          RENO13PROGRAPHITEGREY4,
          RENO13PROGRAPHITEGREY5,
        ],
      },
    ],
  },

  //REALME PHONES

  {
    id: ["REALME1", "REALME2"],

    colors: [
      {
        id: 1,
        name: "Jade Green",
        images: [
          REALMEC63JADEGREEN1,
          REALMEC63JADEGREEN2,
          REALMEC63JADEGREEN3,
          REALMEC63JADEGREEN4,
          REALMEC63JADEGREEN5,
        ],
      },
      {
        id: 2,
        name: "Leather Blue",
        images: [
          REALMEC63LEATHERBLUE1,
          REALMEC63LEATHERBLUE2,
          REALMEC63LEATHERBLUE3,
          REALMEC63LEATHERBLUE4,
          REALMEC63LEATHERBLUE5,
          REALMEC63LEATHERBLUE6,
        ],
      },
    ],
  },

  {
    id: ["REALME3"],

    colors: [
      {
        id: 1,
        name: "Glory Gold",
        images: [
          REALME11GLORYGOLD1,
          REALME11GLORYGOLD2,
          REALME11GLORYGOLD3,
          REALME11GLORYGOLD4,
          REALME11GLORYGOLD5,
        ],
      },
      {
        id: 2,
        name: "Glory Black",
        images: [
          REALME11GLORYBLACK1,
          REALME11GLORYBLACK2,
          REALME11GLORYBLACK3,
          REALME11GLORYBLACK4,
          REALME11GLORYBLACK5,
        ],
      },
    ],
  },

  {
    id: ["REALME4"],

    colors: [
      {
        id: 1,
        name: "Twilight Purple",
        images: [
          REALME12TWILIGHTPURPLE1,
          REALME12TWILIGHTPURPLE2,
          REALME12TWILIGHTPURPLE3,
          REALME12TWILIGHTPURPLE4,
          REALME12TWILIGHTPURPLE5,
        ],
      },
      {
        id: 2,
        name: "Woodland Green",
        images: [
          REALME12WOODLANDGREEN1,
          REALME12WOODLANDGREEN2,
          REALME12WOODLANDGREEN3,
          REALME12WOODLANDGREEN4,
          REALME12WOODLANDGREEN5,
        ],
      },
    ],
  },

  {
    id: ["REALME5", "REALME6"],

    colors: [
      {
        id: 1,
        name: "Feather Green",
        images: [
          REALMEC65FEATHERGREEN1,
          REALMEC65FEATHERGREEN2,
          REALMEC65FEATHERGREEN3,
          REALMEC65FEATHERGREEN4,
          REALMEC65FEATHERGREEN5,
        ],
      },
      {
        id: 2,
        name: "Glowing Black",
        images: [
          REALMEC65GLOWINGBLACK1,
          REALMEC65GLOWINGBLACK2,
          REALMEC65GLOWINGBLACK3,
          REALMEC65GLOWINGBLACK4,
          REALMEC65GLOWINGBLACK5,
        ],
      },
    ],
  },

  {
    id: ["REALME7"],

    colors: [
      {
        id: 1,
        name: "Feather Green",
        images: [
          REALMEC65FEATHERGREEN1,
          REALMEC65FEATHERGREEN2,
          REALMEC65FEATHERGREEN3,
          REALMEC65FEATHERGREEN4,
          REALMEC65FEATHERGREEN5,
        ],
      },
      {
        id: 2,
        name: "Glowing Black",
        images: [
          REALMEC65GLOWINGBLACK1,
          REALMEC65GLOWINGBLACK2,
          REALMEC65GLOWINGBLACK3,
          REALMEC65GLOWINGBLACK4,
          REALMEC65GLOWINGBLACK5,
        ],
      },
    ],
  },

  {
    id: ["REALME8", "REALME9"],

    colors: [
      {
        id: 1,
        name: "Sunny Oasis",
        images: [
          REALMEC67SUNNYOASIS1,
          REALMEC67SUNNYOASIS2,
          REALMEC67SUNNYOASIS3,
          REALMEC67SUNNYOASIS4,
          REALMEC67SUNNYOASIS5,
        ],
      },
      {
        id: 2,
        name: "Dark Purple",
        images: [
          REALMEC67DARKPURPLE1,
          REALMEC67DARKPURPLE2,
          REALMEC67DARKPURPLE3,
          REALMEC67DARKPURPLE4,
          REALMEC67DARKPURPLE5,
        ],
      },
    ],
  },

  {
    id: ["REALME10", "REALME11"],

    colors: [
      {
        id: 1,
        name: "Navigator Beige",
        images: [
          REALME12PLUSNAVIGATORBEIGE1,
          REALME12PLUSNAVIGATORBEIGE2,
          REALME12PLUSNAVIGATORBEIGE3,
          REALME12PLUSNAVIGATORBEIGE4,
          REALME12PLUSNAVIGATORBEIGE5,
        ],
      },
      {
        id: 2,
        name: "Pioneer Green",
        images: [
          REALME12PLUSPIONEERGREEN1,
          REALME12PLUSPIONEERGREEN2,
          REALME12PLUSPIONEERGREEN3,
          REALME12PLUSPIONEERGREEN4,
          REALME12PLUSPIONEERGREEN5,
        ],
      },
    ],
  },

  {
    id: ["REALME12", "REALME13", "REALME14"],

    colors: [
      {
        id: 1,
        name: "Monet Purple",
        images: [
          REALME13PROPLUSMONETPURPLE1,
          REALME13PROPLUSMONETPURPLE2,
          REALME13PROPLUSMONETPURPLE3,
          REALME13PROPLUSMONETPURPLE4,
          REALME13PROPLUSMONETPURPLE5,
        ],
      },
      {
        id: 2,
        name: "Monet Gold",
        images: [
          REALME13PROPLUSMONETGOLD1,
          REALME13PROPLUSMONETGOLD2,
          REALME13PROPLUSMONETGOLD3,
          REALME13PROPLUSMONETGOLD4,
          REALME13PROPLUSMONETGOLD5,
        ],
      },
      {
        id: 3,
        name: "Emerald Green",
        images: [
          REALME13PROPLUSEMERALDGREEN1,
          REALME13PROPLUSEMERALDGREEN2,
          REALME13PROPLUSEMERALDGREEN3,
          REALME13PROPLUSEMERALDGREEN4,
          REALME13PROPLUSEMERALDGREEN5,
        ],
      },
    ],
  },

  {
    id: ["REALME15", "REALME16", "REALME17"],

    colors: [
      {
        id: 1,
        name: "Emerald Green",
        images: [
          REALME13PROEMERALDGREEN1,
          REALME13PROEMERALDGREEN2,
          REALME13PROEMERALDGREEN3,
          REALME13PROEMERALDGREEN4,
          REALME13PROEMERALDGREEN5,
        ],
      },
      {
        id: 2,
        name: "Monet Gold",
        images: [
          REALME13PROMONETGOLD1,
          REALME13PROMONETGOLD2,
          REALME13PROMONETGOLD3,
          REALME13PROMONETGOLD4,
          REALME13PROMONETGOLD5,
        ],
      },
      {
        id: 3,
        name: "Monet Purple",
        images: [
          REALME13PROMONETPURPLE1,
          REALME13PROMONETPURPLE2,
          REALME13PROMONETPURPLE3,
          REALME13PROMONETPURPLE4,
          REALME13PROMONETPURPLE5,
        ],
      },
    ],
  },

  {
    id: ["REALME18", "REALME19", "REALME20"],

    colors: [
      {
        id: 1,
        name: "Victory Gold",
        images: [
          REALME13PLUSVICTORYGOLD1,
          REALME13PLUSVICTORYGOLD2,
          REALME13PLUSVICTORYGOLD3,
          REALME13PLUSVICTORYGOLD4,
          REALME13PLUSVICTORYGOLD5,
        ],
      },
      {
        id: 2,
        name: "Speed Green",
        images: [
          REALME13PLUSSPEEDGREEN1,
          REALME13PLUSSPEEDGREEN2,
          REALME13PLUSSPEEDGREEN3,
          REALME13PLUSSPEEDGREEN4,
          REALME13PLUSSPEEDGREEN5,
        ],
      },
      {
        id: 3,
        name: "Dark Purple",
        images: [
          REALME13PLUSDARKPURPLE1,
          REALME13PLUSDARKPURPLE2,
          REALME13PLUSDARKPURPLE3,
          REALME13PLUSDARKPURPLE4,
          REALME13PLUSDARKPURPLE5,
        ],
      },
    ],
  },

  {
    id: ["REALME21", "REALME22"],

    colors: [
      {
        id: 1,
        name: "Jewel Red",
        images: [
          REALME14XJEWELRED1,
          REALME14XJEWELRED2,
          REALME14XJEWELRED3,
          REALME14XJEWELRED4,
          REALME14XJEWELRED5,
        ],
      },
      {
        id: 2,
        name: "Crystal Black ",
        images: [
          REALME14XCRYSTALBLACK1,
          REALME14XCRYSTALBLACK2,
          REALME14XCRYSTALBLACK3,
          REALME14XCRYSTALBLACK4,
          REALME14XCRYSTALBLACK5,
        ],
      },
      {
        id: 3,
        name: "Golden Glow",
        images: [
          REALME14XGOLDENGLOW1,
          REALME14XGOLDENGLOW2,
          REALME14XGOLDENGLOW3,
          REALME14XGOLDENGLOW4,
          REALME14XGOLDENGLOW5,
        ],
      },
    ],
  },

  {
    id: ["REALME23", "REALME24"],

    colors: [
      {
        id: 1,
        name: "Jaipur Pink",
        images: [
          REALME14PROJAIPURPINK1,
          REALME14PROJAIPURPINK2,
          REALME14PROJAIPURPINK3,
          REALME14PROJAIPURPINK4,
          REALME14PROJAIPURPINK5,
        ],
      },
      {
        id: 2,
        name: "Pearl White ",
        images: [
          REALME14PROPEARLWHITE1,
          REALME14PROPEARLWHITE2,
          REALME14PROPEARLWHITE3,
          REALME14PROPEARLWHITE4,
          REALME14PROPEARLWHITE5,
        ],
      },
      {
        id: 3,
        name: "Suede Grey",
        images: [
          REALME14PROSUEDEGREY1,
          REALME14PROSUEDEGREY2,
          REALME14PROSUEDEGREY3,
          REALME14PROSUEDEGREY4,
          REALME14PROSUEDEGREY5,
        ],
      },
    ],
  },

  {
    id: ["REALME25", "REALME26", "REALME27"],

    colors: [
      {
        id: 1,
        name: "Bikaner Purple",
        images: [
          REALME14PROPLUSBIKANERPRUPLE1,
          REALME14PROPLUSBIKANERPRUPLE2,
          REALME14PROPLUSBIKANERPRUPLE3,
          REALME14PROPLUSBIKANERPRUPLE4,
          REALME14PROPLUSBIKANERPRUPLE5,
        ],
      },
      {
        id: 2,
        name: "Pearl White ",
        images: [
          REALME14PROPLUSPEARLWHITE1,
          REALME14PROPLUSPEARLWHITE2,
          REALME14PROPLUSPEARLWHITE3,
          REALME14PROPLUSPEARLWHITE4,
          REALME14PROPLUSPEARLWHITE5,
        ],
      },
      {
        id: 3,
        name: "Suede Grey",
        images: [
          REALME14PROPLUSSUEDEGREY1,
          REALME14PROPLUSSUEDEGREY2,
          REALME14PROPLUSSUEDEGREY3,
          REALME14PROPLUSSUEDEGREY4,
          REALME14PROPLUSSUEDEGREY5,
        ],
      },
    ],
  },

  //SAMSUNG PHONES
  {
    id: ["SAMSUNG1", "SAMSUNG2", "SAMSUNG3"],

    colors: [
      {
        id: 1,
        name: "Blue Black",
        images: [
          SASMSUNGA165GBLUEBLACK1,
          SASMSUNGA165GBLUEBLACK2,
          SASMSUNGA165GBLUEBLACK3,
          SASMSUNGA165GBLUEBLACK4,
          SASMSUNGA165GBLUEBLACK5,
        ],
      },

      {
        id: 2,
        name: "Light Green",
        images: [
          SASMSUNGA165GLIGHTGREEN1,
          SASMSUNGA165GLIGHTGREEN2,
          SASMSUNGA165GLIGHTGREEN3,
          SASMSUNGA165GLIGHTGREEN4,
          SASMSUNGA165GLIGHTGREEN5,
        ],
      },

      {
        id: 3,
        name: "gold",
        images: [
          SAMSUNGA165GGOLD1,
          SAMSUNGA165GGOLD2,
          SAMSUNGA165GGOLD3,
          SAMSUNGA165GGOLD4,
          SAMSUNGA165GGOLD5,
          SAMSUNGA165GGOLD6,
        ],
      },
    ],
  },

  {
    id: ["SAMSUNG4", "SAMSUNG5", "SAMSUNG6"],

    colors: [
      {
        id: 1,
        name: "Ash Black",
        images: [
          SAMSUNGF15ASHBLACK1,
          SAMSUNGF15ASHBLACK2,
          SAMSUNGF15ASHBLACK3,
          SAMSUNGF15ASHBLACK4,
          SAMSUNGF15ASHBLACK5,
          SAMSUNGF15ASHBLACK6,
        ],
      },
      {
        id: 2,
        name: "Groovy Violet",
        images: [
          SAMSUNGF15GROOVYVIOLET1,
          SAMSUNGF15GROOVYVIOLET2,
          SAMSUNGF15GROOVYVIOLET3,
          SAMSUNGF15GROOVYVIOLET4,
          SAMSUNGF15GROOVYVIOLET5,
          SAMSUNGF15GROOVYVIOLET6,
        ],
      },

      {
        id: 3,
        name: "Jazzy Green",
        images: [
          SAMSUNGF15JAZZYGREEN1,
          SAMSUNGF15JAZZYGREEN2,
          SAMSUNGF15JAZZYGREEN3,
          SAMSUNGF15JAZZYGREEN4,
          SAMSUNGF15JAZZYGREEN5,
          SAMSUNGF15JAZZYGREEN6,
        ],
      },
    ],
  },

  {
    id: ["SAMSUNG10"],

    colors: [
      {
        id: 1,
        name: "ARCTIC BLUEE",
        images: [
          SAMSUNGM14ARCTICBLUE1,
          SAMSUNGM14ARCTICBLUE2,
          SAMSUNGM14ARCTICBLUE3,
          SAMSUNGM14ARCTICBLUE4,
          SAMSUNGM14ARCTICBLUE5,
          SAMSUNGM14ARCTICBLUE6,
        ],
      },
      {
        id: 2,
        name: "SPPHIRE BLUE",
        images: [
          SAMSUNGM14SPPHIREBLUE1,
          SAMSUNGM14SPPHIREBLUE2,
          SAMSUNGM14SPPHIREBLUE3,
          SAMSUNGM14SPPHIREBLUE4,
          SAMSUNGM14SPPHIREBLUE5,
          SAMSUNGM14SPPHIREBLUE6,
        ],
      },
    ],
  },

  {
    id: ["SAMSUNG11", "SAMSUNG12"],

    colors: [
      {
        id: 1,
        name: "BLACK",
        images: [
          SAMSUNGA145GBLACK1,
          SAMSUNGA145GBLACK2,
          SAMSUNGA145GBLACK3,
          SAMSUNGA145GBLACK4,
          SAMSUNGA145GBLACK5,
          SAMSUNGA145GBLACK6,
        ],
      },
      {
        id: 2,
        name: "DARK RED",
        images: [
          SAMSUNGA145GDARKRED1,
          SAMSUNGA145GDARKRED2,
          SAMSUNGA145GDARKRED3,
          SAMSUNGA145GDARKRED4,
          SAMSUNGA145GDARKRED5,
          SAMSUNGA145GDARKRED6,
        ],
      },
      {
        id: 3,
        name: "LIGHT GREEN",
        images: [
          SAMSUNGA145GLIGHTGREEN1,
          SAMSUNGA145GLIGHTGREEN2,
          SAMSUNGA145GLIGHTGREEN3,
          SAMSUNGA145GLIGHTGREEN4,
          SAMSUNGA145GLIGHTGREEN5,
          SAMSUNGA145GLIGHTGREEN6,
        ],
      },
    ],
  },

  {
    id: ["SAMSUNG13"],

    colors: [
      {
        id: 1,
        name: "BLACK",
        images: [A05SBLACK1, A05SBLACK2, A05SBLACK3],
      },
      {
        id: 2,
        name: "LIGHT GREEN",
        images: [A05SLIGHTGREEN1, A05SLIGHTGREEN2, A05SLIGHTGREEN3],
      },
      {
        id: 3,
        name: "LIGHT VIOLET",
        images: [A05SLIGHTVIOLET1, A05SLIGHTVIOLET2, A05SLIGHTVIOLET3],
      },
    ],
  },

  {
    id: ["SAMSUNG15"],

    colors: [
      {
        id: 1,
        name: "BLACK",
        images: [
          SAMSUNGA06BLACK1,
          SAMSUNGA06BLACK2,
          SAMSUNGA06BLACK3,
          SAMSUNGA06BLACK4,
          SAMSUNGA06BLACK5,
          SAMSUNGA06BLACK6,
        ],
      },
      {
        id: 2,
        name: "GOLD",
        images: [
          SAMSUNGA06GOLD1,
          SAMSUNGA06GOLD2,
          SAMSUNGA06GOLD3,
          SAMSUNGA06GOLD4,
          SAMSUNGA06GOLD5,
          SAMSUNGA06GOLD6,
        ],
      },
      {
        id: 3,
        name: "LIGHT BLUE",
        images: [
          SAMSUNGA06LIGHTBLUE1,
          SAMSUNGA06LIGHTBLUE2,
          SAMSUNGA06LIGHTBLUE3,
          SAMSUNGA06LIGHTBLUE4,
          SAMSUNGA06LIGHTBLUE5,
          SAMSUNGA06LIGHTBLUE6,
        ],
      },
    ],
  },

  {
    id: ["SAMSUNG17", "SAMSUNG18"],

    colors: [
      {
        id: 1,
        name: " AWESOME ICEBLUE",
        images: [
          SAMSUNGA35AWESOMEICEBLUE1,
          SAMSUNGA35AWESOMEICEBLUE2,
          SAMSUNGA35AWESOMEICEBLUE3,
          SAMSUNGA35AWESOMEICEBLUE4,
          SAMSUNGA35AWESOMEICEBLUE5,
          SAMSUNGA35AWESOMEICEBLUE6,
        ],
      },
      {
        id: 2,
        name: "AWESOME LILAC",
        images: [
          SAMSUNGA35AWESOMELILAC1,
          SAMSUNGA35AWESOMELILAC2,
          SAMSUNGA35AWESOMELILAC3,
          SAMSUNGA35AWESOMELILAC4,
          SAMSUNGA35AWESOMELILAC5,
          SAMSUNGA35AWESOMELILAC6,
        ],
      },
      {
        id: 3,
        name: "AWESOM ENAVY",
        images: [
          SAMSUNGA35AWESOMENAVY1,
          SAMSUNGA35AWESOMENAVY2,
          SAMSUNGA35AWESOMENAVY3,
          SAMSUNGA35AWESOMENAVY4,
          SAMSUNGA35AWESOMENAVY5,
          SAMSUNGA35AWESOMENAVY6,
        ],
      },
    ],
  },

  {
    id: ["SAMSUNG19", "SAMSUNG20", "SAMSUNG21"],

    colors: [
      {
        id: 1,
        name: " AWESOME NAVY",
        images: [
          SAMSUNGA55AWESOMENAVY1,
          SAMSUNGA55AWESOMENAVY2,
          SAMSUNGA55AWESOMENAVY3,
          SAMSUNGA55AWESOMENAVY4,
          SAMSUNGA55AWESOMENAVY5,
          SAMSUNGA55AWESOMENAVY6,
        ],
      },
      {
        id: 2,
        name: "BLUE",
        images: [
          SAMSUNGA55BLUE1,
          SAMSUNGA55BLUE2,
          SAMSUNGA55BLUE3,
          SAMSUNGA55BLUE4,
          SAMSUNGA55BLUE5,
          SAMSUNGA55BLUE6,
        ],
      },
    ],
  },

  {
    id: ["SAMSUNG24", "SAMSUNG25", "SAMSUNG26"],

    colors: [
      {
        id: 1,
        name: "Cobal Violet",
        images: [
          SAMSUNGS24COBALTVIOLET1,
          SAMSUNGS24COBALTVIOLET2,
          SAMSUNGS24COBALTVIOLET3,
          SAMSUNGS24COBALTVIOLET4,
          SAMSUNGS24COBALTVIOLET5,
        ],
      },
      {
        id: 2,
        name: "Gamber Yellow",
        images: [
          SAMSUNGS245GAMBERYELLOW1,
          SAMSUNGS245GAMBERYELLOW2,
          SAMSUNGS245GAMBERYELLOW3,
          SAMSUNGS245GAMBERYELLOW4,
          SAMSUNGS245GAMBERYELLOW5,
        ],
      },
      {
        id: 3,
        name: "Marble Gray",
        images: [
          SAMSUNGS245GMARBLEGRAY1,
          SAMSUNGS245GMARBLEGRAY2,
          SAMSUNGS245GMARBLEGRAY3,
          SAMSUNGS245GMARBLEGRAY4,
          SAMSUNGS245GMARBLEGRAY5,
          SAMSUNGS245GMARBLEGRAY6,
        ],
      },
      {
        id: 4,
        name: "Ony Black",
        images: [
          SAMSUNGS245GONYXBLACK1,
          SAMSUNGS245GONYXBLACK2,
          SAMSUNGS245GONYXBLACK3,
          SAMSUNGS245GONYXBLACK4,
          SAMSUNGS245GONYXBLACK5,
        ],
      },
    ],
  },

  {
    id: ["SAMSUNG27", "SAMSUNG28"],

    colors: [
      {
        id: 1,
        name: " Cobalt Violet",
        images: [
          SAMSUNGS24PLUS5GCOBALTVIOLET1,
          SAMSUNGS24PLUS5GCOBALTVIOLET2,
          SAMSUNGS24PLUS5GCOBALTVIOLET3,
          SAMSUNGS24PLUS5GCOBALTVIOLET4,
          SAMSUNGS24PLUS5GCOBALTVIOLET5,
          SAMSUNGS24PLUS5GCOBALTVIOLET6,
        ],
      },
      {
        id: 2,
        name: "Onyx Black",
        images: [
          SAMSUNGS24PLUS5GONYXBLACK1,
          SAMSUNGS24PLUS5GONYXBLACK2,
          SAMSUNGS24PLUS5GONYXBLACK3,
          SAMSUNGS24PLUS5GONYXBLACK4,
          SAMSUNGS24PLUS5GONYXBLACK5,
        ],
      },
    ],
  },

  {
    id: ["SAMSUNG29", "SAMSUNG30", "SAMSUNG31"],

    colors: [
      {
        id: 1,
        name: "Titanium Black",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMBLACK1,
          SAMSUNGS24ULTRA5GTITANIUMBLACK2,
          SAMSUNGS24ULTRA5GTITANIUMBLACK3,
          SAMSUNGS24ULTRA5GTITANIUMBLACK4,
          SAMSUNGS24ULTRA5GTITANIUMBLACK5,
        ],
      },
      {
        id: 2,
        name: "Titanium Gray",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMGRAY1,
          SAMSUNGS24ULTRA5GTITANIUMGRAY2,
          SAMSUNGS24ULTRA5GTITANIUMGRAY3,
          SAMSUNGS24ULTRA5GTITANIUMGRAY4,
          SAMSUNGS24ULTRA5GTITANIUMGRAY5,
        ],
      },
      {
        id: 3,
        name: "Titanium Violet",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMVIOLET1,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET2,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET3,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET4,
          SAMSUNGS24ULTRA5GTITANIUMVIOLET5,
        ],
      },
      {
        id: 4,
        name: "Titanium Yellow",
        images: [
          SAMSUNGS24ULTRA5GTITANIUMYELLOW1,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW2,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW3,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW4,
          SAMSUNGS24ULTRA5GTITANIUMYELLOW5,
        ],
      },
    ],
  },

  {
    id: ["SAMSUNG32", "SAMSUNG33"],

    colors: [
      {
        id: 1,
        name: "Blue",
        images: [
          SAMSUNGS24FE5GBLUE1,
          SAMSUNGS24FE5GBLUE2,
          SAMSUNGS24FE5GBLUE3,
          SAMSUNGS24FE5GBLUE4,
          SAMSUNGS24FE5GBLUE5,
          SAMSUNGS24FE5GBLUE6,
        ],
      },
      {
        id: 2,
        name: "Graphite",
        images: [
          SAMSUNGS24FE5GGRAPHITE1,
          SAMSUNGS24FE5GGRAPHITE2,
          SAMSUNGS24FE5GGRAPHITE3,
          SAMSUNGS24FE5GGRAPHITE4,
          SAMSUNGS24FE5GGRAPHITE5,
          SAMSUNGS24FE5GGRAPHITE6,
        ],
      },
      {
        id: 3,
        name: "Mint",
        images: [
          SAMSUNGS24FE5GMINT1,
          SAMSUNGS24FE5GMINT2,
          SAMSUNGS24FE5GMINT3,
          SAMSUNGS24FE5GMINT4,
          SAMSUNGS24FE5GMINT5,
          SAMSUNGS24FE5GMINT6,
        ],
      },
    ],
  },

  // IQoo
  {
    id: ["IQOO1"],
    colors: [
      {
        id: 1,
        name: "Fiery Red",
        images: [
          IQOONEO9PROFIERYRED1,
          IQOONEO9PROFIERYRED2,
          IQOONEO9PROFIERYRED3,
          IQOONEO9PROFIERYRED4,
          IQOONEO9PROFIERYRED5,
        ],
      },
      {
        id: 2,
        name: "Conqueror Black",
        images: [
          IQOONEO9PROCONQUERORBLACK1,
          IQOONEO9PROCONQUERORBLACK2,
          IQOONEO9PROCONQUERORBLACK3,
          IQOONEO9PROCONQUERORBLACK4,
        ],
      },
    ],
  },

  {
    id: ["IQOO2"],
    colors: [
      {
        id: 1,
        name: "Fiery Red",
        images: [
          IQOONEO9PROFIERYRED1,
          IQOONEO9PROFIERYRED2,
          IQOONEO9PROFIERYRED3,
          IQOONEO9PROFIERYRED4,
          IQOONEO9PROFIERYRED5,
        ],
      },
      {
        id: 2,
        name: "Conqueror Black",
        images: [
          IQOONEO9PROCONQUERORBLACK1,
          IQOONEO9PROCONQUERORBLACK2,
          IQOONEO9PROCONQUERORBLACK3,
          IQOONEO9PROCONQUERORBLACK4,
        ],
      },
    ],
  },

  {
    id: ["IQOO3"],
    colors: [
      {
        id: 1,
        name: "Graphene Blue",
        images: [
          IQOOZ9GRAPHENEBLUE1,
          IQOOZ9GRAPHENEBLUE2,
          IQOOZ9GRAPHENEBLUE3,
          IQOOZ9GRAPHENEBLUE4,
        ],
      },
      {
        id: 2,
        name: "Brushed Green",
        images: [
          IQOOZ9BRUSHEDGREEN1,
          IQOOZ9BRUSHEDGREEN2,
          IQOOZ9BRUSHEDGREEN3,
          IQOOZ9BRUSHEDGREEN4,
        ],
      },
    ],
  },

  {
    id: ["IQOO4"],
    colors: [
      {
        id: 1,
        name: "Graphene Blue",
        images: [
          IQOOZ9GRAPHENEBLUE1,
          IQOOZ9GRAPHENEBLUE2,
          IQOOZ9GRAPHENEBLUE3,
          IQOOZ9GRAPHENEBLUE4,
        ],
      },
      {
        id: 2,
        name: "Brushed Green",
        images: [
          IQOOZ9BRUSHEDGREEN1,
          IQOOZ9BRUSHEDGREEN2,
          IQOOZ9BRUSHEDGREEN3,
          IQOOZ9BRUSHEDGREEN4,
        ],
      },
    ],
  },

  {
    id: ["IQOO5", "IQOO6"],
    colors: [
      {
        id: 1,
        name: "Mocha Brown",
        images: [
          IQOOZ9LITEMOCHABROWN1,
          IQOOZ9LITEMOCHABROWN2,
          IQOOZ9LITEMOCHABROWN3,
          IQOOZ9LITEMOCHABROWN4,
          IQOOZ9LITEMOCHABROWN5,
        ],
      },
      {
        id: 2,
        name: "Aqua Flow",
        images: [
          IQOOZ9LITEAQUAFLOW1,
          IQOOZ9LITEAQUAFLOW2,
          IQOOZ9LITEAQUAFLOW3,
          IQOOZ9LITEAQUAFLOW4,
          IQOOZ9LITEAQUAFLOW5,
        ],
      },
    ],
  },

  {
    id: ["IQOO7"],
    colors: [
      {
        id: 1,
        name: "Sonyx Green",
        images: [
          IQOOZ9SONYXGREEN1,
          IQOOZ9SONYXGREEN2,
          IQOOZ9SONYXGREEN3,
          IQOOZ9SONYXGREEN4,
        ],
      },
      {
        id: 2,
        name: "Titanium Matte",
        images: [
          IQOOZ9STITANIUMMATTE1,
          IQOOZ9STITANIUMMATTE2,
          IQOOZ9STITANIUMMATTE3,
          IQOOZ9STITANIUMMATTE4,
        ],
      },
    ],
  },

  {
    id: "[IQOO8]",
    colors: [
      {
        id: 1,
        name: "Sonyx Green",
        images: [
          IQOOZ9SONYXGREEN1,
          IQOOZ9SONYXGREEN2,
          IQOOZ9SONYXGREEN3,
          IQOOZ9SONYXGREEN4,
        ],
      },
      {
        id: 2,
        name: "Titanium Matte",
        images: [
          IQOOZ9STITANIUMMATTE1,
          IQOOZ9STITANIUMMATTE2,
          IQOOZ9STITANIUMMATTE3,
          IQOOZ9STITANIUMMATTE4,
        ],
      },
    ],
  },

  {
    id: ["IQOO9"],
    colors: [
      {
        id: 1,
        name: "Sonyx Green",
        images: [
          IQOOZ9SONYXGREEN1,
          IQOOZ9SONYXGREEN2,
          IQOOZ9SONYXGREEN3,
          IQOOZ9SONYXGREEN4,
        ],
      },
      {
        id: 2,
        name: "Titanium Matte",
        images: [
          IQOOZ9STITANIUMMATTE1,
          IQOOZ9STITANIUMMATTE2,
          IQOOZ9STITANIUMMATTE3,
          IQOOZ9STITANIUMMATTE4,
        ],
      },
    ],
  },

  {
    id: ["IQOO10", "IQOO11", "IQOO12"],
    colors: [
      {
        id: 1,
        name: "Storm Grey",
        images: [
          IQOOZ9XSTORMGREY1,
          IQOOZ9XSTORMGREY2,
          IQOOZ9XSTORMGREY3,
          IQOOZ9XSTORMGREY4,
          IQOOZ9XSTORMGREY5,
        ],
      },
      {
        id: 2,
        name: "Tornado Green",
        images: [
          IQOOZ9XTORNADOGREEN1,
          IQOOZ9XTORNADOGREEN2,
          IQOOZ9XTORNADOGREEN3,
          IQOOZ9XTORNADOGREEN4,
          IQOOZ9XTORNADOGREEN5,
        ],
      },
    ],
  },

  {
    id: ["IQOO13"],
    colors: [
      {
        id: 1,
        name: "Luxe Marble",
        images: [
          IQOOZ9SPROLUXEMARBLE1,
          IQOOZ9SPROLUXEMARBLE2,
          IQOOZ9SPROLUXEMARBLE3,
          IQOOZ9SPROLUXEMARBLE4,
        ],
      },
      {
        id: 2,
        name: "Flamboyant Orange",
        images: [
          IQOOZ9SPROFLAMBOYANTORANGE1,
          IQOOZ9SPROFLAMBOYANTORANGE2,
          IQOOZ9SPROFLAMBOYANTORANGE3,
          IQOOZ9SPROFLAMBOYANTORANGE4,
          IQOOZ9SPROFLAMBOYANTORANGE5,
        ],
      },
    ],
  },

  {
    id: ["IQOO14"],
    colors: [
      {
        id: 1,
        name: "Luxe Marble",
        images: [
          IQOOZ9SPROLUXEMARBLE1,
          IQOOZ9SPROLUXEMARBLE2,
          IQOOZ9SPROLUXEMARBLE3,
          IQOOZ9SPROLUXEMARBLE4,
        ],
      },
      {
        id: 2,
        name: "Flamboyant Orange",
        images: [
          IQOOZ9SPROFLAMBOYANTORANGE1,
          IQOOZ9SPROFLAMBOYANTORANGE2,
          IQOOZ9SPROFLAMBOYANTORANGE3,
          IQOOZ9SPROFLAMBOYANTORANGE4,
          IQOOZ9SPROFLAMBOYANTORANGE5,
        ],
      },
    ],
  },

  {
    id: ["IQOO15"],
    colors: [
      {
        id: 1,
        name: "Luxe Marble",
        images: [
          IQOOZ9SPROLUXEMARBLE1,
          IQOOZ9SPROLUXEMARBLE2,
          IQOOZ9SPROLUXEMARBLE3,
          IQOOZ9SPROLUXEMARBLE4,
        ],
      },
      {
        id: 2,
        name: "Flamboyant Orange",
        images: [
          IQOOZ9SPROFLAMBOYANTORANGE1,
          IQOOZ9SPROFLAMBOYANTORANGE2,
          IQOOZ9SPROFLAMBOYANTORANGE3,
          IQOOZ9SPROFLAMBOYANTORANGE4,
          IQOOZ9SPROFLAMBOYANTORANGE5,
        ],
      },
    ],
  },

  //Motorola

  {
    id: ["MOTOROLA1", "MOTOROLA2"],
    colors: [
      {
        id: 1,
        name: "Viva Magenta",
        images: [
          MOTOROLAG45VIVAMAGENTA1,
          MOTOROLAG45VIVAMAGENTA2,
          MOTOROLAG45VIVAMAGENTA3,
          MOTOROLAG45VIVAMAGENTA4,
        ],
      },
      {
        id: 2,
        name: "Brilliant Blue",
        images: [
          MOTOROLAG45BRILLIANTBLUE1,
          MOTOROLAG45BRILLIANTBLUE2,
          MOTOROLAG45BRILLIANTBLUE3,
          MOTOROLAG45BRILLIANTBLUE4,
        ],
      },
      {
        id: 3,
        name: "Brilliant Green",
        images: [
          MOTOROLAG45BRILLIANTGREEN1,
          MOTOROLAG45BRILLIANTGREEN2,
          MOTOROLAG45BRILLIANTGREEN3,
          MOTOROLAG45BRILLIANTGREEN4,
        ],
      },
    ],
  },

  {
    id: ["MOTOROLA3"],
    colors: [
      {
        id: 1,
        name: "Cobalt Blue",
        images: [
          MOTOROLAG85COBALTBLUE1,
          MOTOROLAG85COBALTBLUE2,
          MOTOROLAG85COBALTBLUE3,
          MOTOROLAG85COBALTBLUE4,
          MOTOROLAG85COBALTBLUE5,
        ],
      },
      {
        id: 2,
        name: "Olive Green",
        images: [
          MOTOROLAG85OLIVEGREEN1,
          MOTOROLAG85OLIVEGREEN2,
          MOTOROLAG85OLIVEGREEN3,
          MOTOROLAG85OLIVEGREEN4,
          MOTOROLAG85OLIVEGREEN5,
        ],
      },
      {
        id: 3,
        name: "Urban Grey",
        images: [
          MOTOROLAG85URBANGREY1,
          MOTOROLAG85URBANGREY2,
          MOTOROLAG85URBANGREY3,
          MOTOROLAG85URBANGREY4,
          MOTOROLAG85URBANGREY5,
        ],
      },
      {
        id: 4,
        name: "Viva Magenta",
        images: [
          MOTOROLAG85VIVAMAGENTA1,
          MOTOROLAG85VIVAMAGENTA2,
          MOTOROLAG85VIVAMAGENTA3,
          MOTOROLAG85VIVAMAGENTA4,
          MOTOROLAG85VIVAMAGENTA5,
        ],
      },
    ],
  },

  {
    id: ["MOTOROLA4"],
    colors: [
      {
        id: 1,
        name: "Cobalt Blue",
        images: [
          MOTOROLAG85COBALTBLUE1,
          MOTOROLAG85COBALTBLUE2,
          MOTOROLAG85COBALTBLUE3,
          MOTOROLAG85COBALTBLUE4,
          MOTOROLAG85COBALTBLUE5,
        ],
      },
      {
        id: 2,
        name: "Olive Green",
        images: [
          MOTOROLAG85OLIVEGREEN1,
          MOTOROLAG85OLIVEGREEN2,
          MOTOROLAG85OLIVEGREEN3,
          MOTOROLAG85OLIVEGREEN4,
          MOTOROLAG85OLIVEGREEN5,
        ],
      },
      {
        id: 3,
        name: "Urban Grey",
        images: [
          MOTOROLAG85URBANGREY1,
          MOTOROLAG85URBANGREY2,
          MOTOROLAG85URBANGREY3,
          MOTOROLAG85URBANGREY4,
          MOTOROLAG85URBANGREY5,
        ],
      },
      {
        id: 4,
        name: "Viva Magenta",
        images: [
          MOTOROLAG85VIVAMAGENTA1,
          MOTOROLAG85VIVAMAGENTA2,
          MOTOROLAG85VIVAMAGENTA3,
          MOTOROLAG85VIVAMAGENTA4,
          MOTOROLAG85VIVAMAGENTA5,
        ],
      },
    ],
  },

  {
    id: ["MOTOROLA5"],
    colors: [
      {
        id: 1,
        name: "Ice Lilac",
        images: [
          MOTOROLAG64ICELILAC1,
          MOTOROLAG64ICELILAC2,
          MOTOROLAG64ICELILAC3,
          MOTOROLAG64ICELILAC4,
          MOTOROLAG64ICELILAC5,
        ],
      },
      {
        id: 2,
        name: "Mint Green",
        images: [
          MOTOROLAG64MINTGREEN1,
          MOTOROLAG64MINTGREEN2,
          MOTOROLAG64MINTGREEN3,
          MOTOROLAG64MINTGREEN4,
          MOTOROLAG64MINTGREEN5,
        ],
      },
      {
        id: 3,
        name: "Pearl Blue",
        images: [
          MOTOROLAG64PEARLBLUE1,
          MOTOROLAG64PEARLBLUE2,
          MOTOROLAG64PEARLBLUE3,
          MOTOROLAG64PEARLBLUE4,
          MOTOROLAG64PEARLBLUE5,
        ],
      },
    ],
  },

  {
    id: ["MOTOROLA6"],
    colors: [
      {
        id: 1,
        name: "Pantone Latte",
        images: [
          MOTOROLAEDGE50NEOPANTONELATTE1,
          MOTOROLAEDGE50NEOPANTONELATTE2,
          MOTOROLAEDGE50NEOPANTONELATTE3,
          MOTOROLAEDGE50NEOPANTONELATTE4,
          MOTOROLAEDGE50NEOPANTONELATTE5,
        ],
      },
      {
        id: 2,
        name: "Neutical Blue",
        images: [
          MOTOROLAEDGE50NEONEUTICALBLUE1,
          MOTOROLAEDGE50NEONEUTICALBLUE2,
          MOTOROLAEDGE50NEONEUTICALBLUE3,
          MOTOROLAEDGE50NEONEUTICALBLUE4,
          MOTOROLAEDGE50NEONEUTICALBLUE5,
        ],
      },
      {
        id: 3,
        name: "Pantone Grisaille",
        images: [
          MOTOROLAEDGE50NEOPANTONEGRISAILLE1,
          MOTOROLAEDGE50NEOPANTONEGRISAILLE2,
          MOTOROLAEDGE50NEOPANTONEGRISAILLE3,
          MOTOROLAEDGE50NEOPANTONEGRISAILLE4,
          MOTOROLAEDGE50NEOPANTONEGRISAILLE5,
        ],
      },
      {
        id: 4,
        name: "Pantone Poinciana",
        images: [
          MOTOROLAEDGE50NEOPANTONEPOINCIANA1,
          MOTOROLAEDGE50NEOPANTONEPOINCIANA2,
          MOTOROLAEDGE50NEOPANTONEPOINCIANA3,
          MOTOROLAEDGE50NEOPANTONEPOINCIANA4,
          MOTOROLAEDGE50NEOPANTONEPOINCIANA5,
        ],
      },
    ],
  },

  {
    id: ["MOTOROLA7"],
    colors: [
      {
        id: 1,
        name: "Forest Green",
        images: [
          MOTOROLAEDGE50FUSIONFORESTGREEN1,
          MOTOROLAEDGE50FUSIONFORESTGREEN2,
          MOTOROLAEDGE50FUSIONFORESTGREEN3,
          MOTOROLAEDGE50FUSIONFORESTGREEN4,
          MOTOROLAEDGE50FUSIONFORESTGREEN5,
        ],
      },
      {
        id: 2,
        name: "Forest Blue",
        images: [
          MOTOROLAEDGE50FUSIONFORESTBLUE1,
          MOTOROLAEDGE50FUSIONFORESTBLUE2,
          MOTOROLAEDGE50FUSIONFORESTBLUE3,
          MOTOROLAEDGE50FUSIONFORESTBLUE4,
          MOTOROLAEDGE50FUSIONFORESTBLUE5,
        ],
      },
      {
        id: 3,
        name: "Hot Pink",
        images: [
          MOTOROLAEDGE50FUSIONHOTPINK1,
          MOTOROLAEDGE50FUSIONHOTPINK2,
          MOTOROLAEDGE50FUSIONHOTPINK3,
          MOTOROLAEDGE50FUSIONHOTPINK4,
          MOTOROLAEDGE50FUSIONHOTPINK5,
        ],
      },
      {
        id: 4,
        name: "Marshamallow Blue",
        images: [
          MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE1,
          MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE2,
          MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE3,
          MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE4,
          MOTOROLAEDGE50FUSIONMARSHAMALLOWBLUE5,
        ],
      },
    ],
  },

  {
    id: ["MOTOROLA8"],
    colors: [
      {
        id: 1,
        name: "Peach Fuzz",
        images: [
          MOTOROLAEDGE50PEACHFUZZ1,
          MOTOROLAEDGE50PEACHFUZZ2,
          MOTOROLAEDGE50PEACHFUZZ3,
          MOTOROLAEDGE50PEACHFUZZ4,
          MOTOROLAEDGE50PEACHFUZZ5,
        ],
      },
      {
        id: 2,
        name: "Jungle Green",
        images: [
          MOTOROLAEDGE50JUNGLEGREEN1,
          MOTOROLAEDGE50JUNGLEGREEN2,
          MOTOROLAEDGE50JUNGLEGREEN3,
          MOTOROLAEDGE50JUNGLEGREEN4,
          MOTOROLAEDGE50JUNGLEGREEN5,
        ],
      },
      {
        id: 3,
        name: "Koala Grey",
        images: [
          MOTOROLAEDGE50KOALAGREY1,
          MOTOROLAEDGE50KOALAGREY2,
          MOTOROLAEDGE50KOALAGREY3,
          MOTOROLAEDGE50KOALAGREY4,
          MOTOROLAEDGE50KOALAGREY5,
        ],
      },
    ],
  },

  {
    id: ["MOTOROLA9"],
    colors: [
      {
        id: 1,
        name: " Caneel Bay",
        images: [
          MOTOROLAEDGE50PROCANEELBAY1,
          MOTOROLAEDGE50PROCANEELBAY2,
          MOTOROLAEDGE50PROCANEELBAY3,
          MOTOROLAEDGE50PROCANEELBAY4,
          MOTOROLAEDGE50PROCANEELBAY5,
        ],
      },
      {
        id: 2,
        name: "Black Beauty",
        images: [
          MOTOROLAEDGE50PROBLACKBEAUTY1,
          MOTOROLAEDGE50PROBLACKBEAUTY2,
          MOTOROLAEDGE50PROBLACKBEAUTY3,
          MOTOROLAEDGE50PROBLACKBEAUTY4,
          MOTOROLAEDGE50PROBLACKBEAUTY5,
        ],
      },
      {
        id: 3,
        name: "Luxe Lavender",
        images: [
          MOTOROLAEDGE50PROLUXELAVENDER1,
          MOTOROLAEDGE50PROLUXELAVENDER2,
          MOTOROLAEDGE50PROLUXELAVENDER3,
          MOTOROLAEDGE50PROLUXELAVENDER4,
          MOTOROLAEDGE50PROLUXELAVENDER5,
        ],
      },
      {
        id: 4,
        name: "Moonlight Pearl",
        images: [
          MOTOROLAEDGE50PROMOONLIGHTPEARL1,
          MOTOROLAEDGE50PROMOONLIGHTPEARL2,
          MOTOROLAEDGE50PROMOONLIGHTPEARL3,
          MOTOROLAEDGE50PROMOONLIGHTPEARL4,
          MOTOROLAEDGE50PROMOONLIGHTPEARL5,
        ],
      },
      {
        id: 5,
        name: "Vanila Cream",
        images: [
          MOTOROLAEDGE50PROVANILACREAM1,
          MOTOROLAEDGE50PROVANILACREAM2,
          MOTOROLAEDGE50PROVANILACREAM3,
          MOTOROLAEDGE50PROVANILACREAM4,
          MOTOROLAEDGE50PROVANILACREAM5,
        ],
      },
    ],
  },

  //POCO

  {
    id: ["POCO1"],
    colors: [
      {
        id: 1,
        name: "Silver Star Dust",
        images: [
          POCOC75SILVERSTARDUST1,
          POCOC75SILVERSTARDUST2,
          POCOC75SILVERSTARDUST3,
          POCOC75SILVERSTARDUST4,
        ],
      },
      {
        id: 2,
        name: "Enchanted Green",
        images: [
          POCOC75ENCHANTEDGREEN1,
          POCOC75ENCHANTEDGREEN2,
          POCOC75ENCHANTEDGREEN3,
          POCOC75ENCHANTEDGREEN4,
        ],
      },
      {
        id: 3,
        name: "Aqua Bliss",
        images: [
          POCOC75AQUABLISS1,
          POCOC75AQUABLISS2,
          POCOC75AQUABLISS3,
          POCOC75AQUABLISS4,
        ],
      },
    ],
  },

  {
    id: ["POCO2", "POCO3"],
    colors: [
      {
        id: 1,
        name: "Lavender Frost",
        images: [
          POCOM7PROLAVENDERFROST1,
          POCOM7PROLAVENDERFROST2,
          POCOM7PROLAVENDERFROST3,
          POCOM7PROLAVENDERFROST4,
          POCOM7PROLAVENDERFROST5,
        ],
      },
      {
        id: 2,
        name: "Lunar Dust",
        images: [
          POCOM7PROLUNARDUST1,
          POCOM7PROLUNARDUST2,
          POCOM7PROLUNARDUST3,
          POCOM7PROLUNARDUST4,
          POCOM7PROLUNARDUST5,
        ],
      },
      {
        id: 3,
        name: "Olive Twilight",
        images: [
          POCOM7PROOLIVETWILIGHT1,
          POCOM7PROOLIVETWILIGHT2,
          POCOM7PROOLIVETWILIGHT3,
          POCOM7PROOLIVETWILIGHT4,
          POCOM7PROOLIVETWILIGHT5,
        ],
      },
    ],
  },
];
